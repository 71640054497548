import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import { checkArray } from '../Utility'

/**
 * Render a dropdown wrapped in a form group.
 * which the value of the item is displayed as it is without futher binding.
 *
 * @param {String} name - unique id for this element
 * @param {String} label - Display for the drop down
 * @param {*} selectedItem - current selected value
 * @param {Array} itemsSource - list of items
 * @param {Function} onChange - callback to handle changes
 */
export default class GenericDropdownsFormGroup extends Component {
    constructor(props) {
        super(props);
        console.log(this.props)
    }

    render() {
        if(Array.isArray(this.props.itemsSource) === false) {
            console.log(`${this.constructor.name} for ${this.props.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input type="select" name={this.props.name} id={this.props.name} value={this.props.selectedItem} onChange={this.props.onChange} invalid={this.props.invalid}>
                        {
                            this.props.itemsSource.map(item =>
                                <option key={item} value={item}>{item}</option>
                            )
                        }
                    </Input>
                    <FormFeedback>{this.props.errorText}</FormFeedback>
                </Col>
            </FormGroup>
        )
    }
}