import { FIREBASE_INIT, USER_INIT, SHOW_LOADING, LOGIN_MODAL, LOGIN_SUCCESS_CB,
    FETCH_USER_RECORDS, LOGIN, LOGOUT, CUSTOMER_SELECTION_MODAL, SELECT_CUSTOMER } from '../actions/types';

const INIT_STATE = {


    firebaseApp: null,
    customer: null,
    records: [],
    loginModal: false,
    loginSuccessHandle: null,
    loading: false,
    loginError: null,
    user: null,
    customerselectionModal: false,
    selectedcustomer: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FIREBASE_INIT:
            return { ...state, firebaseApp: action.payload }

        case LOGIN:
            return { ...state, loading: false, loginModal: false, user: action.payload, loginError: null }

        case LOGOUT:
            return { ...state, user: null }

        // case USER_INIT:
        //     if (action.payload) {
        //         switch (action.payload.status) {
        //             case 'success':
        //                 const { customer } = action.payload;
        //                 console.log('USER_INIT: ' + JSON.stringify(customer));
        //                 if (customer) {
        //                     if (state.loginSuccessHandle) state.loginSuccessHandle();
        //                     return {
        //                         ...state,
        //                         loading: false,
        //                         loginModal: false,
        //                         customer: customer,
        //                         loginError: null
        //                     }
        //                 } else {
        //                     return {
        //                         ...state,
        //                         loading: false,
        //                         loginModal: false,
        //                         customer: null,
        //                         loginError: null
        //                     }
        //                 }

        //             case 'failed':
        //                 const { message } = action.payload;
        //                 return {
        //                     ...state,
        //                     loading: false,
        //                     loginModal: false,
        //                     customer: null,
        //                     loginError: message
        //                 }

        //             default:
        //                 break;
        //         }
        //     } else {
        //         return {
        //             ...state,
        //             loading: false,
        //             loginModal: false,
        //             customer: null,
        //             loginError: null
        //         }
        //     }
        //     return state;

        case SHOW_LOADING:
            return { ...state, loading: action.payload }

        case LOGIN_MODAL:
            return { ...state, loginModal: action.payload }

        case LOGIN_SUCCESS_CB:
            return { ...state, loginSuccessHandle: action.payload }

        case FETCH_USER_RECORDS:
            return { ...state, customer: action.payload.customer, records: action.payload.records }

        case CUSTOMER_SELECTION_MODAL:
            return { ...state, customerselectionModal: action.payload}

        case SELECT_CUSTOMER:
             console.log(action.payload);
            return { ...state, selectedcustomer: action.payload}

        default:
            return state;
    }
}