import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Table } from 'reactstrap'
import { checkArray } from '../Utility'

/**
 * Render time and cost shipping information in a form group
 *
 * @param {Number} price - Item price
 * @param {Number} processDay - process day
 * @param {String} selectedDeliveryOption - Delivery choices
 * @param {Array} deliveryInfo - Delivery information
 */
export default class TimeAndCostFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // if (Array.isArray(this.props.deliveryInfo) === false ||
        //     this.props.selectedDeliveryOption == null) {
        //     console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
        //     return null
        // }

        return (
            <Fragment>
                {/* <FormGroup row>
                    <Label sm={3}><b>Process Day</b></Label>
                    <Label sm={9} style={{ textAlign: 'left', verticalAlign: 'text-bottom' }}>
                        <b>{this.props.processDay == null ? 'N/A' : `${this.props.processDay} ${this.props.processDay > 1 ? 'DAYS' : 'DAY'}`}</b>
                    </Label>
                </FormGroup> */}
                <FormGroup row>
                    <Label sm={3}>
                        <b style={{ display: 'block' }}>Original Price</b>
                    </Label>
                    <Label sm={9}>
                        <b>{this.props.price == null ? 'N/A' : `RM ${this.props.price.toFixed(2)}`}</b>
                    </Label>
                </FormGroup>

                {/* {
                    (this.props.selectedDeliveryOption === 'Courier Service') && (
                        <Fragment>
                            <FormGroup row>
                                <Label sm={3}><b>Delivery</b></Label>
                                <Col sm={9}>
                                    <Table size="sm" bordered>
                                        <thead>
                                            <tr>
                                                <th>Region</th>
                                                <th>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.deliveryInfo.map(di =>
                                                    <tr key={di.region}>
                                                        <td>{di.region}</td>
                                                        <td>RM {di.price.toFixed(2)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    <small>Final delivery charges will be calculated at checkout</small>
                                </Col>
                            </FormGroup>
                        </Fragment>
                    )
                } */}
            </Fragment>
        )
    }
}