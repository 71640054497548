import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { urlToProperty } from "query-string-params";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads,showCustomerSelectionModal } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay';
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl } from "./Utility";
import BookletPrintColourDropdownsFormGroup from './Components/BookletPrintColourDropdownsFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';

class OffsetBooklet extends Component {
    constructor(props) {
        super(props);
        this.product = 'Offset Booklet';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
                lamination: [],
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                binding: null,
                quantity: null,
                cover_material: null,
                print: null,
                lamination: null,
                inner_material: null,
                pages: null,
                // cover_artwork: null,
                // inner_artwork: null,
                // jpeg_reference: null,
                weight: 0.0
            },
            isLoadingData: true
        };

         this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        let isSameOptions = isObjValueEqual(previousProps.product.options, this.props.product.options)
        if (isSameOptions === false) {
            let newConfigurations = this.getDefaultConfigurations(this.props.product.options)
            let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.options)

            this.setState({
                dynamic_options: newDynamicOptions,
                configurations: newConfigurations,
                isLoadingData: false
            })

            return
        }

        let isSameConfigurations = isObjValueEqual(previousState.configurations, this.state.configurations);
        if (isSameConfigurations == false) {
            // Update pricing info if it's a known cariant
            let matchResult = matchCurrentConfigToVariant(this.props.product.variants.variants, this.state.configurations);
            if (matchResult.matched == true) {
                this.props.getProductTimeAndCost(this.product, this.state.configurations, matchResult.variant_id);
            } else {
                this.props.resetProductTimeAndCost()
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['cover_artwork', 'inner_artwork', 'jpeg_reference'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (['lamination'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (['quantity'].includes(name)) {
                    defaultValue = 1;
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }


    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.options will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.options) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // cover_material -> lamination
        const selectedCoverMaterial = productConfigurations.cover_material;
        const staticLaminationOptions = productOptions.lamination;
        let dynLaminationOptions = []
        staticLaminationOptions.values.forEach((element, index) => {
            if (element.cover_material.includes(selectedCoverMaterial)) {
                dynLaminationOptions.push(element.type);
            }
        })
        newDynamicOptions.lamination = dynLaminationOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        const options = this.props.product.options;
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)
        } else if (e.target.name === 'quantity') {
            // convert it to number or set to default if it's not valid
            let parsedValue = parseInt(e.target.value, 10)
            let isValid = parsedValue >= 1
            futureConfigs.quantity = isValid ? parsedValue : 1;
        }

        let futureDynOptions = { ...this.state.dynamic_options };
        let isAlignmentCheckRequired = true;
        let realignmentCount = 0;
        while (isAlignmentCheckRequired) {
            isAlignmentCheckRequired = false;
            let currentPassDynOptions = this.getDynamicOptions(futureConfigs);

            Object.keys(currentPassDynOptions).forEach(name => {
                let dynValues = currentPassDynOptions[name];
                if (futureConfigs[name] == null || dynValues.includes(futureConfigs[name]) === false) {
                    // Set configuration value to default only if the selected value is not within the new dynamic values or is null
                    console.log(`Configuration '${name}' automatically set to '${dynValues[0]}' due to changes in available option or the current value is not defined.`)
                    futureConfigs[name] = dynValues[0];
                    isAlignmentCheckRequired = true;
                }
            });

            futureDynOptions = currentPassDynOptions;
            realignmentCount++;
        }

        if (realignmentCount > 1) {
            console.log(`${realignmentCount} value realignment pass performed.`)
        }

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options, pricing } = this.props.product;

        switch (configName) {
            case 'size':
                // static options
                return (
                    <SizeFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        itemsSource={this.props.product.options[configName].values}
                        selectedItem={this.state.configurations[configName]}
                        onChange={this.handleInputChange.bind(this)}
                        showType={true}
                    />
                )

            case 'quantity':
                // manual input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={this.state.configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1 }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'print':
                // STATIC dropdown options
                return (
                    <BookletPrintColourDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={this.props.product.options[configName].values}
                        selectedItem={this.state.configurations[configName]}
                    />
                )

            case 'lamination':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={this.state.dynamic_options[configName]}
                        selectedItem={this.state.configurations[configName]}
                    />
                )

            case 'cover_material':
            case 'inner_material':
                // STATIC dropdown options with section separator
                let sectionHeader = configName === 'cover_material' ? "Cover" : "Inner";
                return (
                    <Fragment key={sectionHeader}>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>{sectionHeader}</h5>
                        <GenericDropdownsFormGroup
                            key={configName}
                            name={configName}
                            label={configSettings.label}
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={this.props.product.options[configName].values}
                            selectedItem={this.state.configurations[configName]}
                        />
                    </Fragment>
                )
            case 'binding':
            case 'pages':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={this.props.product.options[configName].values}
                        selectedItem={this.state.configurations[configName]}
                    />
                )

            case 'cover_artwork':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'offset_booklet_cover')}
                    />
                )

            case 'inner_artwork':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'offset_booklet_inner')}
                    />
                )

            case 'jpeg_reference':
                return (
                    <SelectFileFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        accept=".pdf, .rar, .zip"
                        onChange={this.handleFileSelect.bind(this, 'offset_booklet_jpeg_ref')}
                    />
                )
            default:
                break;
        }
    }

    addToCart() {
        const { cover_material, inner_material, pages, size } = this.state.configurations;
        let quantity = parseInt(this.state.configurations.quantity);

        if (!this.props.main.selectedcustomer) {
            this.props.showCustomerSelectionModal(true);
            return;
        }

        if (this.checkRequiredConfigurations() === false) {
            this.setState({ popoverOpen: true });
            return;
        }

        let paper_density = parseInt(cover_material);
        let weight = Math.ceil(2 * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2pc for front and back), round up to nearest kg then add 2kg

        paper_density = parseInt(inner_material);
        weight += Math.ceil((pages / 2) * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2 pages = 1 pc), round up to nearest kg then add 2kg

        let config = { ...this.state.configurations, weight }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        let unit_price = 0.0;
        unit_price = (this.state.price_and_duration.price / config.quantity);

  this.props.addToCart({
                product: this.product,
                configurations: config,
                unit_price: unit_price,
                duration: this.state.price_and_duration.duration,
                original_price: this.state.price_and_duration.original_price,
                adjustment_type: this.state.adjustment_type,
                adjustment_value: parseFloat(this.state.adjustment_value),
                adjustment_amount: this.state.adjustment_amount,
                price: this.state.price_and_duration.price,
            });

        this.props.history.push('/cart');
    }

    sendEnquiries() {
        alert("Send enquiries to server");
    }

    render() {
        const { options } = this.props.product;
        const { upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, time_and_cost, isLoadingData } = this.state;

        if(isLoadingData === true)
        {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                {/* <JsonDisplay object={this.state} style={{ position: 'absolute', background: 'white', zIndex: '2', right: '-300px', fontSize: '0.75em', border: '1px blue solid' }} /> */}
                <Modal isOpen={upload_filename !== ''} className='scrolling'>
                    <ModalHeader>Image Upload</ModalHeader>
                    <ModalBody>
                    {
                        (total_uploads === 0)
                            ? (<span>Contacting server . . . <br /></span>)
                            : ((total_uploads < 0)
                            ? (<span>Upload Failed! <br /></span>)
                            : ((upload_count === total_uploads)
                                ? (<span>Upload Completed! <br />( {upload_count} / {total_uploads} files )</span>)
                                : (<span>Uploading <i>{upload_filename}</i><br />( {upload_count} / {total_uploads} files )</span>)))
                    }
                    </ModalBody>
                    <ModalFooter>
                        <Button color={(upload_count === total_uploads) ? 'success' : 'secondary'} onClick={() => this.props.resetUploads()} disabled={upload_count !== total_uploads}>Close</Button>
                    </ModalFooter>
                </Modal>
                {(options) &&
                    <Fragment>
                        <h1 id="product-digital-book">{options.name.toUpperCase()}</h1>
                        <Specification productSpecs={productSpecUrl("Booklet.pdf")} priceList="#" />
                        <Form className="product-info">
                            {
                                // Ignoring _id & name
                                Object.entries(options).slice(2).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            {
                                (this.props.product.time_and_cost != null) && (
                                    <Fragment>
                                        <TimeAndCostFormGroup
                                            price={this.props.product.time_and_cost.price}
                                            processDay={this.props.product.time_and_cost.duration} />
                                        <Button onClick={this.addToCart.bind(this)}>NEXT</Button>
                                    </Fragment>
                                )
                            }
                            {
                                (this.props.product.time_and_cost == null) && (
                                    <Button onClick={this.sendEnquiries.bind(this)}>SEND ENQUIRIES FOR CUSTOM QUOTE</Button>
                                )
                            }
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart }) => {
    return { product, cart };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        showCustomerSelectionModal:showCustomerSelectionModal
        // getProductPricing: getProductPricing,
        // getProductCosts: getProductCosts,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(OffsetBooklet);
