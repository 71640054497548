import { FETCH_BANNERS, GET_PRODUCTS, GET_PRODUCT_OPTIONS, GET_PRODUCT_VARIANTS, GET_PRODUCT_SETUP, GET_PRODUCT_TIME_COST, GET_PRODUCT_PRICING, GET_PRODUCT_COSTS } from '../actions/types';

const INIT_STATE = {
    banners: null,
    products: [],
    pricing: [],
    costs: [],
    options: null,
    variants: null,
    setup: null,
    time_and_cost: null
}

export default (state = INIT_STATE, action) => {
    let data;

    switch (action.type) {
        case FETCH_BANNERS:
            console.log(action.payload);
            return { ...state, banners: action.payload }

        case GET_PRODUCTS:
            return { ...state, products: action.payload }

        case GET_PRODUCT_OPTIONS:
            return { ...state, options: action.payload }

        case GET_PRODUCT_VARIANTS:
            data = action.payload;
            if (data.length > 0) {
                data[0].variants.forEach((variant, index) => {
                    let quantity = variant.prices.map(el => el.quantity);
                    data[0].variants[index].quantity = quantity;
                });
            }
            return { ...state, variants: data[0].variants }

        case GET_PRODUCT_SETUP:
            data = action.payload;
            console.log(data);
            // TODO: Sticker does not have variants and may cause problem.
            if ((!['Digital Booklet', 'Digital Booklet Landscape', 'Sticker Label', 'Sticker Sheet Label', 'Sticker Roll Label', 'Digital Brochure','4 Colour And 1 Colour Digital Booklet'].includes(data.name)) && data.variants && (data.variants.length > 0)) {
                data.variants.forEach((variant, index) => {
                    let quantity = variant.prices.map(el => el.quantity);
                    data.variants[index].quantity = quantity;

                });
            }
            return { ...state, setup: data }

        case GET_PRODUCT_TIME_COST:
            return { ...state, time_and_cost: action.payload }

        case GET_PRODUCT_PRICING:
            return { ...state, pricing: action.payload }

        case GET_PRODUCT_COSTS:
            return { ...state, costs: action.payload }

        default:
            return state;
    }
}