import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { registerFirebase } from '../../actions';
import './App.css';

import UtechHeader from '../Layouts/UtechHeader/UtechHeader';
import UtechNavbar from '../Layouts/UtechNavbar/UtechNavbar';
import ScrollToTop from './ScrollToTop';
// import LoginPage from '../Pages/Account/LoginPage';
// import SignUpPage from '../Pages/Account/SignUpPage';
// import ForgotPasswordPage from '../Pages/Account/ForgotPasswordPage';
import HomePage from '../Pages/Home/HomePage';
import ProductPage from '../Pages/Products/ProductPage';
import ArtWorkSpecificationPage from '../Pages/ArtWorkSpecification/ArtWorkSpecificationPage';
import ProductSizeTemplatePage from '../Pages/ProductSizeTemplate/ProductSizeTemplatePage';
import ProductSpecificationsPage from '../Pages/ProductSpecifications/ProductSpecificationsPage';
import UtechFooter from '../Layouts/UtechFooter/UtechFooter';
import CartPage from '../Pages/Cart/Cart';
import Checkout from '../Pages/Checkout/Checkout';
import ThankYou from '../Pages/Checkout/ThankYou';
import Acknowledge from '../Pages/Acknowledge/Acknowledge';
import MyAccount from "../Pages/Account/MyAccount";
import Invoice from '../Pages/Invoice/Invoice';
import PasswordReset from '../Layouts/UtechHeader/PasswordReset';
import CustomerSelectionModal from '../Layouts/UtechNavbar/CustomerSelectionModal';
import Quotations from '../Pages/Quotations/Quotations';
import QuotationDetails from '../Pages/Quotations/QuotationDetails';
class App extends Component {
  divStyle = {
    height: '500px',
    backgroundColor: 'gray',
  };

  constructor(props) {
    super(props);

    this.props.registerFirebase();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      if (this.props.user && (this.props.user.status === 'INCOMPLETE')) {
        let signUpURL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/sign-up';
        if (window.location.href !== signUpURL) window.location.href = signUpURL;
      }
    }
  }

  render() {
    return (
      <Router basename="/">
        <div className="App">
          <Route component={UtechHeader} />
          <Route component={UtechNavbar} />
          <div className="page-content">
            <Switch>
              <ScrollToTop>
                <Route exact path="/" component={HomePage} />
                <Route path="/products/:type" component={ProductPage} />
                <Route path="/art-work-specification" component={ArtWorkSpecificationPage} />
                <Route path="/product-size-template" component={ProductSizeTemplatePage} />
                <Route path="/product-specifications" component={ProductSpecificationsPage} />
                <Route path="/cart" component={CartPage} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/thankyou" component={ThankYou} />
                <Route path="/acknowledge" component={Acknowledge} />
                <Route path="/my-account" component={MyAccount} />
                <Route path="/invoice" component={Invoice} />
                <Route path="/reset-password" component={PasswordReset} />
                <Route path="/customer-selection-modal" component={CustomerSelectionModal} />
                <Route path="/quotations" component={Quotations} />
                <Route path="/quotation_details/:quote_id" component={QuotationDetails} />
              </ScrollToTop>
            </Switch>
          </div>
          <UtechFooter />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ main }) => {
  const { user } = main;
  return { user };
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    registerFirebase: registerFirebase,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(App);
