export const FIREBASE_INIT = 0x01;
export const USER_INIT = 0x02;
export const REGISTER_CUSTOMER = 0x04;
export const FETCH_USER_RECORDS = 0x05;
export const ACTIVE_PAGE = 0x10;
export const SHOW_LOADING = 0x11;
export const SHOW_MESSAGE = 0x12;
export const FETCH_BANNERS = 0x20;
export const GET_PRODUCTS = 0x21;
export const GET_PRODUCT_OPTIONS = 0x22;
/** @deprecated */
export const GET_PRODUCT_COSTS = 0x23;
/** @deprecated */
export const GET_PRODUCT_PRICING = 0x24;
export const GET_PRODUCT_VARIANTS = 0x25;
export const GET_PRODUCT_SETUP = 0x26;
export const GET_PRODUCT_TIME_COST = 0x27;
export const START_FILE_UPLOAD = 0x30;
export const UPDATE_TOTAL_UPLOADS = 0x31;
export const UPLOAD_FILENAME = 0x32;
export const FILE_UPLOAD_SUCCESS = 0x33;
export const FILE_UPLOAD_FAILED = 0x34;
export const RESET_UPLOAD_COUNT = 0x35;
export const FETCH_SHIPPING_INFO = 0x40;
export const CALCULATE_SHIPPING = 0x41;
export const RESET_SHIPPING = 0x42;
export const ADD_TO_CART = 0x43;
export const REMOVE_FROM_CART = 0x44;
export const UPDATE_CART = 0x45;
export const EMPTY_CART = 0x46;
export const SAVE_ORDER = 0x47;
export const VERIFY_VOUCHER = 0x48;
export const LOGIN_MODAL = 0x50;
export const LOGIN_SUCCESS_CB = 0x51;
export const GET_CUSTOMERS = 0x52;
export const LOGIN= 0x53;
export const LOGOUT = 0x54;
export const CUSTOMER_SELECTION_MODAL = 0x55;
export const SELECT_CUSTOMER  = 0x56;
export const GET_PAYMENT_TERMS = 0x57;
export const GET_DELIVERY_SCHEDULE = 0x58;
export const SELECTED_QUOTE = 0x59;
export const SET_QUOTES_FILTER = 0x60;
export const GET_QUOTES = 0x61;
export const UPDATE_QUOTE = 0x62;
export const SORT_QUOTATION = 0x63;
export const EMPTY_QUOTE = 0x64;
