import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Image, Label, Step, Table, Row, Col, Modal, Button, Form, Input } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope, faCreditCard, faAddressBook, faTruck, faShoppingBag, faPrint, faCheckCircle, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { capitalizeKey } from '../../../config';
import { getQuotes, selectedQuote, showLoading } from '../../../actions';
import './Quotation.css'

class QuotationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartOpen: true,
            logOpen: false,
            quoteID: -1,
            addNoteDialog: false,
            processDialog: false,
            cancelDialog: false,
            paymentChecked: true,
            paymentMethod: 'Cash',
            cancellationNote: '',
            miscNote: '',
            editQuotationModalOpen: false,
            selectedQuote: null,
        }

        window.Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
            return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
        });

        window.Handlebars.registerHelper('currency', function (value) {
            return value.toFixed(2);
        });

        window.Handlebars.registerHelper('date', function (date) {
            return moment(date).format('DD/MM/YYYY');
        });

        window.Handlebars.registerHelper('math', function (lvalue, operator, rvalue, options) {
            lvalue = parseFloat(lvalue);
            rvalue = parseFloat(rvalue);

            return {
                "+": lvalue + rvalue,
                "-": lvalue - rvalue,
                "*": lvalue * rvalue,
                "/": lvalue / rvalue,
                "%": lvalue % rvalue
            }[operator];
        });

        window.Handlebars.registerHelper('showKey', function (value) {
            const capitalize = text => text
                .split('_')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            return capitalize(value);
        });

        window.Handlebars.registerHelper('ifIncludes', function (arg1, arg2, options) {
            return (arg1.toString().includes(arg2)) ? options.fn(this) : options.inverse(this);
        });

        window.Handlebars.registerHelper('isNo', function (value) {
            if (value === 'No')
                return;
        });

        window.Handlebars.registerHelper('Uppercase', function (value) {
            return value.toUpperCase();
        });

        window.Handlebars.registerHelper('currency4dec', function (value) {
            return value.toFixed(4);
        });
    }

    componentWillMount() {
        if (!this.props.user) {
            this.props.history.push('/');
        }
        this.props.showLoading(true);
        this.props.getQuotes({});
    }


    componentDidUpdate(prevProps) {
        if (this.props.quote.quote_list !== prevProps.quote.quote_list) {
            this.props.showLoading(false);
            let quote_id = this.props.match.params.quote_id;
            let quote = this.props.quote.quote_list.find((n) => n._id === quote_id);
            console.log(quote_id)
            console.log(quote)
            this.props.selectedQuote({ quote });
            this.setState({ quoteID: quote_id, selectedQuote: quote });
        }

    }

    componentWillUnmount(){
        this.props.showLoading(false);
    }


    handlePrintQuotation() {
        console.log(this.state.selectedQuote);
        let html = window.Handlebars.templates.quotation(this.state.selectedQuote);
        // let strWindowFeatures = "menubar=yes,location=no,resizable=no,scrollbars=yes,status=no";
        // window.open(window.location.hostname + ':' + window.location.port + html, "_blank", strWindowFeatures);
        let stmt_wnd = window.open("", "_blank");
        stmt_wnd.document.write(html);
    }

    handleEditQuote() {
        let newQuoteProduct = null;
        this.state.selectedQuote.quote_details.items.forEach((item) => {
            newQuoteProduct = item.product;
        });
        console.log(newQuoteProduct);
        this.props.history.push('/products/' + newQuoteProduct)
    }


    render() {
        const { logOpen, cartOpen, selectedQuote, cancellationNote, miscNote, addNoteDialog, processDialog, cancelDialog, editQuotationModalOpen } = this.state;
        console.log(selectedQuote);
        return (
            <Fragment>
                <div style={{ marginTop: '2em' }}>
                    {selectedQuote &&
                        <Container className="quotation-container">
                            <Row>
                                <Col className="quotation-id-title">
                                    <b>Quotation #{selectedQuote._id}</b>
                                </Col>
                                <div className="quote-details-features">
                                    <Button outline color="secondary" className="preview-quote" onClick={()=> {console.log('to send via email');}}><FontAwesomeIcon icon={faEnvelope} /></Button>
                                </div>
                                <div className="quote-details-features">
                                    <Button outline color="secondary" className="preview-quote" onClick={this.handlePrintQuotation.bind(this)}><FontAwesomeIcon icon={faPrint} /></Button>
                                </div>
                            </Row>

                            {/* <Popup
                                    trigger={<Icon bordered className="clickable" name="print" onClick={this.handlePrintQuotation.bind(this)} />}
                                    content='Print Quotation'
                                    style={{ opacity: 0.7 }}
                                    inverted
                                />
                                <Popup
                                    trigger={<Icon className="clickable" bordered name='edit' onClick={this.handleAddNote.bind(this)} />}
                                    content='Add Note'
                                    style={{ opacity: 0.7 }}
                                    inverted
                                /> */}

                            <Row xs="3" className="pd-tp20">
                                <Col>
                                    <div style={{ marginBottom: "12px" }}> <FontAwesomeIcon icon={faCreditCard} /> &nbsp;<b>Quotation Details</b></div>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className="border-none">Quote Date</td>
                                                <td className="border-none"> {moment(selectedQuote.quote_date).format('DD/MM/YYYY HH:mm')}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount</td>
                                                <td>RM {selectedQuote.quote_details.total_amount.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Terms</td>
                                                <td>{selectedQuote.quote_details.payment_terms}</td>
                                            </tr>
                                            <tr>
                                                <td>Process Days</td>
                                                <td>{selectedQuote.quote_details.process_days}</td>
                                            </tr>
                                            <tr>
                                                <td>STATUS</td>
                                                <td>
                                                    {
                                                        (selectedQuote.status === 'Approved') ? <Button style={{ backgroundColor: 'green', backgroundColor: 'green' }}> <FontAwesomeIcon icon={faCheckCircle} color='white' size='lg' />&nbsp;{selectedQuote.status.toUpperCase()}</Button>
                                                            : (selectedQuote.status === 'Submitted') ? <Button style={{ backgroundColor: 'teal', borderColor: 'teal' }}><FontAwesomeIcon icon={faPlusCircle} color='white' size='lg' /> &nbsp;{selectedQuote.status.toUpperCase()}
                                                            </Button>
                                                                : <Button style={{ backgroundColor: '#e91717', borderColor: '#e91717' }}><FontAwesomeIcon icon={faMinusCircle} color='white' size='lg' />&nbsp;{selectedQuote.status.toUpperCase()}</Button>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col className="billing-border">
                                    <FontAwesomeIcon icon={faAddressBook} /> &nbsp;<b>Billing Details</b><br /><br />
                                    {selectedQuote.quote_details.customer.name}<br />
                                    {selectedQuote.quote_details.customer.company}<br />
                                    {selectedQuote.quote_details.customer.address.address_1}<br />
                                    {(selectedQuote.quote_details.customer.address.address_2.length > 0) && selectedQuote.quote_details.customer.address.address_2}<br />
                                    {selectedQuote.quote_details.customer.address.postcode} {selectedQuote.quote_details.customer.address.town_city}<br />
                                    {selectedQuote.quote_details.customer.address.state}<br />
                                    {selectedQuote.quote_details.customer.address.country}<br />
                                    <br />
                                    <FontAwesomeIcon icon={faMobile} /> &nbsp;
                                    <a href={'tel:' + selectedQuote.quote_details.customer.mobile} target="_blank">{selectedQuote.quote_details.customer.mobile}</a><br />
                                    <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                                    <a href={'mailto:' + selectedQuote.quote_details.customer.email} target="_blank">{selectedQuote.quote_details.customer.email}</a>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faTruck} /> &nbsp;<b>Shipping Details</b><br /><br />
                                    {(selectedQuote.quote_details.recipient)
                                        ? (<div>
                                            {selectedQuote.quote_details.recipient.name}<br />
                                            {selectedQuote.quote_details.recipient.address.address_1}<br />
                                            {(selectedQuote.quote_details.recipient.address.address_2.length > 0) && selectedQuote.quote_details.recipient.address.address_2}
                                            {selectedQuote.quote_details.recipient.address.postcode} {selectedQuote.quote_details.recipient.address.town_city}<br />
                                            {selectedQuote.quote_details.recipient.address.state}<br />
                                            {selectedQuote.quote_details.recipient.address.country}<br />
                                            <br />
                                            {(selectedQuote.quote_details.recipient.phone.length > 0) && <a href={'tel:' + selectedQuote.quote_details.recipient.phone} target="_blank">{selectedQuote.quote_details.recipient.phone}</a>}
                                        </div>
                                        )
                                        : (<div>
                                            {selectedQuote.quote_details.customer.name}<br />
                                            {selectedQuote.quote_details.customer.address.address_1}<br />
                                            {(selectedQuote.quote_details.customer.address.address_2.length > 0) && selectedQuote.quote_details.customer.address.address_2}
                                            {selectedQuote.quote_details.customer.address.postcode} {selectedQuote.quote_details.customer.address.town_city}<br />
                                            {selectedQuote.quote_details.customer.address.state}<br />
                                            {selectedQuote.quote_details.customer.address.country}<br />
                                            <br />
                                            {(selectedQuote.quote_details.customer.mobile.length > 0) && <a href={'tel:' + selectedQuote.quote_details.customer.mobile} target="_blank">{selectedQuote.quote_details.customer.mobile}</a>}
                                        </div>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="quotation-id-title">
                                    <FontAwesomeIcon icon={faShoppingBag} /> &nbsp; <b>Products</b>
                                </Col>
                            </Row>
                            <Table responsive style={{ wordBreak: 'break-word' }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>SKU</th>
                                        <th style={{ textAlign: 'left' }}>Description</th>
                                        <th style={{ textAlign: 'right' }}>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedQuote.quote_details.items && selectedQuote.quote_details.items.map((item) => (
                                            <tr key={item.id}>
                                                <td style={{ verticalAlign: 'middle' }}>{item.product}</td>
                                                <td>
                                                    <Table borderless responsive hover className="table-border">
                                                        <tbody>
                                                            {
                                                                Object.keys(item.configurations).map(key => {
                                                                    let value = item.configurations[key];
                                                                    if (key === 'size') {
                                                                        value = (value.type) ? `${value.type}` : `${value.height} mm x ${value.width} mm`;
                                                                    }

                                                                    if (key === 'weight') {
                                                                        value = `${value} KG`;
                                                                    }

                                                                    if (key === 'other1' || key === 'other2' || key === 'other3') {
                                                                        if (value.othersSelected == true) {
                                                                            return (
                                                                                <tr key={value.name}>
                                                                                    <td>{capitalizeKey(value.name)}</td>
                                                                                    <td>{value.value ? value.value : "<Empty>"}  - RM {value.price}  </td>
                                                                                </tr>
                                                                            )
                                                                        } else return;
                                                                    }

                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{capitalizeKey(key)}</td>
                                                                            <td>{value}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            <tr>
                                                                <td>Unit Price</td>
                                                                <td>{item.unit_price.toFixed(3)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    {(item.adjustment_value !== 0) && (item.adjustment_value) &&
                                                        <Table borderless responsive hover className="table-border">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        Original Price
                                                                    </td>
                                                                    <td>
                                                                        RM {item.original_price.toFixed(2)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Adjustment
                                                                    </td>
                                                                    <td>
                                                                        RM {item.adjustment_amount.toFixed(2)} {(item.adjustment_type === '%') && '(' + item.adjustment_value.toFixed(2) + '%)'}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    }
                                                </td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'right' }} >RM {item.price.toFixed(2)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th>SUBTOTAL</th>
                                        <th style={{ textAlign: 'right' }}>RM {selectedQuote.quote_details.subtotal.toFixed(2)}</th>
                                    </tr>
                                    {/* <tr>
                                        <th></th>
                                        <th textAlign='right'>ADJUSTMENT {(selectedQuote.quote_details.adjustment_type === '%') && <span>({selectedQuote.quote_details.adjustment_value} {selectedQuote.quote_details.adjustment_type})</span>}</th>
                                        <th textAlign='right'>RM {selectedQuote.quote_details.adjustment_amount.toFixed(2)}</th>
                                    </tr> */}
                                    <tr>
                                        <th></th>
                                        <th>SHIPPING ({selectedQuote.quote_details.delivery_option})</th>
                                        <th style={{ textAlign: 'right' }}>RM {selectedQuote.quote_details.shipping_fee.toFixed(2)}</th>
                                    </tr>
                                    {(selectedQuote.quote_details.shipping_disc > 0) &&
                                        (<tr>
                                            <th></th>
                                            <th>DISCOUNT (Shipping)</th>
                                            <th style={{ textAlign: 'right' }}>RM -{selectedQuote.quote_details.shipping_disc.toFixed(2)}</th>
                                        </tr>)
                                    }

                                    <tr>
                                        <th></th>
                                        <th><b>TOTAL</b></th>
                                        <th style={{ textAlign: 'right' }}><b>RM {selectedQuote.quote_details.total_amount.toFixed(2)}</b></th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <Row>
                                <Col className="edit_quote_btn">
                                    <Button onClick={this.handleEditQuote.bind(this)}>Clone Quotation</Button>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ main, quote }) => {
    return { user: main.user, quote }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        selectedQuote: selectedQuote,
        getQuotes: getQuotes,
        showLoading: showLoading,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(QuotationDetails);