import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { urlToProperty } from "query-string-params";
import {
    Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
    InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody
} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart, showCustomerSelectionModal, getCustomers, selectCustomer, emptyCart, emptyQuote } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay';
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl } from "./Utility";
import BookletPrintColourDropdownsFormGroup from './Components/BookletPrintColourDropdownsFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import SteppingDropdownsFormGroup from './Components/SteppingDropdownsFormGroup';

class DigitalBooklet extends Component {
    constructor(props) {
        super(props);
        this.product = '4 Colour Digital Booklet';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
                cover_lamination: [],
                inner_lamination: [],
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                binding: null,
                quantity: 0,
                cover_material: null,
                print: null,
                print_inner: null,
                inner_material: null,
                pages: null,
                cover_lamination: null,
                inner_lamination: null,
                cover_spotuv: null,
                inner_spotuv: null,
                folding: null,
                diecut: null,
                perforation: null,
                numbering: 0,
                // cover_artwork: null,
                // cover_artwork_path: null,
                // cover_artwork_download_url: null,
                // inner_artwork: null,
                // inner_artwork_path: null,
                // inner_artwork_download_url: null,
                // jpeg_reference: null,
                // jpeg_reference_path: null,
                // jpeg_reference_download_url: null,
                weight: 0.0,
                other1: { othersSelected: false, name: 'Other1', value: '', price: 0.0 },
                other2: { othersSelected: false, name: 'Other2', value: '', price: 0.0 },
                other3: { othersSelected: false, name: 'Other3', value: '', price: 0.0 },
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                original_price: 0.0,
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            invalid: false,
            invalid_pages: false,
            invalid_binding: false,
            adjustment_value: 0.0,
            adjustment_amount: 0.0,
            adjustment_type: "%",
            customer: null,
            adj_cover_print_price: 0.0,
            adj_inner_print_price: 0.0,
            sys_cover_print_price: 0.0,
            sys_inner_print_price: 0.0,
            adjustPrintingSwitch: false,
            customeroption: [],
            selectedCustomer: null,
            unit_price: 0.0,
            popoverOpen: false,
            popoverTarget: 'name',
            popoverText: '',
            title: '',
            customerName: null,
            customer_email_validation: null,
            matchQuoteConfig: false,
        };
        this.customerRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.props.getProductSetup('Digital Booklet');
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`;
        // if (this.props.main.selectedcustomer) {
        //     let newCustomer = this.props.main.selectedcustomer;
        //     this.setState({ customer: newCustomer });
        // }
        this.props.getCustomers();
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        // if (this.props.main.selectedcustomer !== previousProps.main.selectedcustomer) {
        //     let newCustomer = this.props.main.selectedcustomer;
        //     this.setState({ customer: newCustomer });
        // }

        if (this.props.checkout.list && (this.props.checkout.list !== previousProps.checkout.list)) {
            let customeroption = this.props.checkout.list.map((obj, index) => {
                let newObj = {}; newObj.label = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.value = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.email = obj.email; newObj.index = index;
                return newObj;
            });

            console.log(customeroption)
            this.setState({ data: _.chunk(this.props.checkout.list), customeroption: _.sortBy(customeroption, ['label']) });
        }

        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            const { selectedQuoteProduct } = this.props.quote; //Product From Existed Quote
            let selectedProduct = this.props.cart.items.find((el) => el.product === '4 Colour Digital Booklet');

            if (selectedQuoteProduct && (selectedQuoteProduct.product === '4 Colour Digital Booklet')) {
                this.props.emptyCart(); // To Replace Current Cart Item
                let newConfigurations = selectedQuoteProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedQuoteProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedQuoteProduct.customer,
                    title: selectedQuoteProduct.title,
                    adjustment_amount: selectedQuoteProduct.adjustment_amount,
                    adjustment_type: selectedQuoteProduct.adjustment_type,
                    adjustment_value: selectedQuoteProduct.adjustment_value,
                    adjustPrintingSwitch: selectedQuoteProduct.adjustPrintingSwitch,
                    adj_cover_print_price: selectedQuoteProduct.adj_cover_print_price,
                    adj_inner_print_price: selectedQuoteProduct.adj_inner_print_price,
                    customerName: selectedQuoteProduct.customer.name,
                    customer_email_validation: selectedQuoteProduct.customer.email,
                });
                // Fetch Cart Data and Apply It
            } else if (selectedProduct) {
                let newConfigurations = selectedProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedProduct.customer,
                    title: selectedProduct.title,
                    adjustment_amount: selectedProduct.adjustment_amount,
                    adjustment_type: selectedProduct.adjustment_type,
                    adjustment_value: selectedProduct.adjustment_value,
                    adjustPrintingSwitch: selectedProduct.adjustPrintingSwitch,
                    adj_cover_print_price: selectedProduct.adj_cover_print_price,
                    adj_inner_print_price: selectedProduct.adj_inner_print_price,
                    customerName: selectedProduct.customer.name,
                    customer_email_validation: selectedProduct.customer.email,
                });
            } else {
                let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: newConfigurations,
                    isLoadingData: false
                });
            }
        }


        if ((previousState.configurations !== this.state.configurations) || (previousState.adj_cover_print_price !== this.state.adj_cover_print_price) || (previousState.adj_inner_print_price !== this.state.adj_inner_print_price) || (previousState.adjustPrintingSwitch !== this.state.adjustPrintingSwitch) || (this.state.selectedCustomer !== previousState.selectedCustomer)) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // After getting Dynamic Options, lamination and binding configs need to be defaulted to new dynamic option defaults otherwise it is stale
            if (!newDynamicOptions.cover_lamination.includes(this.state.configurations.cover_lamination)) newConfig.cover_lamination = newDynamicOptions.cover_lamination[0];
            if (!newDynamicOptions.inner_lamination.includes(this.state.configurations.inner_lamination)) newConfig.inner_lamination = newDynamicOptions.inner_lamination[0];
            if (newConfig.binding !== previousState.configurations.binding) {
                if (!newDynamicOptions.cover_material.includes(this.state.configurations.cover_material)) {
                    // Defaulting Cover Material
                    newConfig.cover_material = newDynamicOptions.cover_material[0];
                    window.alert('Cover material reset due to book binding changed');
                }

                let pages = parseInt(newConfig.pages);

                if ((pages < newDynamicOptions.pages.range.min)
                    || (pages > newDynamicOptions.pages.range.max)
                    || (((pages - newDynamicOptions.pages.range.min) % newDynamicOptions.pages.range.step) !== 0)) {
                    // Defaulting Inner Pages
                    newConfig.pages = newDynamicOptions.pages.range.min;
                    window.alert('Inner pages reset due to book binding changed');
                }
            }

            // Price calculation
            if (this.props.product.setup) {
                // console.log(this.props.product.setup.variants[0]);
                const { size, cover_material, cover_lamination, inner_lamination, print, binding, inner_material } = this.state.configurations;
                let quantity = parseInt(this.state.configurations.quantity);
                let pages = parseInt(this.state.configurations.pages);

                let pricing = this.props.product.setup.variants[0];

                // Calculate cover paper price
                let cover_paper = pricing.material.prices.find((el) => cover_material.includes(el.paper_type) && cover_material.includes(el.weight));
                let cover_paper_price = NaN;

                // Assuming it is only A5 or 4x6 as both calculation are same, if not paper sizes are added will need more conditions
                let adj_cover_qty = Math.ceil(((size.type === 'A4') || (size.type === 'B5')) ? quantity + 5 : (quantity + 5) / 2);
                let adj_inner_qty = quantity + 5;
                // console.log('Adj Cover Qty = ' + adj_cover_qty);
                // console.log('Adj Inner Qty = ' + adj_inner_qty);

                if (cover_paper) {
                    cover_paper_price = adj_cover_qty * cover_paper.unit_price;
                }

                console.log('Cover Paper Unit Price = ' + cover_paper.unit_price);
                console.log('Cover Paper Price = ' + cover_paper_price);

                if (cover_material.toUpperCase().includes('PAPER')) {
                    if (cover_paper_price < pricing.material.min_paper_price) {
                        cover_paper_price = pricing.material.min_paper_price;
                    }
                } else {
                    if (cover_paper_price < pricing.material.min_card_price) {
                        cover_paper_price = pricing.material.min_card_price;
                    }
                }
                // console.log('Updated Cover Paper Price = ' + cover_paper_price);

                // Calculate cover lamination price
                let cover_lamination_price = NaN;

                if (cover_lamination.toUpperCase().includes('FRONT')) {
                    cover_lamination_price = ((adj_cover_qty - 35) * 12.3 * 25 * pricing.lamination.price_per_sq_inch) + pricing.lamination.one_sided_min_price;
                    if (cover_lamination_price < pricing.lamination.one_sided_min_price) {
                        cover_lamination_price = pricing.lamination.one_sided_min_price;
                    }
                } else if (cover_lamination.toUpperCase().includes('BOTH')) {
                    cover_lamination_price = ((adj_cover_qty - 40) * 12.3 * 25 * pricing.lamination.price_per_sq_inch * 2) + pricing.lamination.two_sided_min_price;
                    if (cover_lamination_price < pricing.lamination.two_sided_min_price) {
                        cover_lamination_price = pricing.lamination.two_sided_min_price;
                    }
                } else {
                    cover_lamination_price = 0.0;
                }

                // Calculate inner paper price
                let sheets_per_book = ((size.type === 'A4') || (size.type === 'B5')) ? Math.ceil(pages / 4) : (Math.ceil(pages / 4) / 2);
                let inner_paper = pricing.material.prices.find((el) => inner_material.includes(el.paper_type) && inner_material.includes(el.weight));
                let inner_paper_price = NaN;
                if (adj_inner_qty && inner_paper && sheets_per_book) {
                    inner_paper_price = adj_inner_qty * sheets_per_book * inner_paper.unit_price;
                    console.log('Inner paper unit price = ' + inner_paper.unit_price);
                }

                console.log('Inner Paper Price = ' + inner_paper_price);

                if (inner_material.toUpperCase().includes('PAPER')) {
                    if (inner_paper_price < pricing.material.min_paper_price) {
                        inner_paper_price = pricing.material.min_paper_price;
                    }
                } else {
                    if (inner_paper_price < pricing.material.min_card_price) {
                        inner_paper_price = pricing.material.min_card_price;
                    }
                }
                // console.log('Updated Inner Paper Price = ' + inner_paper_price);

                // Calculate inner lamination price
                let inner_lamination_price = NaN;

                if (inner_lamination.toUpperCase().includes('FRONT')) {
                    inner_lamination_price = (((adj_inner_qty * sheets_per_book) - 35) * 12.3 * 25 * pricing.lamination.price_per_sq_inch) + pricing.lamination.one_sided_min_price;
                    if (inner_lamination_price < pricing.lamination.one_sided_min_price) {
                        inner_lamination_price = pricing.lamination.one_sided_min_price;
                    }
                } else if (inner_lamination.toUpperCase().includes('BOTH')) {
                    inner_lamination_price = (((adj_inner_qty * sheets_per_book) - 40) * 12.3 * 25 * pricing.lamination.price_per_sq_inch * 2) + pricing.lamination.two_sided_min_price;
                    if (inner_lamination_price < pricing.lamination.two_sided_min_price) {
                        inner_lamination_price = pricing.lamination.two_sided_min_price;
                    }
                } else {
                    inner_lamination_price = 0.0;
                }

                // Calculate printing price

                // INFO: Currently only use 4-color paper printing pricing until further decision
                let cover_sides = (print.toUpperCase().includes('OUTER') && print.toUpperCase().includes('INNER')) ? 2 : 1;
                let cover_print_qty = adj_cover_qty * cover_sides;
                let inner_print_qty = adj_inner_qty * sheets_per_book;
                let printing_quantity = cover_print_qty + inner_print_qty;

                let printing_price = NaN;
                let cover_printing_price = NaN;
                let inner_printing_price = NaN;
                let unit_cover_print_price = 0.18;
                let unit_inner_print_price = 0.18;
                let cover_print_price_entry = null;
                let inner_print_price_entry = null;
                let min_cover_print_price = NaN;
                let min_inner_print_price = NaN;
                let print_colours = newConfig.print.toUpperCase().includes('4 COLOUR') ? 4 : 1;
                console.log(print_colours)
                let print_inner_colours = newConfig.print_inner.toUpperCase().includes('4 COLOUR') ? 4 : 1;

                // Calculate cover printing price
                cover_print_price_entry = pricing.printing.find((el) => ((el.material.toUpperCase() === 'PAPER') && (el.type.includes(print_colours))));
                if (cover_print_price_entry) {
                    let cover_sides = (print.toUpperCase().includes('OUTER') && print.toUpperCase().includes('INNER')) ? 2 : 1;
                    cover_print_qty = adj_cover_qty * cover_sides;
                    console.log('Cover print quantity = ' + cover_print_qty);

                    cover_print_price_entry.prices.forEach((el) => {
                        if (el.from < cover_print_qty) unit_cover_print_price = el.unit_price;
                    });
                    min_cover_print_price = cover_print_price_entry.min_printing_price;
                }

                // Calculate inner printing price 
                inner_print_price_entry = pricing.printing.find((el) => (inner_material.toUpperCase().includes(el.material.toUpperCase()) && (el.type.includes(print_inner_colours))));
                if (inner_print_price_entry) {
                    inner_print_qty = adj_inner_qty * sheets_per_book;
                    console.log('Inner print quantity = ' + inner_print_qty);

                    inner_print_price_entry.prices.forEach((el) => {
                        if (el.from < inner_print_qty) unit_inner_print_price = el.unit_price;
                    });
                    min_inner_print_price = inner_print_price_entry.min_printing_price;
                }

                // Selecting printing rate
                if ((this.state.selectedCustomer) && (this.state.selectedCustomer.meter_click) && (this.state.selectedCustomer.meter_click.enable)) {
                    // Customer profile meter click rate

                    cover_print_price_entry = this.state.selectedCustomer.meter_click.printing.find((el) => ((cover_material.toUpperCase().includes(el.material.toUpperCase())) && (el.type.includes(print_colours))));
                    unit_cover_print_price = cover_print_price_entry.unit_price;

                    inner_print_price_entry = this.state.selectedCustomer.meter_click.printing.find((el) => ((inner_material.toUpperCase().includes(el.material.toUpperCase())) && (el.type.includes(print_inner_colours))));
                    unit_inner_print_price = inner_print_price_entry.unit_price;
                }

                this.setState({ sys_cover_print_price: unit_cover_print_price, sys_inner_print_price: unit_inner_print_price });

                // When printing price override is enable
                if (this.state.adjustPrintingSwitch === true) {
                    unit_cover_print_price = this.state.adj_cover_print_price;
                    unit_inner_print_price = this.state.adj_inner_print_price;
                } else {
                    this.setState({ adj_cover_print_price: unit_cover_print_price, adj_inner_print_price: unit_inner_print_price });
                }

                console.log('Updated unit cover printing price = ' + unit_cover_print_price);
                console.log('Min cover printing price = ' + min_cover_print_price);

                console.log('Updated unit inner printing price = ' + unit_inner_print_price);
                console.log('Min printing inner price = ' + min_inner_print_price);


                cover_printing_price = cover_print_qty * unit_cover_print_price;
                inner_printing_price = inner_print_qty * unit_inner_print_price * 2;

                if (cover_printing_price < min_cover_print_price) cover_printing_price = min_cover_print_price;
                if (inner_printing_price < min_inner_print_price) inner_printing_price = min_inner_print_price;

                printing_price = cover_printing_price + inner_printing_price;

                // Calculate binding price
                let binding_price = null;
                if (binding.trim().toUpperCase() === 'SADDLE STITCH') {
                    binding_price = (quantity + 5 - pricing.misc.saddlestitch_cutoff_qty) * pricing.misc.saddlestitch_price_increment + pricing.misc.saddlestitch_min_price;
                    if (binding_price < pricing.misc.saddlestitch_min_price) {
                        binding_price = pricing.misc.saddlestitch_min_price;
                    }
                    this.setState({ invalid_binding: false })
                } else if (binding.trim().toUpperCase() === 'PERFECT BINDING') {
                    binding_price = (quantity + 5 - pricing.misc.perfectbind_cutoff_qty) * pricing.misc.perfectbind_price_increment + pricing.misc.perfectbind_min_price;
                    if (binding_price < pricing.misc.perfectbind_min_price) {
                        binding_price = pricing.misc.perfectbind_min_price;
                    }
                    this.setState({ invalid_binding: false })
                } else if (newConfig.binding.toUpperCase() === 'WIRE-O') {
                    //Calculate wire-o price
                    console.log('Selected WIRE-O');
                    let selectedSize = pricing.wire_o.prices.find((el) => el.paper_size === size.type);
                    if (selectedSize) {
                        if (newConfig.quantity > selectedSize.cutoff_quantity) binding_price = newConfig.quantity * selectedSize.high_qty_unit_price;
                        else binding_price = newConfig.quantity * selectedSize.low_qty_unit_price;
                        binding_price = Math.max(binding_price, selectedSize.min_price);
                        this.setState({ invalid_binding: false });
                    }
                    else {
                        binding_price = 0.0;
                        this.setState({ invalid_binding: true, errorTextBinding: 'Your Book Closed Size Doest Not Have the Pricing for Wire-O. Please Try Another Size!' });
                    }
                } else if (newConfig.binding.toUpperCase() === 'THREADSEWING') {
                    console.log('Selected THREADSEWING');
                    //Calculate threadsewing price
                    binding_price = ((sheets_per_book * pricing.misc.threadsewing_fold_sewing_unit_price) + pricing.misc.threadsewing_perfectbind_unit_price) * newConfig.quantity;
                    console.log('threadsewing price before find minimum price = ' + binding_price)
                    binding_price = Math.max(pricing.misc.threadsewing_min_price, binding_price);
                    this.setState({ invalid_binding: false })
                }

                // Calculate diecut price
                let diecut_price = NaN;
                if (newConfig.diecut.toUpperCase() === 'YES') {
                    diecut_price = parseFloat((Math.max(pricing.misc.diecut_min_price, newConfig.quantity * pricing.misc.diecut_unit_price)));
                }
                else {
                    diecut_price = 0.0;
                }

                console.log('diecut price = ' + diecut_price);


                // Calculate cover spot uv price
                let cover_spotuv_price = NaN;
                if (newConfig.cover_spotuv.toUpperCase() === 'YES') {
                    cover_spotuv_price = parseFloat(Math.max(pricing.misc.spotuv_min_price, newConfig.quantity * pricing.misc.spotuv_unit_price).toFixed(2));
                }
                else {
                    cover_spotuv_price = 0.0;
                }

                console.log('cover spotuv price = ' + cover_spotuv_price);

                // Calculate inner spot uv price
                let inner_spotuv_price = NaN;
                if (newConfig.inner_spotuv.toUpperCase() === 'YES') {
                    inner_spotuv_price = parseFloat(Math.max(pricing.misc.spotuv_min_price, newConfig.quantity * pricing.misc.spotuv_unit_price).toFixed(2));
                }
                else {
                    inner_spotuv_price = 0.0;
                }

                console.log('inner spotuv price = ' + inner_spotuv_price);

                // Calculate folding price
                let folding_price = NaN;
                if (!newConfig.folding.toUpperCase().includes('NO')) {
                    folding_price = pricing.misc.folding_min_price;
                    folding_price += (newConfig.quantity > pricing.misc.folding_cutoff_qty) ? (newConfig.quantity - pricing.misc.folding_cutoff_qty) * pricing.misc.folding_price_increment : 0;
                }
                else {
                    folding_price = 0.0;
                }

                console.log('folding price = ' + folding_price);

                // Calculate numbering price
                let numbering_price = (newConfig.numbering > 0) ? Math.max(((parseInt(newConfig.numbering) + 1) * newConfig.quantity / 100), pricing.misc.numbering_min_price) : 0;
                console.log('numbering price = ' + numbering_price);

                // Calculate perforation price
                let perforation_price = NaN;
                if (newConfig.perforation.toUpperCase() === 'YES') {
                    perforation_price = Math.max((newConfig.quantity * pricing.misc.perforation_unit_price), pricing.misc.perforation_min_price);
                } else {
                    perforation_price = 0.0;
                }

                console.log('perforation price = ' + perforation_price);

                console.log('Sheets per book = ' + sheets_per_book);
                console.log('Cover Paper = ' + cover_paper_price);
                console.log('Inner Paper = ' + inner_paper_price);
                console.log('Cover Lamination = ' + cover_lamination_price);
                console.log('Inner Lamination = ' + inner_lamination_price);
                console.log('Cover Printing = ' + cover_printing_price);
                console.log('Inner Printing = ' + inner_printing_price);
                console.log('Binding = ' + binding_price);
                if ((cover_paper_price !== NaN) && (cover_lamination_price !== NaN) && (inner_lamination_price !== NaN) && (inner_paper_price !== NaN) && (printing_price && binding_price !== NaN)) {
                    console.log('total' + (cover_paper_price + cover_lamination_price + inner_lamination_price + inner_lamination_price + inner_paper_price + printing_price + binding_price + cover_spotuv_price + inner_spotuv_price + folding_price + diecut_price + perforation_price + numbering_price + parseFloat(this.state.configurations.other1.price) + parseFloat(this.state.configurations.other2.price) + parseFloat(this.state.configurations.other3.price)))
                    newPriceAndDuration.original_price = Math.ceil(cover_paper_price + cover_lamination_price + inner_lamination_price + inner_paper_price + printing_price + binding_price + cover_spotuv_price + inner_spotuv_price + folding_price + diecut_price + perforation_price + numbering_price) + parseFloat(this.state.configurations.other1.price) + parseFloat(this.state.configurations.other2.price) + parseFloat(this.state.configurations.other3.price);
                    newPriceAndDuration.price = Math.ceil(newPriceAndDuration.original_price) + this.state.adjustment_amount;
                    newPriceAndDuration.duration = 3;   // Process Day is defaulted to 3 days until further notice

                    // Special pricing handling requested for newly added B5 and 4x6 by discounting total price by 1% as differentiation
                    if ((size.type === 'B5') || (size.type === '4x6')) { newPriceAndDuration.original_price *= 0.99; newPriceAndDuration.price = newPriceAndDuration.original_price + this.state.adjustment_amount; }
                } else {
                    newPriceAndDuration.original_price = NaN;
                    newPriceAndDuration.price = NaN;
                    newPriceAndDuration.duration = 0;
                }
            }

            // Check Config Match With Product Options
            let checkMatchConfig = null;
            if (this.props.quote.selectedQuoteProduct) {
                for (const [key, value] of Object.entries(newConfig)) {
                    for (const [opt_key, opt_value] of Object.entries(this.props.product.setup.options)) {
                        if (key === 'pages' || key === 'quantity') {
                            console.log('Skip the Config')
                        } else if (key === 'print_inner') {
                            if (value !== this.props.product.setup.options[key].values) { this.setState({ matchQuoteConfig: true }); }
                        } else if (key === 'cover_lamination' || key === 'inner_lamination') {
                            checkMatchConfig = this.props.product.setup.options[key].values.some((el) => { return el.type === value; });
                            if (checkMatchConfig === false) this.setState({ matchQuoteConfig: true });
                        } else if (key === 'size') {
                            checkMatchConfig = this.props.product.setup.options[key].values.some((el) => { return el.type === value.type; });
                            if (checkMatchConfig === false) this.setState({ matchQuoteConfig: true });
                        } else if (key === 'numbering') {
                            checkMatchConfig = this.props.product.setup.options[key].values.some((el) => el === parseInt(value));
                            if (checkMatchConfig === false) this.setState({ matchQuoteConfig: true });
                        } else if (key === opt_key) {
                            checkMatchConfig = this.props.product.setup.options[key].values.some((el) => el === value);
                            if (checkMatchConfig === false) this.setState({ matchQuoteConfig: true });
                            console.log(checkMatchConfig)
                        }
                    }
                }
            }

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });

            console.log(newDynamicOptions);
            console.log(newConfig);
        }

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;
                newConfig.cover_artwork = this.props.cart.cover_artwork;
                newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
                newConfig.inner_artwork = this.props.cart.inner_artwork;
                newConfig.inner_artwork_path = this.props.cart.inner_artwork_path;
                newConfig.jpeg_reference = this.props.cart.jpeg_reference;
                newConfig.jpeg_reference_path = this.props.cart.jpeg_reference_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }

        if ((this.state.adjustment_type !== previousState.adjustment_type) || (this.state.adjustment_value !== previousState.adjustment_value) || (this.state.configurations !== previousState.configurations) || (this.state.selectedCustomer !== previousState.selectedCustomer) || (previousState.adj_cover_print_price !== this.state.adj_cover_print_price) || (previousState.adj_inner_print_price !== this.state.adj_inner_print_price) || (previousState.adjustPrintingSwitch !== this.state.adjustPrintingSwitch)) {
            let final_price = this.state.price_and_duration;
            let quantity = parseInt(this.state.configurations.quantity);

            if ((this.state.adjustment_value.toString().length > 0) && !isNaN(this.state.adjustment_value)) {
                console.log(this.state.adjustment_amount)
                this.state.adjustment_amount = (this.state.adjustment_type === '%') ? (this.state.adjustment_value * final_price.original_price / 100) : parseFloat(this.state.adjustment_value);
                final_price.price = this.state.adjustment_amount + final_price.original_price;
                let unit_price = parseFloat((final_price.price) / quantity).toFixed(4);
                this.setState({ adjustment_amount: this.state.adjustment_amount, price_and_duration: final_price, adjustment_value: parseFloat(this.state.adjustment_value), unit_price });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['cover_artwork', 'inner_artwork', 'jpeg_reference',
                    'cover_artwork_download_url',
                    'inner_artwork_download_url',
                    'jpeg_reference_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (['lamination'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (['inner_lamination'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (['quantity'].includes(name)) {
                    defaultValue = 1;
                } else if (['pages'].includes(name)) {
                    defaultValue = 4;
                } else if (['print_inner'].includes(name)) {
                    defaultValue = productOptions[name].values;
                } else if (['print'].includes(name)) {
                    defaultValue = '4 Colour Outer & 4 Colour Inner';
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.options will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.options) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        const selectedBinding = productConfigurations.binding;
        const selectedCoverMaterial = productConfigurations.cover_material;
        const staticCoverLaminationOptions = productOptions.cover_lamination;
        const staticCoverMaterialOptions = productOptions.cover_material;
        const selectedInnerMaterial = productConfigurations.inner_material;
        const staticInnerLaminationOptions = productOptions.inner_lamination;
        const staticInnerMaterialOptions = productOptions.inner_material;

        // perfect bind -> only gloss art cards allowed
        let dynCoverMaterialOptions = [];
        staticCoverMaterialOptions.values.forEach((element) => {
            // if (selectedBinding.toUpperCase() === 'PERFECT BINDING') {
            //     if (element.toUpperCase().includes('GLOSS ART CARD')) {
            //         dynCoverMaterialOptions.push(element);
            //     }
            // } else {
            dynCoverMaterialOptions.push(element);
            // }
        });

        // perfect bind -> only gloss art cards allowed
        let dynInnerMaterialOptions = [];
        staticInnerMaterialOptions.values.forEach((element) => {
            // if (selectedBinding.toUpperCase() === 'PERFECT BINDING') {
            //     if (element.toUpperCase().includes('GLOSS ART CARD')) {
            //         dynInnerMaterialOptions.push(element);
            //     }
            // } else {
            dynInnerMaterialOptions.push(element);
            // }
        });


        // cover_material -> lamination
        let dynCoverLaminationOptions = [];
        staticCoverLaminationOptions.values.forEach((element) => {
            // if (element.cover_material.includes(selectedCoverMaterial)) {
            dynCoverLaminationOptions.push(element.type);
            // }
        })

        // inner_material -> lamination
        let dynInnerLaminationOptions = [];
        staticInnerLaminationOptions.values.forEach((element) => {
            // if (element.inner_material.includes(selectedInnerMaterial)) {
            dynInnerLaminationOptions.push(element.type);
            // }
        })

        // perfect bind -> inner pages (48 to 250 step of 2), saddle stitch -> inner pages (4 to 80 step of 4) and only front lamination allowed
        let dynInnerPagesOptions;
        if (selectedBinding.toUpperCase() === 'PERFECT BINDING') {
            dynInnerPagesOptions = { range: { min: 48, max: 250, step: 2 } }
            dynCoverLaminationOptions = dynCoverLaminationOptions.filter((lamination) => lamination.includes('None') || lamination.includes('Front'));
            dynInnerLaminationOptions = dynInnerLaminationOptions.filter((lamination) => lamination.includes('None') || lamination.includes('Front'));
        } else {
            dynInnerPagesOptions = { range: { min: 4, max: 80, step: 4 } }
        }

        newDynamicOptions.cover_material = dynCoverMaterialOptions;
        newDynamicOptions.cover_lamination = dynCoverLaminationOptions;
        newDynamicOptions.inner_material = dynInnerMaterialOptions;
        newDynamicOptions.inner_lamination = dynInnerLaminationOptions;
        newDynamicOptions.pages = dynInnerPagesOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        const options = this.props.product.setup.options;
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)
        }

        if (e.target.name === 'quantity') {
            if (e.target.value > 700) {
                this.setState({ invalid: true });
            } else {
                this.setState({ invalid: false });
            }
        }

        if (e.target.name === 'pages') {
            futureConfigs.pages = parseInt(e.target.value)
            if (e.target.value < 0) {
                this.setState({ invalid_pages: true });
            } else {
                this.setState({ invalid_pages: false });
            }
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    //Title
    handleInputText(e) {
        let value = '';

        if (e.target.name === 'title') {
            value = e.target.value.toUpperCase()
        } else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }

    handleVisibleOthers(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.othersSelected = e.target.checked;
        console.log(e.target.checked)

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherName(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.name = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherValue(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.value = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }


    handleInputOther(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.price = parseFloat(e.target.value);

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options, invalid, invalid_pages, invalid_binding, errorTextBinding } = this.state;
        switch (configName) {
            case 'size':
                // static options
                return (
                    <SizeFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                        onChange={this.handleInputChange.bind(this)}
                        showType={true}
                    />
                )

            case 'pages':
                // manual input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1 }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalid_pages}
                        errorText='Pages Cannot Less Than 0!'
                    />
                )

            case 'quantity':
                // manual input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1 }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalid}
                        errorText='Please use offset for better pricing!'
                    />
                )

            case 'print':
                // STATIC dropdown options
                return (
                    <BookletPrintColourDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'print_inner':
                // STATIC dropdown options
                return (
                    <BookletPrintColourDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={[options[configName].values]}
                        selectedItem={configurations[configName]}
                    />
                )
            case 'cover_lamination':
                // DYNAMIC dropdown options
                return (
                    <Fragment key='Finishing'>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Finishing</h5>
                        <GenericDropdownsFormGroup
                            key={configName}
                            name={configName}
                            label={'Cover Lamination'}
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={dynamic_options[configName]}
                            selectedItem={configurations[configName]}
                        />
                    </Fragment>
                )

            case 'cover_material':
                // DYNAMIC dropdown options with section separator
                return (
                    <Fragment key='Cover'>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Cover</h5>
                        <GenericDropdownsFormGroup
                            key={configName}
                            name={configName}
                            label='Cover Material'
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={dynamic_options[configName]}
                            selectedItem={configurations[configName]}
                        />
                    </Fragment>
                )

            case 'inner_material':
                // STATIC dropdown options with section separator
                return (
                    <Fragment key='Inner'>
                        <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Inner</h5>
                        <GenericDropdownsFormGroup
                            key={'inner_material'}
                            name={'inner_material'}
                            label={`Inner Material`}
                            onChange={this.handleInputChange.bind(this)}
                            itemsSource={options['inner_material'].values}
                            selectedItem={configurations['inner_material']}
                        />
                    </Fragment>
                )
            case 'inner_lamination':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'binding':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                        invalid={invalid_binding}
                        errorText={errorTextBinding}
                    />
                )
            case 'perforation':
            case 'diecut':
            case 'numbering':
            case 'inner_spotuv':
            case 'cover_spotuv':
            case 'folding':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            // case 'cover_artwork':
            //     return (
            //         <SelectFileFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             accept=".pdf, .rar, .zip"
            //             onChange={this.handleFileSelect.bind(this, 'digital_booklet_cover')}
            //         />
            //     )

            // case 'inner_artwork':
            //     return (
            //         <SelectFileFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             accept=".pdf, .rar, .zip"
            //             onChange={this.handleFileSelect.bind(this, 'digital_booklet_inner')}
            //         />
            //     )

            // case 'jpeg_reference':
            //     return (
            //         <SelectFileFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             accept=".jpg, .jpeg"
            //             onChange={this.handleFileSelect.bind(this, 'digital_booklet_jpeg_ref')}
            //         />
            //     )

            // case 'cover_artwork_download_url':
            //     return (
            //         <ManualInputFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             value={configurations[configName]}
            //             inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
            //             onChange={this.handleInputChange.bind(this)}
            //         />
            //     )

            // case 'inner_artwork_download_url':
            //     return (
            //         <ManualInputFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             value={configurations[configName]}
            //             inputAttributes={{ placeholder: 'INNER ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
            //             onChange={this.handleInputChange.bind(this)}
            //         />
            //     )

            // case 'jpeg_reference_download_url':
            //     return (
            //         <ManualInputFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             value={configurations[configName]}
            //             inputAttributes={{ placeholder: 'JPEG REFERENCE DOWNLOAD URL', style: { textTransform: 'none' } }}
            //             onChange={this.handleInputChange.bind(this)}
            //         />
            // )
            default:
                break;
        }
    }

    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        let missingConfigurations = [];

        // Add checks for required configuration here
        // if (this.state.configurations['cover_artwork_path'] == null && (
        //     this.state.configurations['cover_artwork_download_url'] == null ||
        //     this.state.configurations['cover_artwork_download_url'] == ""
        // )) {
        //     missingConfigurations.push("Cover Artwork");
        // }

        // if (this.state.configurations['inner_artwork_path'] == null && (
        //     this.state.configurations['inner_artwork_download_url'] == null ||
        //     this.state.configurations['inner_artwork_download_url'] == ""
        // )) {
        //     missingConfigurations.push("Inner Artwork");
        // }

        // if (this.state.configurations['jpeg_reference_path'] == null && (
        //     this.state.configurations['jpeg_reference_download_url'] == null ||
        //     this.state.configurations['jpeg_reference_download_url'] == ""
        // )) {
        //     missingConfigurations.push("JPEG for Reference");
        // }

        console.log(missingConfigurations);
        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {
        const { cover_material, inner_material, pages, size } = this.state.configurations;
        let quantity = parseInt(this.state.configurations.quantity);

        // if (!this.props.main.selectedcustomer) {
        //     this.props.showCustomerSelectionModal(true);
        //     return;
        // }

        if (!this.state.selectedCustomer) {
            this.customerRef.current.focus();
            this.setState({
                popoverText: 'Customer Name is required!',
                popoverTarget: 'name',
                popoverOpen: true
            });
            return;
        }

        if (this.checkRequiredConfigurations() === false) {
            this.setState({ popoverOpen: true });
            return;
        }

        let paper_density = parseInt(cover_material);
        let weight = Math.ceil(2 * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2pc for front and back), round up to nearest kg then add 2kg

        paper_density = parseInt(inner_material);
        weight += Math.ceil((pages / 2) * quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg (2 pages = 1 pc), round up to nearest kg then add 2kg

        let config = { ...this.state.configurations, weight }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            customer: this.state.selectedCustomer,
            title: this.state.title,
            product: this.product,
            configurations: { ...config, quantity },
            unit_price: parseFloat(this.state.unit_price),
            duration: this.state.price_and_duration.duration,
            adj_cover_print_price: parseFloat(this.state.adj_cover_print_price),
            adj_inner_print_price: parseFloat(this.state.adj_inner_print_price),
            adjustPrintingSwitch: this.state.adjustPrintingSwitch,
            original_price: this.state.price_and_duration.original_price,
            adjustment_type: this.state.adjustment_type,
            adjustment_value: parseFloat(this.state.adjustment_value),
            adjustment_amount: this.state.adjustment_amount,
            price: this.state.price_and_duration.price,
        });

        //Clear Quote since we are generating new cart item
        this.props.emptyQuote();

        // this.props.history.push('/cart');
        this.props.history.push('/checkout');
    }

    handleEnableAdjustmentPrinting() {
        this.setState({ adjustPrintingSwitch: !this.state.adjustPrintingSwitch })
    }

    handleAdjustmentPrintingPrice(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleAdjustmentInput(e) {
        this.setState({ adjustment_value: e.target.value });
    }

    handleAdjustmentTypeSelect(type, e) {
        this.setState({ adjustment_type: type });
    }

    handleAdjustmentUnitPrice(e) {
        let final_price = this.state.price_and_duration;
        let quantity = parseInt(this.state.configurations.quantity);

        //When Unit Price Changed, Final Price will also change as the unit price
        final_price.price = (e.target.value * quantity);
        console.log(final_price.price)
        //Find Out New Adjustment Value after unit price changed and update to the adjustment value field
        //Adjusment value will depend the adjusment type to calculate
        this.state.adjustment_amount = (final_price.price - final_price.original_price);
        this.state.adjustment_value = (this.state.adjustment_type === '%') ? parseFloat(this.state.adjustment_amount / final_price.original_price * 100).toFixed(2) : parseFloat(this.state.adjustment_amount).toFixed(2);
        console.log(this.state.adjustment_value)

        this.setState({ unit_price: parseFloat(e.target.value), price_and_duration: final_price, adjustment_amount: this.state.adjustment_amount, adjustment_value: this.state.adjustment_value })
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    handleSelectChange(e) {
        console.log(e)
        if ((!e.email) || (e.email === "")) {
            console.log(e.email)
            this.customerRef.current.focus();

            this.setState({
                popoverText: 'Missing Customer Info!',
                popoverTarget: 'name',
                popoverOpen: true,
                popoverPosition: 'bottom',
                customerName: e,
                customer_email_validation: e.email
            });

        } else {
            this.setState({ popoverOpen: false, customerName: e, customer_email_validation: null });
        }

        // Find Customer by index from selected customer selection value
        let selectedCustomer = "";
        selectedCustomer = this.props.checkout.list.find(function (element, index) {
            if ((index === e.index) && (e.index !== "")) {
                return element;
            }
        });
        console.log(selectedCustomer)
        this.setState({ selectedCustomer, customerName: e, customer_email_validation: e.email });
        this.props.selectCustomer(selectedCustomer);
    }

    toggleTooltip() {
        const { configurations, price_and_duration, invalid_pages, invalid_binding, customer_email_validation, customerName, title } = this.state;
        if (!title) {
            this.setState({
                popoverText: 'Please Enter A Title',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if ((price_and_duration.price <= 0) || (price_and_duration.price === NaN)) {

            this.setState({
                popoverText: 'Your Total Price is Zero or Less Than Zero!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (invalid_pages || invalid_binding) {

            this.setState({
                popoverText: 'Invalid Field!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (configurations.quantity <= 0) {

            this.setState({
                popoverText: 'Quantity is 0!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customerName) {
            this.setState({
                popoverText: 'Please Select A Customer.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customer_email_validation) {
            this.setState({
                popoverText: 'Please Update Customer Details To Proceed.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
    }

    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, invalid_pages, invalid_binding, adjustment_type, adjustment_amount, adj_cover_print_price, adj_inner_print_price, sys_cover_print_price, sys_inner_print_price, adjustPrintingSwitch, selectedCustomer, customeroption, title, customer_email_validation, customerName, matchQuoteConfig } = this.state;
        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Modal isOpen={matchQuoteConfig} className='scrolling'>
                    <ModalHeader style={{ backgroundColor: '#dd3636', color: 'white', marginRight: '-0.5px' }}>
                        Doesn't Match Default Config Selection
                    </ModalHeader>
                    <ModalBody>
                        <p>Selected Quote Doest Not Match Default Configurations Selection.</p>
                        <p>Please Create A New Quotation.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.history.goBack}>Back</Button>
                    </ModalFooter>
                </Modal>
                <Popover placement={this.state.popoverPosition} isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                    <PopoverBody>{this.state.popoverText}</PopoverBody>
                </Popover>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book">{this.product.toUpperCase()}</h1>
                        <Form className="product-info">
                            <FormGroup row>
                                <Label sm={3}> <b>Customer</b></Label>
                                <Col sm={9}>
                                    <Select id="name" name="name" ref={this.customerRef} placeholder={selectedCustomer ? selectedCustomer.name.toUpperCase() + " (" + selectedCustomer.email + ") -" + selectedCustomer.company : "NAME / COMPANY / EMAIL"} options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Title</b></Label>
                                <Col sm={9}>
                                    <Input name='title' id='title' type="text" placeholder="Title" value={title} style={{ textTransform: 'uppercase' }} onChange={this.handleInputText.bind(this)} />
                                </Col>
                            </FormGroup>
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Other</h5>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other1" id="other1" value={configurations.other1.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other1.othersSelected} />
                                    <Input name="other1" id="other1" type="text" placeholder="(Title)" value={configurations.other1.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="text" placeholder="(Value)" value={configurations.other1.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="number" placeholder="(Price)" value={configurations.other1.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other2" id="other2" value={configurations.other2.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other2.othersSelected} />
                                    <Input name="other2" id="other2" type="text" placeholder="(Title)" value={configurations.other2.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="text" placeholder="(Value)" value={configurations.other2.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="number" placeholder="(Price)" value={configurations.other2.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other3" id="other3" value={configurations.other3.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other3.othersSelected} />
                                    <Input name="other3" id="other3" type="text" placeholder="(Title)" value={configurations.other3.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="text" placeholder="(Value)" value={configurations.other3.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="number" placeholder="(Price)" value={configurations.other3.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Price</h5>
                            <FormGroup row>
                                <Label sm={3}>
                                    <b style={{ display: 'block' }}>Printing Rate<br /><br /></b>
                                </Label>
                                <Col sm={9}>
                                    <FormGroup check>
                                        <Input type="checkbox" name="print_rate_override" id="print_rate_override" onClick={this.handleEnableAdjustmentPrinting.bind(this)} defaultChecked={this.state.adjustPrintingSwitch} />
                                        <Label for="print_rate_override" check><b>Override system printing rate</b></Label>
                                    </FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="adj_cover_print_price">Cover (RM{this.state.sys_cover_print_price.toFixed(2)})</Label>
                                                <Input name="adj_cover_print_price" id="adj_cover_print_price" type="number" min="0" step="0.01" placeholder="Cover Printing Rate" value={adjustPrintingSwitch ? adj_cover_print_price : sys_cover_print_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentPrintingPrice.bind(this)} disabled={!adjustPrintingSwitch} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="adj_inner_print_price">Inner (RM{this.state.sys_inner_print_price.toFixed(2)})</Label>
                                                <Input name="adj_inner_print_price" id="adj_inner_print_price" type="number" min="0" step="0.01" placeholder="Inner Printing Rate" value={adjustPrintingSwitch ? adj_inner_print_price : sys_inner_print_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentPrintingPrice.bind(this)} disabled={!adjustPrintingSwitch} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <Fragment>
                                <TimeAndCostFormGroup
                                    price={price_and_duration.original_price}
                                    processDay={price_and_duration.duration} />
                                <FormGroup row>
                                    <Label sm={3}> <b>Unit Price</b></Label>
                                    <Col sm={9}>
                                        <Input name="unit_price" id="unit_price" type="number" placeholder="ADJUSTMENT" value={this.state.unit_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentUnitPrice.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>
                                        <b style={{ display: 'block' }}>Adjustment Value</b>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input name="adjustment_value" id="adjustment_value" type="number" placeholder="ADJUSTMENT" value={this.state.adjustment_value} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentInput.bind(this)} />
                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle caret>
                                                    {
                                                        (adjustment_type === '%') ? '% ' : 'RM'
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, '%')}>Percentage (%)</DropdownItem>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, 'RM')} >Riggit Malaysia (RM)</DropdownItem>
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label sm={3}> <b>Adjustment Amount</b></Label>
                                    <Label sm={9} ><b>RM {adjustment_amount.toFixed(2)}</b></Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Price</b></Label>
                                    <Label sm={9} ><b>RM {price_and_duration.price.toFixed(2)}</b></Label>
                                </FormGroup>
                                {(title) && (configurations.quantity > 0) && (!invalid_binding) && (!invalid_pages) && (price_and_duration.price > 0 && price_and_duration.price != NaN) && (customer_email_validation) && (customerName) ?
                                    <Button id="add-to-cart" name="add-to-cart" onClick={this.addToCart.bind(this)} >NEXT</Button>
                                    :
                                    <Button id="add-to-cart" name="add-to-cart" style={{ opacity: '0.5' }} onClick={this.toggleTooltip.bind(this)} onMouseOver={this.toggleTooltip.bind(this)} onMouseOut={this.closePopover.bind(this)}>NEXT</Button>
                                }
                            </Fragment>
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart, main, checkout, quote }) => {
    return { product, cart, main, checkout, quote };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart,
        showCustomerSelectionModal: showCustomerSelectionModal,
        selectCustomer: selectCustomer,
        getCustomers: getCustomers,
        emptyCart: emptyCart,
        emptyQuote: emptyQuote,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(DigitalBooklet));
