import { combineReducers } from 'redux';
import MainReducer from './MainReducer';
import ProductReducer from './ProductReducer';
import CartReducer from './CartReducer';
import CheckoutReducer from './CheckoutReducer';
import QuoteReducer from './QuoteReducer';

export default combineReducers({
    main: MainReducer,
    product: ProductReducer,
    cart: CartReducer,
    checkout: CheckoutReducer,
    quote: QuoteReducer,
});