import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './UtechFooter.css'

import footerIcon from './footer-icon.png';

export default class UtechFooter extends React.Component {
  divStyle = {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '45px',
    color: 'white',
  };

  render() {
    return (
      <footer>
        <div className="footer-links pt-4 pb-1">
          <Container>
            <Row>
              <Col xs="12" md="6" lg="auto" className="footer-links-col">
                <h3>Product</h3>
                <ul>
                  <li><Link to="/products/1 Colour Digital Booklet">1 Colour Digital Booklet</Link></li>
                  <li><Link to="/products/4 Colour Digital Booklet">4 Colour Digital Booklet</Link></li>
                  <li><Link to="/products/4 Colour And 1 Colour Digital Booklet">4 Colour And 1 Colour Digital Booklet</Link></li>
                  <li><Link to="/products/Digital Booklet Landscape">Digital Booklet Landscape</Link></li>
                  {/* <li><Link to="/products/Offset Booklet">Offset Booklet</Link></li> */}
                  <li><Link to="/products/Digital Business Card">Digital Business Card</Link></li>
                  <li><Link to="/products/Offset Business Card">Offset Business Card</Link></li>
                  {/* <li><Link to="/products/Brochure">Brochure</Link></li> */}
                  <li><Link to="/products/Digital Brochure">Digital Brochure</Link></li>
                  {/* <li><Link to="/products/Flyer">Flyer</Link></li> */}
                  {/* <li><Link to="/products/Racing Horse Calendar With Header">Racing Horse Calendar With Header</Link></li>
                  <li><Link to="/products/Racing Horse Calendar Without Header">Racing Horse Calendar Without Header</Link></li> */}
                  <li><Link to="/products/Sticker Label">Sticker Label</Link></li>
                  <li><Link to="/products/StickerSheet Label">Sticker Label (Sheet)</Link></li>
                  <li><Link to="/products/StickerRoll Label">Sticker Label (Roll)</Link></li>
                </ul>
              </Col>
              <Col xs="12" md="6" lg="auto" className="footer-links-col d-none d-md-block">
                <h3>Product Guide</h3>
                <ul>
                  <li><Link to="/art-work-specification">Art Work Specification</Link></li>
                  <li><Link to="/product-size-template">Product Size Template</Link></li>
                  <li><Link to="/product-specifications">Product Specification</Link></li>
                </ul>
                <h3><Link to="/quotations"  className="footer-quote">Quotations</Link></h3>
              </Col>
              <Col className="footer-links-col"></Col>
            </Row>
          </Container>
        </div>
        <div className="footer-div">
          <Container>
            <Row>
              <Col xs="12" md="6">
                <div className="d-inline-block float-none float-md-left">PERCETAKAN U-TECH SDN BHD © 2020</div>
              </Col>
              <Col xs="12" md="6">
                <img className="footer-icon d-none d-lg-block" src={footerIcon} title="footer-icon" alt="Footer Icon" />
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}
