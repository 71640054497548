import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { checkArray } from '../Utility'
import './BookletPrintColourDropdownsFormGroup.css'
/**
 * Render dropdowns for booklet print color selection in a form group.
 * which the value of the item is displayed as it is without further binding.
 * 
 * Upon user selection, a companion picture may be shown.
 * 
 * @param {String} name - unique id for this element
 * @param {String} label - Display for the drop down
 * @param {*} selectedItem - current selected value
 * @param {Array} itemsSource - list of items
 * @param {Function} onChange - callback to handle changes
 */
export default class BookletPrintColourDropdownsFormGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(Array.isArray(this.props.itemsSource) === false) {
            console.log(`${this.constructor.name} not rendering due to unfullfilled prerequisites`)
            return null
        }

        let imgId = null
        if (this.props.selectedItem === '4 Colour Outer Only') {
            imgId = 'cover_side_1_img'
        } else if (this.props.selectedItem === '4 Colour Outer & 4 Colour Inner') {
            imgId = 'cover_side_2_img'
        }
        return (
            <FormGroup row>
                <Label htmlFor={this.props.name} sm={3}>{this.props.label}</Label>
                <Col sm={9}>
                    <Input type="select" name={this.props.name} id={this.props.name} value={this.props.selectedItem} onChange={this.props.onChange}>
                        {
                            this.props.itemsSource.map(item => 
                                <option key={item} value={item}>{item}</option>
                            )
                        }
                    </Input>
                    {
                        (imgId != null) && (
                            <div id={imgId} />
                        )
                    }
                </Col>
            </FormGroup>
        )
    }
}