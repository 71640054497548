import { FETCH_SHIPPING_INFO, RESET_SHIPPING, SAVE_ORDER, VERIFY_VOUCHER, GET_CUSTOMERS, GET_PAYMENT_TERMS, GET_DELIVERY_SCHEDULE } from '../actions/types';

const INIT_STATE = {
    billing_country: 'MALAYSIA',
    shipping_country: 'MALAYSIA',
    shipping_rates: [],
    voucher_discount: null,
    order: null,
    list: [],
    payment_terms: {},
    delivery_schedule: {},
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case RESET_SHIPPING:
            return INIT_STATE;

        case SAVE_ORDER:
            return { ...state, order: action.payload }

        case FETCH_SHIPPING_INFO:
            return { ...state, shipping_rates: action.payload }

        case VERIFY_VOUCHER:
            console.log(action.payload);
            return { ...state, voucher_discount: action.payload }

        case GET_CUSTOMERS:
            console.log(action.payload);

            return {
                ...state,
                list: action.payload,
                customerModalOpen: false
            }
        case GET_PAYMENT_TERMS:
            return { ...state, payment_terms: action.payload }

        case GET_DELIVERY_SCHEDULE:
            return { ...state, delivery_schedule: action.payload }

        default:
            return state;
    }
}