import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaginationComponent from "react-reactstrap-pagination";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faSync, faSearch, faCaretDown, faCaretUp, faInfoCircle, faCheckCircle, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FormGroup, Form, Button, Input, Table, Col, Container } from 'reactstrap';
import { setQuotesFilter, getQuotes, showLoading, sortQuotation } from '../../../actions';

class Quotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            column: null,
            data: [],
            length: 0,
            page: 0,
            rowsPerPage: 15,
            direction: 'descending',
            formType: null,
            showError: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        // let cat = (this.props.match.params.cat) ? this.props.match.params.cat : 'All';

        // this.props.setQuotesFilter(cat);
        // if (cat === 'All') {
        //     this.props.getQuotes({});
        // } else {
        //     this.props.getQuotes({ status: cat });
        // }
        if (!this.props.user) {
            this.props.history.push('/');
        }
        this.props.showLoading(true);
        this.props.getQuotes({});

    }

    componentDidMount() {
        // this.props.setActivePage('quotations');
    }

    componentWillReceiveProps(nextProps) {
        console.log('Quote componentWillReceiveProps');
        console.log(this.props)
        console.log(nextProps)
        if (this.props.quote.quote_list !== nextProps.quote.quote_list) {
            this.setState({ data: _.chunk(nextProps.quote.quote_list, this.state.rowsPerPage), length: nextProps.quote.quote_list.length, column: nextProps.quote.sortColumn, direction: nextProps.quote.sortDirection, page: 0 });
            this.props.showLoading(false);
        }
        else if ((this.props.quote.sortColumn !== nextProps.quote.sortColumn) || (this.props.quote.sortDirection !== nextProps.quote.sortDirection)) {
            this.setState({ data: _.chunk(nextProps.quote.list, this.state.rowsPerPage), column: nextProps.quote.sortColumn, direction: nextProps.quote.sortDirection, page: 0 });
        }

        if (this.props.match.params.cat !== nextProps.match.params.cat) {
            console.log(this.props.location.search);
            this.props.showLoading(true);
            let cat = (nextProps.match.params.cat) ? nextProps.match.params.cat : 'All';

            this.props.setQuotesFilter(cat);
            if (cat === 'All') {
                this.props.getQuotes({});
            } else {
                this.props.getQuotes({ status: cat });
            }
        }
    }

    componentWillUnmount(){
        this.props.showLoading(false);
    }

    handleQuoteClick(quote_id, e) {
        this.props.history.push('/quotation_details/' + quote_id);
    }

    handleInputChange(e, data) {
        console.log(e);
        console.log(data);
        this.setState({
            [data.id]: data.value
        });
    }

    handleSearchChange = (e) => {
        var sValue = e.target.value;
        this.setState({ searchValue: sValue });
    }

    handleSearchSubmit = (e) => {
        const re = new RegExp(_.escapeRegExp(e.target.value), 'i')
        const isMatch = result => (re.test(result.quote_details.customer.name) || re.test(result._id) || re.test(result.quote_details.customer.company));

        let list = _.filter(this.props.quote.quote_list, isMatch);
        this.setState({ data: _.chunk(list, this.state.rowsPerPage), length: list.length });
    }

    handleRefresh = () => {
        this.props.showLoading('Retrieving quotes . . .');
        this.props.getQuotes({});
        console.log("Refresh List");
    }

    handleSort = (clickedColumn) => () => {
        this.props.sortQuotation(clickedColumn);
    }

    render() {
        const { column, data, direction, page, rowsPerPage, length, searchValue } = this.state;

        return (
            <Fragment>
                <Container className="quotation-container">
                    <Form>
                        <FormGroup row>
                            <Col xs="5">
                                <Input placeholder='Search...' value={searchValue} onKeyUp={this.handleSearchSubmit} onChange={this.handleSearchChange} />
                            </Col>
                            <Col xs="1" style={{ paddingLeft: "0px" }}>
                                <FontAwesomeIcon icon={faSearch} style={{ fontSize: "22px", marginTop: "6px", color: "gray" }} /></Col>
                            <Col style={{ textAlign: "right" }} xs="6">
                                <Button onClick={this.handleRefresh} className="button.refresh-button"><FontAwesomeIcon icon={faSync} /> </Button>
                            </Col>

                        </FormGroup>
                    </Form>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th >
                                    <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                                </th >
                                <th onClick={this.handleSort('quote_date')}>
                                    Date  <FontAwesomeIcon icon={direction === 'ascending' ? faCaretUp : faCaretDown} />
                                </th>
                                <th onClick={this.handleSort('_id')}>
                                    Quote<FontAwesomeIcon icon={direction === 'ascending' ? faCaretUp : faCaretDown} />
                                </th>
                                <th>
                                    Quote To
                            </th>
                                <th>
                                    Total
                            </th>
                                <th onClick={this.handleSort('name')}>
                                    Submitter <FontAwesomeIcon icon={direction === 'ascending' ? faCaretUp : faCaretDown} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data[page] &&
                                (data.length > 0) ?
                                data[page].map((quoteBundle) => {
                                    let quote = quoteBundle.quote_details;
                                    return (
                                        <tr key={quoteBundle._id} onClick={this.handleQuoteClick.bind(this, quoteBundle._id)}>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {
                                                    (quoteBundle.status === 'Approved') ? <FontAwesomeIcon icon={faCheckCircle} color='green' size='lg' />
                                                        : (quoteBundle.status === 'Submitted') ? <FontAwesomeIcon icon={faPlusCircle} color='teal' size='lg' />
                                                            : <FontAwesomeIcon icon={faMinusCircle} color='red' size='lg' />
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {moment(quoteBundle.quote_date).format('DD/MM/YYYY HH:mm')}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>{quoteBundle._id}</td>
                                            <td>
                                                <span>{quote.customer.name}</span><br />
                                                <span>{quote.customer.company}</span><br />
                                                {quote.items && quote.items.map((item, index) => {
                                                    return (
                                                        <span key={index}>{item.title}</span>
                                                    )
                                                })}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                RM {quote.total_amount.toFixed(2)}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>{quoteBundle.user.name}</td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                        No Record Found
                             </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <Col sm={{ size: 1, offset: 8 }} style={{ paddingLeft: "7px" }}>
                        <PaginationComponent
                            totalItems={length}
                            pageSize={rowsPerPage}
                            onSelect={(e) => { console.log(e); this.setState({ page: e - 1 }) }}
                            firstPageText="<<"
                            previousPageText="<"
                            nextPageText=">"
                            lastPageText=">>"
                        />
                    </Col>
                </Container>
            </Fragment>
        )
    }
}


const mapStateToProps = ({ main, quote }) => {
    return {  user: main.user,quote }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoading: showLoading,
        setQuotesFilter: setQuotesFilter,
        getQuotes: getQuotes,
        sortQuotation: sortQuotation
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Quotations);
