import { GET_QUOTES, UPDATE_QUOTE, SET_QUOTES_FILTER, SORT_QUOTATION, SELECTED_QUOTE, EMPTY_QUOTE } from '../actions/types';
import _ from 'lodash';
const INITIAL_STATE = {
    quote_list: [],
    upload_status: 200,
    filter: 'All',
    status: 'ok',
    sortColumn: 'quote_date',
    sortDirection: 'descending',
    selectedQuote: null,
    setup: null,
    loading: false,
}

export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case GET_QUOTES:
            console.log(action)
            return {
                ...state,
                quote_list: action.payload,
                loading: false,
            }

        case SET_QUOTES_FILTER:
            console.log(action.payload)
            return {
                ...state,
                filter: action.payload
            }

        case UPDATE_QUOTE:
            let quote_index = state.quote_list.findIndex((n) => n._id === action.payload._id);
            return {
                ...state,
                quote_list: state.quote_list.map((quote, index) => {
                    if (index === quote_index) {
                        return Object.assign({}, quote, action.payload)
                    }
                    return quote
                })
            }

        case SORT_QUOTATION:
            let sorted = null;
            let sort_column = action.payload;
            let direction = 'ascending';
            if (state.sortColumn !== sort_column) {
                console.log('Sorting by ' + sort_column);
                console.log('state' + state);
                sorted = _.sortBy(state.quote_list, [sort_column]);
            } else {
                sorted = state.quote_list.reverse();
                console.log(sorted);
                direction = (state.sortDirection === 'ascending') ? 'descending' : 'ascending';
                console.log('Sorting in ' + direction);
            }

            return {
                ...state,
                list: sorted,
                sortColumn: sort_column,
                sortDirection: direction
            }

        case SELECTED_QUOTE:
            console.log(action.payload)
            let selectedQuoteProduct = null;
            if (action.payload.quote && action.payload.quote.quote_details.items) {
                selectedQuoteProduct = action.payload.quote.quote_details.items.find((n) => n.product);
            } else {
                selectedQuoteProduct = null;
            }
            return {
                ...state,
                selectedQuote: action.payload.quote,
                selectedQuoteProduct: selectedQuoteProduct
            }
            
        case EMPTY_QUOTE:
            return {
                ...state,
                selectedQuote: null,
                selectedQuoteProduct: null,
            }

        default:
            return state;
    }
};
