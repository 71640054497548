import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { urlToProperty } from "query-string-params";
import { Container, Row, Col, Card, CardDeck, CardHeader, CardBody } from 'reactstrap';
import { resetShipping, emptyCart, fetchUserRecords } from '../../../actions';


class ThankYou extends Component {
    state = {
        showModal: false
    }

    constructor(props) {
        super(props);
        this.search_query = urlToProperty(props.location.search);
    }

    componentWillMount() {
        this.props.emptyCart();
        this.props.resetShipping();
        // this.props.fetchUserRecords(this.props.main.customer._id);
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    showInvoice() {
        this.setState({ showModal: true });
    }

    render() {
        const { records } = this.props.main;
        console.log(records);


        return (
            <Container fluid={false} className="my-3">

                <h2 className="text-center">Thank You!</h2>
                <CardDeck>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <p className="text-center">Your quote has been submitted successfully.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                {/* { latestOrder &&
                                    <Button onClick={this.showInvoice.bind(this)}>SHOW INVOICE</Button>
                                } */}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </CardDeck>
            </Container>
        )
    }
}

const mapStateToProps = ({ main }) => {
    return { main };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetShipping: resetShipping,
        emptyCart: emptyCart,
        fetchUserRecords: fetchUserRecords
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ThankYou);