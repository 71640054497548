import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { withAlert } from 'react-alert';
import { DotLoader } from 'react-spinners';
import { showLoginModal } from '../../../actions';
import Login from './Login';
import './UtechHeader.css';

// Image assets
import logo from './logo.png';


class UtechHeader extends Component {

    state = {
        theme: null,
        dropdownOpen: false
    }

    componentDidUpdate(prevProps) {

        if ((this.props.user !== prevProps.user ) && (this.props.user)) {
            console.log(this.props.user)
            console.log(prevProps.user)
            if (this.props.user.state !== 'ACTIVE') {
                this.props.firebaseApp.auth().signOut()
                .then(() => {
                    this.props.logoutStaff();
                    this.props.history.push('/');
                });
            }
            else if (this.props.user.role !== 'ADMIN' && this.props.user.role  !== 'SALES') {
                this.props.firebaseApp.auth().signOut()
                .then(() => {
                    this.props.history.push('/');
                    const alert = this.props.alert.show('Sorry, You are not Salesman or Admin. ', {
                        timeout: 3000,
                        type: 'error'
                    });
                });
            }
        }

        if ((this.props.loginError !== prevProps.loginError) && (this.props.loginError)) {
            if (this.props.loginError === 'User not found!') {
                const alert = this.props.alert.show('Please Ask Admin to Create Account For You. ', {
                    timeout: 4000,
                    type: 'error'
                });
            } else {
                const alert = this.props.alert.show(this.props.loginError, {
                    timeout: 3000,
                    type: 'error'
                });
            }
        }
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    toggleLoginModal = (evt) => {
        evt.preventDefault();
        this.props.history.push('/');
    }

    toggleAccountOpen = () => {
        this.setState({ accountOpen: !this.state.accountOpen });
    }

    handleCart = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        this.props.history.push('/cart');
    }

    resetTheme = (evt) => {
        evt.preventDefault();
        this.setState({ theme: null });
    }

    chooseTheme = (theme, evt) => {
        evt.preventDefault();
        this.setState({ theme });
    }

    render() {
        const { loading } = this.props;

        return (
            <Container className="utech-header text-center">
                <Modal className="loadingModal" isOpen={loading}>
                    <ModalBody className="loadingBody">
                        <Row>
                            <Col></Col>
                            <Col xs="auto"><DotLoader color={'#36D7B7'} size={60} loading={true} /></Col>
                            <Col></Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Row>
                    <Col className="mt-2 mt-md-3 mt-xl-0 mb-3">
                        <a className="float-none float-lg-left" onClick={() => this.props.history.push('/')}><img src={logo} title="PERCETAKAN U-TECH SDN BHD" alt="PERCETAKAN U-TECH SDN BHD" style={{ height: '100px', width: 'auto' }} /></a>
                    </Col>
                    <Col style={{margin: 'auto'}}>
                        <h1 style={{color: '#555555', fontWeight: 'bold'}}>SALESFORCE</h1>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ main, product, cart, checkout }) => {
    const { user, loginModal, loginSuccessHandle, loading, firebaseApp, loginError,  } = main;
    const { prod_cat } = product;
    const { order } = checkout;
    return { prod_cat, user, cart, loginModal, loginSuccessHandle, loading, firebaseApp, order, loginError };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(UtechHeader));
