import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { Button, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { showLoginModal, showLoading, showCustomerSelectionModal, getCustomers, selectCustomer, emptyCart } from '../../../actions';
import Select from 'react-select';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faUserEdit, faCheck } from '@fortawesome/free-solid-svg-icons';

const request = require('superagent');

class CustomerSelectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerAvailable: false,
      confirmation: false,
      modalOpen: true,
      loading: false,
      customeroption: [],
    }
  }


  componentWillMount() {
    this.props.getCustomers();
  }


  componentDidUpdate(previousProps, previousState) {
    console.log(this.props);
    // console.log(previousProps.customer);
    if (this.props.checkout.list && (this.props.checkout.list !== previousProps.checkout.list)) {
      let customeroption = this.props.checkout.list.map(obj => {
        let newObj = {}; newObj.label = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
        newObj.value = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
        newObj.name = obj.name; newObj.email = obj.email; newObj.company = obj.company;
        return newObj;
      });

      console.log(customeroption)
      this.setState({ data: _.chunk(this.props.checkout.list), customeroption: _.sortBy(customeroption, ['label']) });

    }
  }



  resetInputStyle(e) {
    e.target.classList.remove('is-invalid');
    this.setState({
      popoverOpen: false
    });
  }

  closePopover() {
    this.setState({
      popoverOpen: false
    });
  }

  closeCustomerSelectionModal(e) {
    e.preventDefault();
    this.props.showCustomerSelectionModal(false);
  }

  toggle() {
    this.props.showCustomerSelectionModal(false);
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  handleSelectChange(e) {

    console.log(e.value)

    //Input Value find most neareselt email / name
    var selectedCustomer = "";
    selectedCustomer = this.props.checkout.list.find(function (element) {
      if ((element.name === e.name) && (e.name !== "")) {
        return (element.name === e.name);
      } else if ((element.email === e.email) && (e.email !== "")) {
        return (element.email === e.email);
      } else if ((element.company === e.company) && (e.company !== "")) {
        return (element.company === e.company);
      }
    });
    console.log(selectedCustomer)
    this.setState({ selectedCustomer });

  }

  handleAddSelectedCustomer(e) {
    e.preventDefault();
    console.log(this.state.customeroption);

    if (this.state.selectedCustomer === "" || this.state.selectedCustomer === undefined) {
      const alert = this.props.alert.show('Customer Cannot Leave Empty!', {
        timeout: 3000,
        type: 'error',
      });
    }
    else {
      if (this.props.selectedcustomer) {
        if (this.props.selectedcustomer._id !== this.state.selectedCustomer._id) {
          console.log(this.props.cart.items);
          if (this.props.cart.items.length > 0) {
            this.setState({ confirmation: !this.state.confirmation });
          } else {
            this.props.selectCustomer(this.state.selectedCustomer);
            this.props.showCustomerSelectionModal(false);
            this.props.history.push('/');
          }
        } else {
          this.props.showCustomerSelectionModal(false);
          this.props.history.push('/');
        }
      } else {
        this.props.selectCustomer(this.state.selectedCustomer);
        this.props.showCustomerSelectionModal(false);
        this.props.history.push('/');
      }
    }
  }

  handleConfirm(confirm) {
    console.log(confirm)
    if (confirm) {
      this.props.selectCustomer(this.state.selectedCustomer);
      this.props.showCustomerSelectionModal(false);
      this.props.emptyCart();
    }
    this.setState({ confirmation: !this.state.confirmation });
  }



  render() {
    console.log(this.props);
    const { customeroption, modalOpen } = this.state;

    return (
      <Modal isOpen={modalOpen}>
        <ModalHeader toggle={this.toggle.bind(this)}><FontAwesomeIcon style={{ marginRight: '5px' }} icon={faUserEdit} /> Customer Selection</ModalHeader>
        <ModalBody>
          <Modal isOpen={this.state.confirmation}>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>Changing customer will cause shopping cart to be emptied. Do you want to proceed?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleConfirm.bind(this, false)}>Cancel</Button>
              <Button onClick={this.handleConfirm.bind(this, true)}>OK</Button>
            </ModalFooter>
          </Modal>
          <FormGroup row>
            <Col>
              <Select id="name" name="name" placeholder="NAME / COMPANY / EMAIL" options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Button onClick={this.handleAddSelectedCustomer.bind(this)}> Confirm <FontAwesomeIcon icon={faCheck} /></Button>
            </Col>
          </FormGroup>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ main, cart, checkout }) => {
  const { customer, loading, selectedcustomer } = main;
  return { customer, loading, cart, checkout, selectedcustomer };
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showLoginModal: showLoginModal,
    showLoading: showLoading,
    showCustomerSelectionModal: showCustomerSelectionModal,
    getCustomers: getCustomers,
    selectCustomer: selectCustomer,
    emptyCart: emptyCart
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(withAlert(CustomerSelectionModal)));
