import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import {
    Button,
    Row, Col,
    Popover, PopoverBody,
    Form, FormGroup, Label, Input,
    Card, Container, CardBody
} from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { showLoginModal, showLoading, loginStaff, logoutStaff } from '../../../actions';
import { serverURI } from '../../../config';
import * as firebase from 'firebase';
import './HomePage.css';
import CustomerSelectionModal from '../../Layouts/UtechNavbar/CustomerSelectionModal';


const request = require('superagent');

class Homepage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            popoverTarget: 'login_email',
            popoverText: '',
            loading: false,
        }
    }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    authErrorToMsg(err_code) {
        switch (err_code) {
            case 'auth/invalid-email':
                return 'Invalid email address';

            case 'auth/email-already-in-use':
                return 'Account already existed';

            case 'auth/operation-not-allowed':
                return 'Operation not allowed';

            case 'auth/user-disabled':
                return 'Acccount disabled, please contact administrator';

            case 'auth/user-not-found':
                return 'Account not found, please register an account';

            case 'auth/wrong-password':
                return 'Wrong password';

            case 'auth/weak-password':
                return 'Weak password';

            case 'auth/account-exists-with-different-credential':
                return 'Same email address has been used';

            default:
                return 'No error code';
        }
    }

    handleLogin(login, e) {
        e.preventDefault();
        require('formdata-polyfill');
        let formData = new FormData(this.formRef);
        if (!this.formRef.elements['login_email'].value.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            this.formRef.elements['login_email'].classList.add('is-invalid');
            this.formRef.elements['login_email'].focus();
            this.setState({
                popoverText: 'Invalid email format!',
                popoverTarget: 'login_email',
                popoverOpen: true
            });
        } else if (!this.formRef.elements['login_password'].value.match(/.*^(?=.{6,})(?=.*[a-zA-Z])(?!.*\s).*$/)) {
            this.formRef.elements['login_password'].classList.add('is-invalid');
            this.formRef.elements['login_password'].focus();
            this.setState({
                popoverText: 'Password MUST be at least 6 alphanumeric characters!',
                popoverTarget: 'login_password',
                popoverOpen: true
            });
        } else {
            let userData = Array.from(formData.values());

            this.props.showLoading(true);
            if (login) {
                this.props.firebaseApp.auth().signInWithEmailAndPassword(userData[0], userData[1])
                    .then((userCredentials) => {
                        console.log(userCredentials.user);
                        if (userCredentials.user) {
                            this.props.loginStaff(userCredentials.user);
                        }
                        else {
                            console.log('User has logged out!');
                            this.props.logoutStaff();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showLoading(false);
                        const alert = this.props.alert.show('Login failed! ' + this.authErrorToMsg(err.code), {
                            timeout: 3000,
                            type: 'error',
                        });
                    });
            } else {
                let actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be whitelisted in the Firebase Console.
                    url: window.location.href,
                    // This must be true.
                    handleCodeInApp: true,
                    // iOS: {
                    //   bundleId: 'com.example.ios'
                    // },
                    // android: {
                    //   packageName: 'com.example.android',
                    //   installApp: true,
                    //   minimumVersion: '12'
                    // },
                    // dynamicLinkDomain: 'example.page.link'
                };

                this.props.firebaseApp.auth().sendSignInLinkToEmail(userData[0], actionCodeSettings)
                    .then(() => {
                        // The link was successfully sent. Inform the user.
                        // Save the email locally so you don't need to ask the user for it again
                        // if they open the link on the same device.
                        // window.localStorage.setItem('emailForSignIn', userData[0]);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showLoading(false);
                        const alert = this.props.alert.show('Register failed! ' + this.authErrorToMsg(err.code), {
                            timeout: 3000,
                            type: 'error',
                        });
                    });
            }
        }
    }

    toggleCustomerSelectionModal = (evt) => {
        evt.preventDefault();

        this.props.showCustomerSelectionModal(!this.props.customerselectionModal);
        console.log(this.props)
    }

    handleResetPassword(e) {
        e.preventDefault();
        this.props.history.push('/reset-password');
        // window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/reset-password';
    }

    render() {
        const { order, isOpen, user, customerselectionModal, selectedcustomer } = this.props;
        console.log(this.props)

        return (
            <div>
                {(user == null) &&
                    <Container>
                        <h2>Login Page</h2>
                        <Popover placement='top' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                            <PopoverBody>{this.state.popoverText}</PopoverBody>
                        </Popover>
                        <Form innerRef={(ref) => this.formRef = ref}>
                            <Card>
                                <CardBody>
                                    <FormGroup row>
                                        <Col xs="2"></Col>
                                        <Col xs="1" className="login-logo"><FontAwesomeIcon icon={faEnvelope} ></FontAwesomeIcon></Col>
                                        <Col sm="12" md={{ size: 6 }}>
                                            <Label for="login_email" hidden>Email</Label>
                                            <Input type="email" id="login_email" name="login_email" placeholder="Email" defaultValue={(order) ? order.customer.email : null} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs="2"></Col>
                                        <Col xs="1" className="login-logo"><FontAwesomeIcon icon={faKey} ></FontAwesomeIcon></Col>
                                        <Col sm="12" md={{ size: 6 }}>
                                            <Label for="login_password" hidden>Password</Label>
                                            <Input type="password" id="login_password" name="login_password" placeholder="Password" onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <Row>
                                        <Col></Col>
                                        <Col xs="auto">
                                            <button className="button login" onClick={this.handleLogin.bind(this, true)}>Login</button>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row>
                                        <Col></Col>
                                        <Col xs="auto" id="forgotPassword">
                                            <a href='#' onClick={this.handleResetPassword.bind(this)}>Forgot Password?</a>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </Container>
                }

                {(user !== null) &&
                    <Container>
                        <br></br>
                        <Row>
                            <h3 className="home-product-title"><b>PRODUCTS</b></h3>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: 'left' }}>
                                <div className="box-category">
                                    <ul className="box-category">
                                        <li>
                                            <ul className="box-category">
                                                <li>
                                                    <Link to="/products/1 Colour Digital Booklet"><h4><b>1 COLOUR DIGITAL BOOKLET</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/4 Colour Digital Booklet"><h4><b>4 COLOUR DIGITAL BOOKLET</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/4 Colour And 1 Colour Digital Booklet"><h4><b>4 COLOUR AND 1 COLOUR DIGITAL BOOKLET</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/Digital Booklet Landscape"><h4><b>DIGITAL BOOKLET LANDSCAPE</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/Digital Business Card"><h4><b>DIGITAL BUSINESS CARD</b></h4></Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/products/Flyer"><h4><b>FLYER</b></h4></Link>
                                                </li> */}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col>
                                <div className="box-category">
                                    <ul className="box-category">
                                        <li>
                                            <ul className="box-category">
                                                <li>
                                                    <Link to="/products/Offset Business Card"><h4><b>OFFSET BUSINESS CARD</b></h4></Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/products/Brochure"><h4><b>BROCHURE</b></h4></Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/products/Digital Brochure"><h4><b>DIGITAL BROCHURE</b></h4></Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/products/Racing Horse Calendar With Header"><h4><b>RACING HORSE CALENDAR WITH HEADER</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/Racing Horse Calendar Without Header"><h4><b>RACING HORSE CALENDAR WITHOUT HEADER</b></h4></Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/products/Sticker Label"><h4><b>STICKER LABEL</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/StickerSheet Label"><h4><b>STICKER LABEL (SHEET)</b></h4></Link>
                                                </li>
                                                <li>
                                                    <Link to="/products/StickerRoll Label"><h4><b>STICKER LABEL (ROLL)</b></h4></Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
        )
    }
}

const mapStateToProps = ({ main, cart, checkout }) => {
    const { customer, loading, firebaseApp, user, selectedcustomer, customerselectionModal } = main;
    const { order } = checkout;
    return { customer, cart, loading, firebaseApp, order, user, selectedcustomer, customerselectionModal };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal,
        showLoading: showLoading,
        loginStaff: loginStaff,
        logoutStaff: logoutStaff
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(Homepage));
