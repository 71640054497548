import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert, Card, CardBody } from 'reactstrap'
import logo from './logo.png';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            sent: false,
            error: false,
            errMsg: ''
        }
        this.submitPWReset = this.submitPWReset.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }

    submitPWReset(e) {
        e.preventDefault();
        this.setState({ sent: false, error: false });
        this.props.firebaseApp.auth().sendPasswordResetEmail(this.state.email)
            .then(() => {
                console.log('Password reset email sent.');
                this.setState({ sent: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ error: true, errMsg: error.message });
            });
    }

    render() {
        const { email, sent, error, errMsg } = this.state;

        return (
            <Container style={{ maxWidth: 600 }}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col >
                                <image src={logo} verticalAlign='left' size='small' />
                                <h2 style={{color:'black' , textAlign:'left'}}>
                                    Password Reset
                                </h2>
                                <br></br>
                                <Form onSubmit={this.submitPWReset}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input  placeholder='E-mail address' id='email' name='email' value={email} onChange={this.handleInputChange} disabled={sent} />
                                    </FormGroup>
                                    <Button color='primary' type='submit' disabled={sent}>
                                        Submit
                            </Button>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ maxWidth: 450, padding: 20 }}>
                                <Alert color="info" isOpen={!sent && !error}>
                                    Reset password link will be sent to your email
                        </Alert>
                                <Alert color="success" isOpen={sent}>
                                    Reset password link has been sent to {email}
                                </Alert>
                                <Alert color="danger" isOpen={error}>
                                    {errMsg}
                                </Alert>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = ({ main }) => {
    return { firebaseApp: main.firebaseApp };
}

export default connect(mapStateToProps)(PasswordReset);
