import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import {
    Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
    InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody
} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart, showCustomerSelectionModal, getCustomers, selectCustomer, emptyQuote, emptyCart } from '../../../actions';
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import OptionalGenericDropdownsFormGroup from './Components/OptionalGenericDropdownsFormGroup';
import OrientationFormGroup from './Components/OrientationFormGroup';
import FoldingCreasingFormGroup from './Components/FoldingCreasingFormGroup';
import HotStampingFormGroup from './Components/HotStampingFormGroup';
import RoundCornerFormGroup from './Components/RoundCornerFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import SelectFileFormGroup from './Components/SelectFileFormGroup';
import JsonDisplay from './Components/JsonDisplay'
import { isObjValueEqual, matchCurrentConfigToVariant, readFileToBase64StringPromise, productSpecUrl, productPriceUrl, } from "./Utility";
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import ManualInputFormGroup from './Components/ManualInputFormGroup';

class OffsetBusinessCard extends Component {
    constructor(props) {
        super(props);
        this.product = 'Offset Business Card';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
                lamination: [],
                folding: [],
                spot_uv: [],
                round_corner: [],
                hole_punching: [],
                print: []
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                orientation: null,
                material: null,
                quantity: 0,
                print: null,
                lamination: null,
                folding: null,
                custom_creasing: false,
                creasing: 10,
                creasing_remains: 0,
                spot_uv: null,
                hot_stamping: null,
                hot_stamping_colour_1: null,
                hot_stamping_colour_2: null,
                round_corner: null,
                round_corner_type: null,
                hole_punching: null,
                // cover_artwork: null,
                // cover_artwork_path: null,
                // cover_artwork_download_url: null,
                weight: 0.0,
                other1: { othersSelected: false, name: 'Other1', value: '', price: 0.0 },
                other2: { othersSelected: false, name: 'Other2', value: '', price: 0.0 },
                other3: { othersSelected: false, name: 'Other3', value: '', price: 0.0 },
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                original_price: 0.0,
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            adjustment_value: 0.00,
            adjustment_amount: 0.00,
            adjustment_type: "%",
            price: 0.00,
            customeroption: [],
            selectedCustomer: null,
            unit_price: 0.0,
            popoverOpen: false,
            popoverTarget: 'name',
            popoverText: '',
            customerName: null,
            customer_email_validation: null,
            matchQuoteConfig: false,
        };
        this.customerRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
        this.props.getCustomers();
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {

        if (this.props.checkout.list && (this.props.checkout.list !== previousProps.checkout.list)) {
            let customeroption = this.props.checkout.list.map((obj, index) => {
                let newObj = {}; newObj.label = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.value = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.email = obj.email; newObj.index = index;
                return newObj;
            });

            console.log(customeroption)
            this.setState({ data: _.chunk(this.props.checkout.list), customeroption: _.sortBy(customeroption, ['label']) });
        }

        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            const { selectedQuoteProduct } = this.props.quote; // Product From Existed Quote
            let selectedProduct = this.props.cart.items.find((el) => el.product === 'Offset Business Card');
            console.log(selectedProduct)

            if (selectedQuoteProduct && (selectedQuoteProduct.product === 'Offset Business Card')) {
                this.props.emptyCart(); // To Replace Current Cart Item
                let newConfigurations = selectedQuoteProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedQuoteProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedQuoteProduct.customer,
                    title: selectedQuoteProduct.title,
                    adjustment_amount: selectedQuoteProduct.adjustment_amount,
                    adjustment_type: selectedQuoteProduct.adjustment_type,
                    adjustment_value: selectedQuoteProduct.adjustment_value,
                    adjustPrintingSwitch: selectedQuoteProduct.adjustPrintingSwitch,
                    adj_cover_print_price: selectedQuoteProduct.adj_cover_print_price,
                    adj_inner_print_price: selectedQuoteProduct.adj_inner_print_price,
                    adj_printing_price: selectedQuoteProduct.adj_printing_price,
                    customerName: selectedQuoteProduct.customer.name,
                    customer_email_validation: selectedQuoteProduct.customer.email,
                });
                // Fetch Cart Data and Apply It
            } else if (selectedProduct) {
                let newConfigurations = selectedProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

                let newQuantities = newDynamicOptions['quantity'];
                let currentQuantity = newConfigurations['quantity'];
                if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                    newConfigurations['quantity'] = newQuantities[0];
                }

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedProduct.customer,
                    title: selectedProduct.title,
                    adjustment_amount: selectedProduct.adjustment_amount,
                    adjustment_type: selectedProduct.adjustment_type,
                    adjustment_value: selectedProduct.adjustment_value,
                    customerName: selectedProduct.customer.name,
                    customer_email_validation: selectedProduct.customer.email,
                });
            }
            else {
                let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

                // Make sure the state's default value synced up with the UI one during initial data load.
                let newQuantities = newDynamicOptions['quantity'];
                let currentQuantity = newConfigurations['quantity'];
                if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                    newConfigurations['quantity'] = newQuantities[0];
                }

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: newConfigurations,
                    isLoadingData: false
                });
            }
        }

        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;

            // Get the correct options based on the latest configurations
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
            if (previousState.configurations.size !== this.state.configurations.size) {

                // Select the first valid options, ONLY IF the current selected is not a valid option
                if (newDynamicOptions.folding.includes(newConfig.folding) === false) {
                    newConfig.folding = newDynamicOptions.folding[0];
                }

                if (newDynamicOptions.round_corner.includes(newConfig.round_corner) === false) {
                    newConfig.round_corner = newDynamicOptions.round_corner[0];
                    newConfig.round_corner_type = null;
                }

                if (newDynamicOptions.hole_punching.includes(newConfig.hole_punching) == false) {
                    newConfig.hole_punching = newDynamicOptions.hole_punching[0];
                }
            }


            // Re-query for potential drop-down options, if the configurations is changed due to default settings
            newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantities = newDynamicOptions['quantity'];
            let currentQuantity = newConfig['quantity'];
            if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                newConfig['quantity'] = newQuantities[0];
            }

            // Find the right variant
            let variant = this.props.product.setup.variants.find(variant =>
                (variant.material === newConfig.material)
                && (variant.lamination === newConfig.lamination)
                && (variant.spot_uv === newConfig.spot_uv)
                && (variant.folding === newConfig.folding)
            );
            let item = null;
            console.log(variant);
            console.log(this.state.configurations);

            // Find the right price
            if (variant && this.state.configurations.print) {
                item = variant.prices.find(item => (item.quantity == newConfig.quantity))
                if (item) {
                    newPriceAndDuration.original_price = item[newConfig.print.replace(' ', '_')] + parseFloat(this.state.configurations.other1.price) + parseFloat(this.state.configurations.other2.price) + parseFloat(this.state.configurations.other3.price);
                    newPriceAndDuration.price = this.state.adjustment_amount + newPriceAndDuration.original_price;
                    newPriceAndDuration.duration = variant.duration;

                    if (this.state.configurations.round_corner !== 'None') {
                        newPriceAndDuration.original_price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.duration += variant.duration_RC_PH;
                    }

                    if (this.state.configurations.hole_punching !== 'None') {
                        newPriceAndDuration.original_price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.price += item.RoundCorner_PunchHole;
                        newPriceAndDuration.duration += variant.duration_RC_PH;
                    }

                    if (this.state.configurations.hot_stamping !== 'None') {
                        newPriceAndDuration.original_price += item.HotStamping;
                        newPriceAndDuration.price += item.HotStamping;
                        newPriceAndDuration.duration += variant.duration_HS;
                        if (this.state.configurations.hot_stamping.includes('2C')) {
                            newPriceAndDuration.original_price += item.HotStamping;
                            newPriceAndDuration.price += item.HotStamping;
                        }
                    }

                } else {
                    newPriceAndDuration.original_price = 0.0;
                    newPriceAndDuration.price = 0.0;
                    newPriceAndDuration.duration = 0;
                }
            }
            console.log(newPriceAndDuration);

            // Check Config Match With Product Options
            if (this.props.quote.selectedQuoteProduct) {
                let checkOrientation = this.props.product.setup.options.orientation.values.some((el) => { return el === newConfig.orientation; });
                let checkPrint = this.props.product.setup.options.print.values.some((el) => { return el === newConfig.print; });
                let checkSize = this.props.product.setup.options.size.values.some((el) => { return ((el.height === newConfig.size.height) && (el.width === newConfig.size.width)); });
                if (checkOrientation && checkPrint && checkSize && variant && item) {
                    console.log('All Config Match')
                } else {
                    // Show Match Error Modal
                    this.setState({ matchQuoteConfig: true })
                }
            }

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });
        }

        if (previousProps.cart.upload_count !== this.props.cart.upload_count) {
            if ((this.props.cart.total_uploads > 0) && (this.props.cart.total_uploads === this.props.cart.upload_count)) {
                let newConfig = this.state.configurations;
                newConfig.cover_artwork = this.props.cart.cover_artwork;
                newConfig.cover_artwork_path = this.props.cart.cover_artwork_path;
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                this.setState({
                    dynamic_options: newDynamicOptions,
                    configurations: newConfig
                });
            }
        }


        if ((this.state.adjustment_type !== previousState.adjustment_type) || (this.state.adjustment_value !== previousState.adjustment_value) || (this.state.configurations !== previousState.configurations) || (this.state.selectedCustomer !== previousState.selectedCustomer)) {
            let final_price = this.state.price_and_duration;
            let quantity = parseInt(this.state.configurations.quantity);

            if ((this.state.adjustment_value.toString().length > 0) && !isNaN(this.state.adjustment_value)) {
                console.log(this.state.adjustment_amount)
                this.state.adjustment_amount = (this.state.adjustment_type === '%') ? (this.state.adjustment_value * final_price.original_price / 100) : parseFloat(this.state.adjustment_value);
                final_price.price = this.state.adjustment_amount + final_price.original_price;
                let unit_price = parseFloat((final_price.price) / quantity).toFixed(4);
                this.setState({ adjustment_amount: this.state.adjustment_amount, price_and_duration: final_price, adjustment_value: parseFloat(this.state.adjustment_value), unit_price });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        let defaultVariant = (this.props.product.setup.variants) ? this.props.product.setup.variants[0] : [];
        const defaultVariantKeys = Object.keys(defaultVariant);
        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (defaultVariantKeys.includes(name)) {
                newConfigurations[name] = Array.isArray(defaultVariant[name]) ? defaultVariant[name][0] : defaultVariant[name];
            } else if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['cover_artwork', 'cover_artwork_path', 'cover_artwork_download_url'].includes(name)) {
                    // Use null as default
                    defaultValue = null;
                } else if (['folding', 'lamination', 'spot_uv'].includes(name)) {
                    // Use first value's type as default
                    defaultValue = productOptions[name].values[0].type;
                } else if (name === 'quantity') {
                    defaultValue = 0;
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations;
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // material choices affects laminations & spot uv choices
        const selectedMaterial = productConfigurations.material;
        // material -> lamination
        const staticLaminationOptions = productOptions.lamination;
        let dynLaminationOptions = []
        staticLaminationOptions.values.forEach((element, index) => {
            if (element.material.includes(selectedMaterial)) {
                dynLaminationOptions.push(element.type);
            }
        })
        newDynamicOptions.lamination = dynLaminationOptions;

        // material -> spot uv
        const staticSpotUvOptions = productOptions.spot_uv;
        let dynSpotUvOptions = [];
        staticSpotUvOptions.values.forEach((element, index) => {
            if (element.material.includes(selectedMaterial)) {
                dynSpotUvOptions.push(element.type);
            }
        })
        newDynamicOptions.spot_uv = dynSpotUvOptions;

        // lamination -> spot uv
        const selectedLamination = productConfigurations.lamination;
        if (selectedLamination.toUpperCase().includes('GLOSS')) {
            newDynamicOptions.spot_uv = ['None'];
        }

        // material -> print (Frosted Plastic Card can only print 1 side)
        const staticPrintOptions = productOptions.print;
        let dynPrintOptions = [];
        staticPrintOptions.values.forEach((element, index) => {
            if (!(selectedMaterial.toUpperCase().includes('FROSTED PLASTIC CARD') && element.toUpperCase().includes('BOTH'))) {
                dynPrintOptions.push(element);
            }
        })
        newDynamicOptions.print = dynPrintOptions;

        // size affects folding choices
        // size -> folding
        const selectedSize = productConfigurations.size.width;
        const staticFoldingOptions = productOptions.folding;
        let dynFoldingOptions = [];
        staticFoldingOptions.values.forEach(value => {
            if (value.card_width.includes(selectedSize)) {
                dynFoldingOptions.push(value.type);
            }
        })
        newDynamicOptions.folding = dynFoldingOptions;

        newDynamicOptions.hole_punching = productOptions.hole_punching.values;
        newDynamicOptions.round_corner = productOptions.round_corner.values;
        // folding affects round corner and hole punching
        if (productConfigurations.folding !== 'None') {
            // no round corner & hold punching when folded
            newDynamicOptions.round_corner = ['None'];
            newDynamicOptions.hole_punching = ['None'];
        }

        // quantity depends on material + lamination + spot uv + folding
        let selectedVariant = productVariants.find((variant) => {
            return ((variant.material === productConfigurations.material) && (variant.lamination === productConfigurations.lamination) && (variant.spot_uv === productConfigurations.spot_uv) && (variant.folding === productConfigurations.folding));
        });
        // let dynQuantityOptions = (selectedVariant) ? [0, ...selectedVariant.quantity] : [0];
        console.log(productConfigurations);
        console.log(selectedVariant);
        let dynQuantityOptions = (selectedVariant) ? [...selectedVariant.quantity] : [];
        newDynamicOptions.quantity = dynQuantityOptions;

        console.log('New Dynoptions');
        console.log(newDynamicOptions);
        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        const options = this.props.product.setup.options;
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'hot_stamping') {
            // special handling for hot stamping colour
            if (e.target.value.includes('1C')) {
                if (futureConfigs.hot_stamping_colour_1 === null) {
                    futureConfigs.hot_stamping_colour_1 = 'Black';
                }
                futureConfigs.hot_stamping_colour_2 = null;
            } else if (e.target.value.includes('2C')) {
                if (futureConfigs.hot_stamping_colour_1 === null) {
                    futureConfigs.hot_stamping_colour_1 = 'Black';
                }
                if (futureConfigs.hot_stamping_colour_2 === null) {
                    futureConfigs.hot_stamping_colour_2 = 'Black';
                }
            } else {
                futureConfigs.hot_stamping_colour_1 = null;
                futureConfigs.hot_stamping_colour_2 = null;
            }
        } else if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)

            // updating creasing remain value
            futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
        } else if (e.target.name === 'round_corner') {
            if (futureConfigs.round_corner === 'None') {
                // Reset it
                futureConfigs.round_corner_type = null
            } else if (futureConfigs.round_corner_type === null && options.round_corner.round_corner_type.length > 0) {
                // Set a default
                futureConfigs.round_corner_type = options.round_corner.round_corner_type[0];
            }
        } else if (e.target.name === 'custom_creasing') {
            if (futureConfigs.custom_creasing === false) {
                // reset to default
                futureConfigs.creasing = 10;
                futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
            }
        } else if (e.target.name === 'creasing') {
            // update creasing remain value
            futureConfigs.creasing_remains = futureConfigs.size.width - futureConfigs.creasing;
        } else if (e.target.name === 'creasing_remains') {
            // update creasing value
            futureConfigs.creasing = futureConfigs.size.width - futureConfigs.creasing_remains;
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);
        if (e.target.name === 'material') {
            futureConfigs['lamination'] = futureDynOptions['lamination'] ? futureDynOptions['lamination'][0] : null;
            futureConfigs['spot_uv'] = futureDynOptions['spot_uv'] ? futureDynOptions['spot_uv'][0] : null;
            futureConfigs['print'] = futureDynOptions['print'] ? futureDynOptions['print'][0] : null;
            futureConfigs['quantity'] = futureDynOptions['quantity'] ? futureDynOptions['quantity'][0] : null;
            console.log(futureDynOptions['quantity']);
            console.log(futureConfigs['quantity']);
        }

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options } = this.state;

        switch (configName) {

            case 'size':
                // static options
                return (
                    <SizeFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )

            case 'orientation':
                // static options
                return (
                    <OrientationFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )
            case 'material':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'print':
            case 'lamination':
            case 'quantity':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'spot_uv':
            case 'hole_punching':
                // DYNAMIC dropdown options
                return (
                    <OptionalGenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'folding':
                // dynamic options
                return (
                    <FoldingCreasingFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        optionItemsSource={dynamic_options[configName]}
                        selectedOption={configurations[configName]}
                        customCreasing={configurations.custom_creasing}
                        creasing={configurations.creasing}
                        creasingRemains={configurations.creasing_remains}
                        materialSize={configurations.size}
                    />
                )

            case 'hot_stamping':
                // static options
                return (
                    <HotStampingFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        optionItemsSource={options[configName].values}
                        selectedOption={configurations[configName]}
                        colourItemsSource={options[configName].colours}
                        selectedColour1={configurations[`${configName}_colour_1`]}
                        selectedColour2={configurations[`${configName}_colour_2`]}
                    />
                )

            case 'round_corner':
                // dynamic options for options
                // static options for types
                let cornerTypeKey = `${configName}_type`;
                return (
                    <RoundCornerFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        selectedOption={configurations[configName]}
                        optionItemsSource={dynamic_options[configName]}
                        selectedCornerType={configurations[cornerTypeKey]}
                        cornerTypeItemsSource={options[configName].round_corner_type}
                    />
                )

            // case 'cover_artwork':
            //     return (<SelectFileFormGroup
            //         key={configName}
            //         name={configName}
            //         label={configSettings.label}
            //         accept=".pdf, .rar, .zip"
            //         onChange={this.handleFileSelect.bind(this, 'offset_business_card_cover')}
            //     />
            //     )

            // case 'cover_artwork_download_url':
            //     return (
            //         <ManualInputFormGroup
            //             key={configName}
            //             name={configName}
            //             label={configSettings.label}
            //             value={configurations[configName]}
            //             inputAttributes={{ placeholder: 'ARTWORK DOWNLOAD URL', style: { textTransform: 'none' } }}
            //             onChange={this.handleInputChange.bind(this)}
            //         />
            //     )
            default:
                break;
        }
    }

    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        var missingConfigurations = [];

        // Add checks for required configuration here
        // if (this.state.configurations['cover_artwork_path'] == null &&
        //     (this.state.configurations['cover_artwork_download_url'] == null ||
        //         this.state.configurations['cover_artwork_download_url'] == "")) {
        //     missingConfigurations.push("Artwork");
        // }

        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {
        const { material, size } = this.state.configurations;
        let quantity = parseInt(this.state.configurations.quantity);

        // if (!this.props.main.selectedcustomer) {
        //     this.props.showCustomerSelectionModal(true);
        //     return;
        // }

        if (!this.state.selectedCustomer) {
            this.customerRef.current.focus();
            this.setState({
                popoverText: 'Customer Name is required!',
                popoverTarget: 'name',
                popoverOpen: true
            });
            return;
        }

        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let paper_density = parseInt(material);
        let weight = Math.ceil(quantity * size.height * size.width * paper_density / 1e9) + 2;  // size in mm and density in g to convert to kg, round up to nearest kg then add 2kg

        let config = { ...this.state.configurations, weight }

        if (this.state.configurations.folding.toUpperCase() === 'NONE') {
            config.custom_creasing = undefined;
            config.creasing = undefined;
            config.creasing_remains = undefined;
        } else {
            config.custom_creasing = this.state.configurations.custom_creasing;
            config.creasing = this.state.configurations.creasing;
            config.creasing_remains = this.state.configurations.creasing_remains;
        }

        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            customer: this.state.selectedCustomer,
            product: this.product,
            configurations: config,
            title: this.state.title,
            unit_price: parseFloat(this.state.unit_price),
            duration: this.state.price_and_duration.duration,
            original_price: this.state.price_and_duration.original_price,
            adjustment_type: this.state.adjustment_type,
            adjustment_value: parseFloat(this.state.adjustment_value),
            adjustment_amount: this.state.adjustment_amount,
            price: this.state.price_and_duration.price,
        });

        //Clear Quote since we are generating new cart item
        this.props.emptyQuote();

        // this.props.history.push('/cart');
        this.props.history.push('/checkout');
    }


    handleAdjustmentInput(e) {
        this.setState({ adjustment_value: e.target.value });
    }

    handleAdjustmentTypeSelect(type, e) {
        this.setState({ adjustment_type: type });
    }

    handleInputText(e) {
        let value = '';

        if (e.target.name === 'title') {
            value = e.target.value.toUpperCase()
        } else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }

    handleVisibleOthers(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.othersSelected = e.target.checked;
        console.log(e.target.checked)

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherName(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.name = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherValue(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.value = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }


    handleInputOther(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.price = parseFloat(e.target.value);

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleAdjustmentUnitPrice(e) {
        let final_price = this.state.price_and_duration;
        let quantity = parseInt(this.state.configurations.quantity);

        //When Unit Price Changed, Final Price will also change as the unit price
        final_price.price = (e.target.value * quantity);
        console.log(final_price.price)
        //Find Out New Adjustment Value after unit price changed and update to the adjustment value field
        //Adjusment value will depend the adjusment type to calculate
        this.state.adjustment_amount = (final_price.price - final_price.original_price);
        this.state.adjustment_value = (this.state.adjustment_type === '%') ? parseFloat(this.state.adjustment_amount / final_price.original_price * 100).toFixed(2) : parseFloat(this.state.adjustment_amount).toFixed(2);
        console.log(this.state.adjustment_value)

        this.setState({ unit_price: parseFloat(e.target.value), price_and_duration: final_price, adjustment_amount: this.state.adjustment_amount, adjustment_value: this.state.adjustment_value })
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    handleSelectChange(e) {
        console.log(e)
        if ((!e.email) || (e.email === "")) {
            console.log(e.email)
            this.customerRef.current.focus();

            this.setState({
                popoverText: 'Missing Customer Info!',
                popoverTarget: 'name',
                popoverOpen: true,
                popoverPosition: 'bottom',
                customerName: e,
                customer_email_validation: e.email
            });

        } else {
            this.setState({ popoverOpen: false, customerName: e, customer_email_validation: null });
        }

        // Find Customer by index from selected customer selection value
        let selectedCustomer = "";
        selectedCustomer = this.props.checkout.list.find(function (element, index) {
            if ((index === e.index) && (e.index !== "")) {
                return element;
            }
        });
        console.log(selectedCustomer)
        this.setState({ selectedCustomer, customerName: e, customer_email_validation: e.email });
        this.props.selectCustomer(selectedCustomer);
    }

    toggleTooltip() {
        const { configurations, price_and_duration, customer_email_validation, customerName, title } = this.state;
        if (!title) {
            this.setState({
                popoverText: 'Please Enter A Title',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if ((price_and_duration.price <= 0) || (price_and_duration.price === NaN)) {

            this.setState({
                popoverText: 'Your Total Price is Zero or Less Than Zero!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (configurations.quantity <= 0) {

            this.setState({
                popoverText: 'Quantity is 0!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customerName) {
            this.setState({
                popoverText: 'Please Select A Customer.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customer_email_validation) {
            this.setState({
                popoverText: 'Please Update Customer Details To Proceed.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
    }


    render() {
        const { setup } = this.props.product;
        const { upload_started, upload_count, upload_filename, total_uploads } = this.props.cart;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, adjustment_type, adjustment_amount, selectedCustomer, customeroption, title, customer_email_validation, customerName, matchQuoteConfig } = this.state;
        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                {/* <JsonDisplay object={this.state} style={{ position: 'absolute', background: 'white', zIndex: '2', right: '0px', fontSize: '0.75em', border: '1px blue solid' }} /> */}
                <Modal isOpen={matchQuoteConfig} className='scrolling'>
                    <ModalHeader style={{ backgroundColor: '#dd3636', color: 'white', marginRight: '-0.5px' }}>
                        Doesn't Match Default Config Selection
                    </ModalHeader>
                    <ModalBody>
                        <p>Selected Quote Doest Not Match Default Configurations Selection.</p>
                        <p>Please Create A New Quotation.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.history.goBack}>Back</Button>
                    </ModalFooter>
                </Modal>
                <Popover placement={this.state.popoverPosition} isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                    <PopoverBody>{this.state.popoverText}</PopoverBody>
                </Popover>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book">{this.product.toUpperCase()}</h1>
                        <Form className="product-info">
                            <FormGroup row>
                                <Label sm={3}> <b>Customer</b></Label>
                                <Col sm={9}>
                                    <Select id="name" name="name" ref={this.customerRef} placeholder={selectedCustomer ? selectedCustomer.name.toUpperCase() + " (" + selectedCustomer.email + ") -" + selectedCustomer.company : "NAME / COMPANY / EMAIL"} options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Title</b></Label>
                                <Col sm={9}>
                                    <Input name='title' id='title' type="text" placeholder="Title" value={title} style={{ textTransform: 'uppercase' }} onChange={this.handleInputText.bind(this)} />
                                </Col>
                            </FormGroup>
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Other</h5>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other1" id="other1" value={configurations.other1.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other1.othersSelected} />
                                    <Input name="other1" id="other1" type="text" placeholder="(Title)" value={configurations.other1.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="text" placeholder="(Value)" value={configurations.other1.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="number" placeholder="(Price)" value={configurations.other1.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other2" id="other2" value={configurations.other2.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other2.othersSelected} />
                                    <Input name="other2" id="other2" type="text" placeholder="(Title)" value={configurations.other2.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="text" placeholder="(Value)" value={configurations.other2.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="number" placeholder="(Price)" value={configurations.other2.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other3" id="other3" value={configurations.other3.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other3.othersSelected} />
                                    <Input name="other3" id="other3" type="text" placeholder="(Title)" value={configurations.other3.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="text" placeholder="(Value)" value={configurations.other3.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="number" placeholder="(Price)" value={configurations.other3.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Price</h5>
                            <Fragment>
                                <TimeAndCostFormGroup
                                    price={price_and_duration.original_price}
                                    processDay={price_and_duration.duration} />
                                <FormGroup row>
                                    <Label sm={3}> <b>Unit Price</b></Label>
                                    <Col sm={9}>
                                        <Input name="unit_price" id="unit_price" type="number" placeholder="ADJUSTMENT" value={this.state.unit_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentUnitPrice.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>
                                        <b style={{ display: 'block' }}>Adjustment Value</b>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input name="adjustment_value" id="adjustment_value" type="number" placeholder="ADJUSTMENT" value={this.state.adjustment_value} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentInput.bind(this)} />
                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle caret>
                                                    {
                                                        (adjustment_type === '%') ? '% ' : 'RM'
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, '%')}>Percentage (%)</DropdownItem>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, 'RM')} >Riggit Malaysia (RM)</DropdownItem>
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Adjustment Amount</b></Label>
                                    <Label sm={9} ><b>RM {adjustment_amount.toFixed(2)}</b></Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Price</b></Label>
                                    <Label sm={9} ><b>RM {price_and_duration.price.toFixed(2)}</b></Label>
                                </FormGroup>
                                {(title) && (configurations.quantity > 0) && (price_and_duration.price > 0 && price_and_duration.price != NaN) && (customer_email_validation) && (customerName) ?
                                    <Button id="add-to-cart" name="add-to-cart" onClick={this.addToCart.bind(this)} >NEXT</Button>
                                    :
                                    <Button id="add-to-cart" name="add-to-cart" style={{ opacity: '0.5' }} onClick={this.toggleTooltip.bind(this)} onMouseOver={this.toggleTooltip.bind(this)} onMouseOut={this.closePopover.bind(this)}>NEXT</Button>
                                }
                            </Fragment>
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart, main, checkout, quote }) => {
    return { product, cart, main, checkout, quote };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart,
        showCustomerSelectionModal: showCustomerSelectionModal,
        selectCustomer: selectCustomer,
        getCustomers: getCustomers,
        emptyCart: emptyCart,
        emptyQuote: emptyQuote,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(OffsetBusinessCard));
