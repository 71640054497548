import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import {
    Container, Row, Col, Form, FormGroup, Label, Input, Button, Alert, Tooltip,
    InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, PopoverBody, Popover
} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart, showCustomerSelectionModal, getCustomers, selectCustomer } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import { productSpecUrl, productPriceUrl } from "./Utility";
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';
import imagecalendar from './Images/calendar-without-header.png';


class RacingHorseCalendarInset extends Component {
    constructor(props) {
        super(props);
        this.product = 'Racing Horse Calendar Without Header';
        this.state = {
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                size: null,
                quantity: 0,
                print: null,
                material: null,
                weight: 0.0,
                other1: { othersSelected: false, name: 'Other1', value: '', price: 0.0 },
                other2: { othersSelected: false, name: 'Other2', value: '', price: 0.0 },
                other3: { othersSelected: false, name: 'Other3', value: '', price: 0.0 },
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                original_price: 0.0,
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            tooltipOpen: false,
            tooltipMesg: '',
            adjustment_value: 0.00,
            adjustment_amount: 0.00,
            adjustment_type: "%",
            price: 0.00,
            customeroption: [],
            selectedCustomer: null,
            unit_price: 0.0,
            popoverOpen: false,
            popoverTarget: 'name',
            popoverText: '',
            customerName: null,
            customer_email_validation: null,
        };
        this.customerRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
        this.props.getCustomers();
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if (this.props.checkout.list && (this.props.checkout.list !== previousProps.checkout.list)) {
            let customeroption = this.props.checkout.list.map((obj,index) => {
                let newObj = {}; newObj.label = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.value = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.email = obj.email; newObj.index = index;
                return newObj;
            });

            console.log(customeroption)
            this.setState({ data: _.chunk(this.props.checkout.list), customeroption: _.sortBy(customeroption, ['label']) });
        }

        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            let selectedProduct = this.props.cart.items.find((el) => el.product === 'Racing Horse Calendar Without Header');
            console.log(selectedProduct)

            if (selectedProduct) {
                let newConfigurations = selectedProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

                let newQuantities = newDynamicOptions['quantity'];
                let currentQuantity = newConfigurations['quantity'];
                if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                    newConfigurations['quantity'] = newQuantities[0];
                }

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedProduct.customer,
                    title: selectedProduct.title,
                    adjustment_amount: selectedProduct.adjustment_amount,
                    adjustment_type: selectedProduct.adjustment_type,
                    adjustment_value: selectedProduct.adjustment_value,
                    customerName: selectedProduct.customer.name,
                    customer_email_validation: selectedProduct.customer.email,
                });
            }
            else {
                let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

                // Make sure the state's default value synced up with the UI one during initial data load.
                let newQuantities = newDynamicOptions['quantity'];
                let currentQuantity = newConfigurations['quantity'];
                if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                    newConfigurations['quantity'] = newQuantities[0];
                }

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: newConfigurations,
                    isLoadingData: false
                });
            }
        }


        if (previousState.configurations !== this.state.configurations) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;

            // Get the correct options based on the latest configurations
            let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);

            // Make sure the state's default value synced up with the UI one during initial data load.
            let newQuantities = newDynamicOptions['quantity'];
            let currentQuantity = newConfig['quantity'];
            if (Array.isArray(newQuantities) && newQuantities.length > 0 && newQuantities.includes(parseInt(currentQuantity)) === false) {
                newConfig['quantity'] = newQuantities[0];
            }

            let variant = this.props.product.setup.variants.find(variant => (variant.size === newConfig.size.type));
            // Find the right price
            if (variant && this.state.configurations.print) {
                let item = variant.prices.find(item => (item.quantity == newConfig.quantity))
                if (item) {
                    newPriceAndDuration.original_price = item.Price + parseFloat(this.state.configurations.other1.price) + parseFloat(this.state.configurations.other2.price) + parseFloat(this.state.configurations.other3.price);
                    newPriceAndDuration.price = item.Price + this.state.adjustment_amount;
                    newPriceAndDuration.duration = item.duration;
                    newConfig['weight'] = item.weight;
                } else {
                    newPriceAndDuration.original_price = 0.0;
                    newPriceAndDuration.price = 0.0;
                    newPriceAndDuration.duration = 0;
                    newConfig['weight'] = 0.0;
                }
            }

            this.setState({
                dynamic_options: newDynamicOptions,
                price_and_duration: newPriceAndDuration,
                configurations: newConfig
            });
        }

        if ((this.state.adjustment_type !== previousState.adjustment_type) || (this.state.adjustment_value !== previousState.adjustment_value) || (this.state.configurations !== previousState.configurations) || (this.state.selectedCustomer !== previousState.selectedCustomer)) {
            let final_price = this.state.price_and_duration;
            let quantity = parseInt(this.state.configurations.quantity);

            if ((this.state.adjustment_value.toString().length > 0) && !isNaN(this.state.adjustment_value)) {
                console.log(this.state.adjustment_amount)
                this.state.adjustment_amount = (this.state.adjustment_type === '%') ? (this.state.adjustment_value * final_price.original_price / 100) : parseFloat(this.state.adjustment_value);
                final_price.price = this.state.adjustment_amount + final_price.original_price;
                let unit_price = parseFloat((final_price.price) / quantity).toFixed(4);
                this.setState({ adjustment_amount: this.state.adjustment_amount, price_and_duration: final_price, adjustment_value: parseFloat(this.state.adjustment_value), unit_price });
            }
        }
    }

    /**
     * Generate configurations with default value, based on the provided options
     *
     * @param {*} productOptions Product options
     *
     * @returns {Object} configurations with default value
     */
    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }

        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (name === 'quantity') {
                    defaultValue = 0;
                } else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions == undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // quantity depends on size
        let selectedVariant = productVariants.find((variant) => {
            return ((variant.size === productConfigurations.size.type));
        });
        // let dynQuantityOptions = (selectedVariant) ? [0, ...selectedVariant.quantity] : [0];
        let dynQuantityOptions = (selectedVariant) ? [...selectedVariant.quantity] : [];

        newDynamicOptions.quantity = dynQuantityOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        let futureConfigs = { ...this.state.configurations };

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            futureConfigs[e.target.name] = e.target.checked;
        } else {
            // everything else just let it slide
            futureConfigs[e.target.name] = e.target.value;
        }

        if (e.target.name === 'size') {
            // convert json string to obj
            futureConfigs.size = JSON.parse(e.target.value)
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    /**
     * Handle file selection event.
     * Acquires token from backend and upload files to AliCloud storage.
     *
     * @param {*} e eventData
     */
    handleFileSelect(type, e) {
        e.stopPropagation();
        e.preventDefault();
        let files = e.target.files;

        console.log(files);
        if (files.length > 0) {
            this.props.uploadFiles(files, type);
        }
    }

    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options } = this.state;

        switch (configName) {


            case 'size':
                // static options
                return (
                    <Fragment>
                        <SizeFormGroup
                            key={configName}
                            name={configName}
                            label={configSettings.label}
                            itemsSource={options[configName].values}
                            selectedItem={configurations[configName]}
                            onChange={this.handleInputChange.bind(this)}

                        />
                    </Fragment>
                )

            case 'material':
            case 'print':
            case 'finishing':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'quantity':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName]}
                        selectedItem={configurations[configName]}
                    />
                )
            default:
                break;
        }
    }


    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
            window.alert(`Invalid product configurations.`);
            return false;
        }

        // everything OK
        return true;
    }

    addToCart() {

        // if (!this.props.main.selectedcustomer) {
        //     this.props.showCustomerSelectionModal(true);
        //     return;
        // }

        if (!this.state.selectedCustomer) {
            this.customerRef.current.focus();
            this.setState({
                popoverText: 'Customer Name is required!',
                popoverTarget: 'name',
                popoverOpen: true
            });
            return;
        }

        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let config = { ...this.state.configurations }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            customer: this.state.selectedCustomer,
            title: this.state.title,
            product: this.product,
            configurations: config,
            unit_price: parseFloat(this.state.unit_price),
            duration: this.state.price_and_duration.duration,
            original_price: this.state.price_and_duration.original_price,
            adjustment_type: this.state.adjustment_type,
            adjustment_value: parseFloat(this.state.adjustment_value),
            adjustment_amount: this.state.adjustment_amount,
            price: this.state.price_and_duration.price,
        });

        // this.props.history.push('/cart');
        this.props.history.push('/checkout');
    }

    toggleOutOfStock(open, e) {
        const { configurations } = this.state;

        if (configurations.size.width === 127) {
            this.setState({
                tooltipMesg: 'Product Out Of Stock!',
                tooltipOpen: open
            });
        }
    }

    handleAdjustmentInput(e) {
        this.setState({ adjustment_value: e.target.value });
    }

    handleAdjustmentTypeSelect(type, e) {
        this.setState({ adjustment_type: type });
    }

    handleInputText(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleVisibleOthers(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.othersSelected = e.target.checked;
        console.log(e.target.checked)

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherName(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.name = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherValue(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.value = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }


    handleInputOther(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.price = parseFloat(e.target.value);

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }
    handleAdjustmentUnitPrice(e) {
        let final_price = this.state.price_and_duration;
        let quantity = parseInt(this.state.configurations.quantity);

        //When Unit Price Changed, Final Price will also change as the unit price
        final_price.price = (e.target.value * quantity);
        console.log(final_price.price)
        //Find Out New Adjustment Value after unit price changed and update to the adjustment value field
        //Adjusment value will depend the adjusment type to calculate
        this.state.adjustment_amount = (final_price.price - final_price.original_price);
        this.state.adjustment_value = (this.state.adjustment_type === '%') ? parseFloat(this.state.adjustment_amount / final_price.original_price * 100).toFixed(2) : parseFloat(this.state.adjustment_amount).toFixed(2);
        console.log(this.state.adjustment_value)

        this.setState({ unit_price: parseFloat(e.target.value), price_and_duration: final_price, adjustment_amount: this.state.adjustment_amount, adjustment_value: this.state.adjustment_value })
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    handleSelectChange(e) {
        console.log(e)
        if ((!e.email) || (e.email === "")) {
            console.log(e.email)
            this.customerRef.current.focus();

            this.setState({
                popoverText: 'Missing Customer Info!',
                popoverTarget: 'name',
                popoverOpen: true,
                popoverPosition: 'bottom',
                customerName: e,
                customer_email_validation: e.email
            });

        } else {
            this.setState({ popoverOpen: false, customerName: e, customer_email_validation: null });
        }

        // Find Customer by index from selected customer selection value
        let selectedCustomer = "";
        selectedCustomer = this.props.checkout.list.find(function (element,index) {
            if ((index === e.index) && (e.index !== "")) {
                return element;
            }
        });
        console.log(selectedCustomer)
        this.setState({ selectedCustomer,customerName: e, customer_email_validation: e.email });
        this.props.selectCustomer(selectedCustomer);
    }

    toggleTooltip() {
        const { configurations, price_and_duration, customer_email_validation, customerName, title } = this.state;
        if (!title) {
            this.setState({
                popoverText: 'Please Enter A Title',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if ((price_and_duration.price <= 0) || (price_and_duration.price === NaN)) {

            this.setState({
                popoverText: 'Your Total Price is Zero or Less Than Zero!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (configurations.quantity <= 0) {

            this.setState({
                popoverText: 'Quantity is 0!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customerName) {
            this.setState({
                popoverText: 'Please Select A Customer.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customer_email_validation) {
            this.setState({
                popoverText: 'Please Update Customer Details To Proceed.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
    }

    render() {
        const { setup } = this.props.product;
        const { configurations, dynamic_options, price_and_duration, isLoadingData, tooltipOpen, tooltipMesg, adjustment_type, adjustment_amount, selectedCustomer, customeroption, title, customerName, customer_email_validation} = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Popover placement={this.state.popoverPosition} isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                    <PopoverBody>{this.state.popoverText}</PopoverBody>
                </Popover>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book" style={{ display: "flex", alignDirection: "column", fontSize: "2.5em" }}>RACING HORSE CALENDAR WITHOUT HEADER<img src={imagecalendar} style={{ height: "180px", paddingRight: "10px" }} /> </h1>
                        <Form className="product-info">
                            <FormGroup row>
                                <Label sm={3}> <b>Customer</b></Label>
                                <Col sm={9}>
                                    <Select id="name" name="name" ref={this.customerRef} placeholder={selectedCustomer ? selectedCustomer.name.toUpperCase() + " (" + selectedCustomer.email + ") -" + selectedCustomer.company : "NAME / COMPANY / EMAIL"} options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Title</b></Label>
                                <Col sm={9}>
                                    <Input name='title' id='title' type="text" placeholder="Title" value={title} style={{ textTransform: 'none' }} onChange={this.handleInputText.bind(this)} />
                                </Col>
                            </FormGroup>
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Other</h5>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other1" id="other1" value={configurations.other1.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other1.othersSelected} />
                                    <Input name="other1" id="other1" type="text" placeholder="(Title)" value={configurations.other1.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="text" placeholder="(Value)" value={configurations.other1.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="number" placeholder="(Price)" value={configurations.other1.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other2" id="other2" value={configurations.other2.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other2.othersSelected} />
                                    <Input name="other2" id="other2" type="text" placeholder="(Title)" value={configurations.other2.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="text" placeholder="(Value)" value={configurations.other2.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="number" placeholder="(Price)" value={configurations.other2.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other3" id="other3" value={configurations.other3.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other3.othersSelected} />
                                    <Input name="other3" id="other3" type="text" placeholder="(Title)" value={configurations.other3.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="text" placeholder="(Value)" value={configurations.other3.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="number" placeholder="(Price)" value={configurations.other3.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Price</h5>
                            <Fragment>
                                <TimeAndCostFormGroup
                                    price={price_and_duration.original_price}
                                    processDay={price_and_duration.duration} />
                                <FormGroup row>
                                    <Label sm={3}> <b>Unit Price</b></Label>
                                    <Col sm={9}>
                                        <Input name="unit_price" id="unit_price" type="number" placeholder="ADJUSTMENT" value={this.state.unit_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentUnitPrice.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>
                                        <b style={{ display: 'block' }}>Adjustment Value</b>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input name="adjustment_value" id="adjustment_value" type="number" placeholder="ADJUSTMENT" value={this.state.adjustment_value} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentInput.bind(this)} />
                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle caret>
                                                    {
                                                        (adjustment_type === '%') ? '% ' : 'RM'
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, '%')}>Percentage (%)</DropdownItem>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, 'RM')} >Riggit Malaysia (RM)</DropdownItem>
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Adjustment Amount</b></Label>
                                    <Label sm={9} ><b>RM {adjustment_amount.toFixed(2)}</b></Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Price</b></Label>
                                    <Label sm={9} ><b>RM {price_and_duration.price.toFixed(2)}</b></Label>
                                </FormGroup>
                                {(configurations.size.width === 127) ?
                                    <div>
                                        <Col sm={16}>
                                            <Alert color="warning" style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                                <p style={{ paddingTop: "5px", margin: '0px', paddingBottom: "5px" }}>Calendar Size 127MM X 133MM is Out Of Stock!</p>
                                            </Alert>
                                        </Col>
                                        <Tooltip placement="top" isOpen={tooltipOpen} target="cartbutton">
                                            {tooltipMesg}
                                        </Tooltip>
                                        <Button
                                            id="cartbutton" className="button checkout-disabled"
                                            onClick={this.toggleOutOfStock.bind(this, true)}
                                            onMouseOver={this.toggleOutOfStock.bind(this, true)}
                                            onMouseOut={this.toggleOutOfStock.bind(this, false)}>NEXT</Button>
                                    </div>
                                    :
                                    <Fragment>
                                        {(title) && (configurations.quantity > 0) && (price_and_duration.price > 0 && price_and_duration.price != NaN) && (customer_email_validation) && (customerName) ?
                                            <Button id="add-to-cart" name="add-to-cart" onClick={this.addToCart.bind(this)} >NEXT</Button>
                                            :
                                            <Button id="add-to-cart" name="add-to-cart" style={{ opacity: '0.5' }} onClick={this.toggleTooltip.bind(this)} onMouseOver={this.toggleTooltip.bind(this)} onMouseOut={this.closePopover.bind(this)}>NEXT</Button>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart, main, checkout }) => {
    return { product, cart, main, checkout };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart,
        showCustomerSelectionModal: showCustomerSelectionModal,
        getCustomers: getCustomers,
        selectCustomer: selectCustomer,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(RacingHorseCalendarInset));
