export const serverURI = 'https://www.utech.com.my:5889';
export const firebaseConfig = {
  apiKey: "AIzaSyAhSyi87OtOJS2ZQsaWBRsAkIPaOuCSMrs",
  authDomain: "utech-admin.firebaseapp.com",
  databaseURL: "https://utech-admin.firebaseio.com",
  projectId: "utech-admin",
  storageBucket: "utech-admin.appspot.com",
  messagingSenderId: "743156533084"
};

export const capitalizeKey = text => text
  .split('_')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');
