import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';
import { propertyToUrl } from "query-string-params";
import { serverURI, capitalizeKey } from '../../../config';
import _ from 'lodash';
import moment from 'moment';
import {
    Alert,
    Container, Row, Col,
    Button,
    Collapse,
    Table,
    InputGroup,
    Form, FormGroup, Label, Input, FormText,
    Popover, PopoverBody,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardDeck, CardHeader, CardBody
} from 'reactstrap';
import { withAlert } from 'react-alert'
// import { propertyToUrl } from "query-string-params";
import { fetchShippingInfo, calculateShipping, resetShipping, emptyCart, registerLoginSuccessHandle, showLoading, showLoginModal, saveOrder, verifyVoucher, getCustomers, selectCustomer, getPaymentTerms, getDeliverySchedule } from '../../../actions';
import MediaQuery from 'react-responsive';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';

import './Checkout.css';


const request = require('superagent');
const deepcopy = require('deepcopy');

class Checkout extends Component {

    constructor(props) {
        super(props);
        window.Handlebars.registerHelper('date', function (date) {
            return moment(date).format('DD/MM/YYYY');
        });

        window.Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
            return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
        });

        window.Handlebars.registerHelper('ifIncludes', function (arg1, arg2, options) {
            return (arg1.toString().includes(arg2)) ? options.fn(this) : options.inverse(this);
        });

        window.Handlebars.registerHelper('currency', function (value) {
            return value.toFixed(2);
        });
        window.Handlebars.registerHelper('math', function (lvalue, operator, rvalue, options) {
            lvalue = parseFloat(lvalue);
            rvalue = parseFloat(rvalue);

            return {
                "+": lvalue + rvalue,
                "-": lvalue - rvalue,
                "*": lvalue * rvalue,
                "/": lvalue / rvalue,
                "%": lvalue % rvalue
            }[operator];
        });

        window.Handlebars.registerHelper('showKey', function (value) {
            const capitalize = text => text
                .split('_')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            return capitalize(value);
        });

        window.Handlebars.registerHelper('isNo', function (value) {
            if (value === 'No')
                return;
        });

        window.Handlebars.registerHelper('Uppercase', function (value) {
            return value.toUpperCase();
        });

        window.Handlebars.registerHelper('currency4dec', function (value) {
            return value.toFixed(4);
        });

        this.toggle = this.toggle.bind(this);
        this.state = {
            shippingAddr: false,
            createAcct: false,
            shipping_country: 'Malaysia',
            shipping_state: 'State',
            popoverOpen: false,
            popoverTarget: 'mobile',
            popoverText: '',
            confirmSubmitQuote: false,
            isBlocking: true,
            total_weight: 0.0,
            voucher_code: '',
            voucher_discount: null,
            balance_alert: false,
            submitting: false,
            dropdownOpen: false,
            showAdjustment: true,
            adjustment_value: 0.00,
            adjustment_amount: 0.00,
            customeroption: [],
            searchValue: null,
            payment_terms: "Cash",
            delivery_schedule: "1 week upon confirmation order.",
            newConfig: null,
            customer: {
                name: '',
                email: '',
                mobile: '',
                address: {
                    address_1: '',
                    address_2: '',
                    state: 'State',
                    country: 'Malaysia',
                    postcode: '',
                    town_city: '',
                },
            },
        }
    }

    componentWillMount() {
        const { country, shipping_country, delivery_schedule, payment_terms } = this.props.checkout;
        const { user, customer } = this.props;
        console.log(this.props)
        let total_weight = 0.0;
        this.props.cart.items.forEach(item => total_weight += item.configurations.weight);
        this.props.getPaymentTerms();
        this.props.getDeliverySchedule();
        this.props.getCustomers();
        this.props.fetchShippingInfo([country, shipping_country]);
        console.log(this.props.cart.delivery_option);

        //Customer From Cart
        let selectedCustomer = null;
        this.props.cart.items.forEach(item => selectedCustomer = item.customer);

        if (user) {
            let newConfig = null;
            newConfig = this.props.cart.items.map((el) => { return el.configurations; });
            let config = newConfig.find((el) => el);
            console.log(config);
            this.setState({
                newConfig: deepcopy(config),
                total_weight: total_weight,
                delivery_option: this.props.cart.delivery_option,
            });
            console.log('setState in componentWillMount');
            this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: user.address.country, state: user.address.state, weight: total_weight });
            if (payment_terms.values) {
                console.log(payment_terms.values[0]);
                this.setState({ payment_terms: payment_terms.values[0] });
            }
            if (delivery_schedule.values) {
                console.log(delivery_schedule.values[0]);
                this.setState({ delivery_schedule: delivery_schedule.values[0] });
            }
            // if (this.props.selectedcustomer !== null && this.props.selectedcustomer !== undefined) {
            //     let newCustomer = this.props.selectedcustomer;
            //     this.setState({ customer: newCustomer });
            // }
            // else {
            //     this.setState({ customer: null });
            // }
            if (selectedCustomer !== null && selectedCustomer !== undefined) {
                this.setState({ customer: selectedCustomer });
            }
            else {
                this.setState({ customer: null });
            }
        } else {
            this.setState({
                total_weight: total_weight,
                delivery_option: this.props.cart.delivery_option
            });
            console.log('setState in componentWillMount');
            this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: 'MALAYSIA', state: 'State', weight: total_weight });


        }
    }

    componentDidUpdate(previousProps, previousState) {
        const { newItem } = this.state;
        if (previousProps.cart.items !== this.props.cart.items) {
            if (this.props.cart.items.length === 0) this.props.history.push('/cart');
            let total_weight = 0.0;
            this.props.cart.items.forEach(item => total_weight += item.configurationsweight);
            this.setState({ total_weight: total_weight });
            console.log('Update Item List')
        }

        if ((previousState.shipping_state !== this.state.shipping_state) || (previousState.shippingAddr !== this.state.shippingAddr) || (previousState.total_weight !== this.state.total_weight)) {
            if (this.state.shippingAddr) {
                this.props.calculateShipping({ delivery_option: this.state.delivery_option, country: this.state.shipping_country, state: this.state.shipping_state, weight: this.state.total_weight });
            } else {
                this.props.calculateShipping({ delivery_option: this.state.delivery_option, country: this.state.customer.address.country, state: this.state.customer.address.state, weight: this.state.total_weight });
            }
        }


        // if ((this.state.adjustment_type !== previousState.adjustment_type) || (this.state.adjustment_value !== previousState.adjustment_value)) {
        //     let adjustment_amount = 0.0;

        //     if ((this.state.adjustment_value.length > 0) && !isNaN(this.state.adjustment_value)) {
        //         adjustment_amount = (this.state.adjustment_type === '%') ? (this.state.adjustment_value * this.props.cart.total / 100) : parseFloat(this.state.adjustment_value);
        //     }

        //     this.setState({ adjustment_amount });
        // }

    }

    componentWillUnmount() {
        console.log('Deregistering login callback');
        this.props.registerLoginSuccessHandle(null);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handlePaymentMethod(e) {
        console.log(e.target.value)
        this.setState({
            payment_terms: e.target.value,
        });
    }

    handleDeliverySchedule(e) {
        console.log(e.target.value)
        this.setState({
            delivery_schedule: e.target.value,
        });
    }

    handleLogin(e) {
        e.preventDefault();
        this.props.showLoginModal(true);
    }

    handleBillAddrChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
            e.target.classList.remove('is-invalid');
        } else {
            e.target.style.color = '#495057';
        }
        this.setState({
            customer: {
                ...this.state.customer,
                [e.target.name]: e.target.value
            }
        });
    }

    handleShipAddrChange(e) {
        if (e.target.value === 'State') {
            e.target.style.color = '#999';
        } else {
            e.target.style.color = '#495057';
            this.setState({ shippingStateValid: null });
        }
        this.setState({ shipping_state: e.target.value });
    }

    handleInput(e) {
        console.log({
            customer: {
                ...this.state.customer,
                [e.target.name]: e.target.value
            }
        })
        this.setState({
            customer: {
                ...this.state.customer,
                [e.target.name]: e.target.value
            }
        });
    }

    handleAddressInput(e) {
        let newAddress = this.state.customer.address;
        newAddress[e.target.name] = e.target.value;

        this.setState({
            customer: {
                ...this.state.customer,
                address: newAddress
            }
        });
    }

    handleInputConfigurations(e) {
        const { newConfig } = this.state;

        if (e.target.name === 'size') {
            let newSize = newConfig[e.target.name];
            newSize.type = e.target.value;
            this.setState({ newConfig });
        }
        else if (e.target.name === 'other1' || e.target.name === 'other2' || e.target.name === 'other3') {
            let newOther1 = newConfig[e.target.name];
            newOther1.value = e.target.value;
            this.setState({ newConfig });
        }
        else {
            newConfig[e.target.name] = e.target.value;
            console.log(e.target.name)
            this.setState({ newConfig });
        }
    }

    handleAdjustmentInput(e) {
        this.setState({ adjustment_value: e.target.value });
    }

    // handleSelectChange(e, { value }) {

    //     console.log(e.value)

    //     //Input Value find most neareselt email / name
    //     var customer = "";
    //     customer = this.props.checkout.list.find(function (element) {
    //         return element.name === e.value || element.email === e.value || element.company === e.value;
    //     });

    //     // Show value at Form
    //     this.setState({ customer });
    //     console.log(customer);

    // }

    resetInputStyle(e) {
        e.target.classList.remove('is-invalid');
        this.setState({
            popoverOpen: false
        });
    }

    showPhoneHelpText(e) {
        this.setState({
            popoverText: 'Phone number starts with area code (eg. 0XXXXXXXX for fixed line, 01XXXXXXXX for mobile)',
            popoverTarget: e.target.id,
            popoverOpen: true
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    closeBalanceAlert() {
        this.setState({
            balance_alert: false
        });
    }

    calculateDiscount(voucher, amount) {
        let discount = 0;

        if (voucher && voucher.type !== 'error') {
            discount = (voucher.type === 'cash') ? (voucher.value) : (amount * voucher.value / 100.0);
        }

        return discount;
    }

    async completeSubmitQuote(user, quote) {
        console.log('completeSubmitQuote: ');
        console.log(user);
        console.log(quote);

        this.setState({ isBlocking: false, submitting: true });

        let trxn = {
            user: {
                _id: user._id,
                name: user.name,
                email: user.email
            },
            // quote_details: (quote) ? quote : this.props.checkout.quote
            quote_details: quote,
            print_quote: this.state.print_quote
        }
        console.log(this.props.checkout)
        console.log(trxn)
        
        try {
            let resp = await request.post(serverURI + '/v1/admin/submit_quote')
                .set('Content-Type', 'application/json')
                .send(trxn);
            if (resp.status === 200) {
                this.formRef.reset();
                this.setState({
                    shippingAddr: false,
                    createAcct: false,
                    shipping_state: 'State',
                    billing_state: 'State',
                    ib_pay: false,
                    cc_pay: false,
                    popoverOpen: false,
                    popoverTarget: 'mobile',
                    popoverText: '',
                    confirmSubmitQuote: false,
                });
                this.props.showLoading(false);
                this.props.showLoginModal(false);
                console.log("Success Submit Quote")
                console.log(resp.body)
                this.handleGenerateStatement(resp.body);
                this.props.history.push('/thankyou?');
            } else {
                const alert = this.props.alert.show('Failed to submit quote. Please try again!', {
                    timeout: 3000,
                    type: 'error',
                });

                this.setState({ submitting: false });
            }
        } catch (err) {
            console.log(err);
            const alert = this.props.alert.show(err.response.text, {
                timeout: 3000,
                type: 'error',
            });

            this.setState({ submitting: false });
        }
    }

    validateAndSubmit(e) {
        e.preventDefault();
        this.closePopover();
        let totalAmount = (this.props.cart.total + this.props.cart.shipping_fee - this.props.cart.shipping_disc) + parseFloat(this.state.adjustment_amount);


        // Perform form validation
        if (e.target.elements['name'].value === '') {
            e.target.elements['name'].classList.add('is-invalid');
            e.target.elements['name'].focus();
            this.setState({
                popoverText: 'Name is required!',
                popoverTarget: 'name',
                popoverOpen: true
            });
        } else if (e.target.elements['company'].value === '') {
            e.target.elements['company'].classList.add('is-invalid');
            e.target.elements['company'].focus();
            this.setState({
                popoverText: 'Company name is required!',
                popoverTarget: 'company',
                popoverOpen: true
            });
        } else if (e.target.elements['address_1'].value === '') {
            e.target.elements['address_1'].classList.add('is-invalid');
            e.target.elements['address_1'].focus();
            this.setState({
                popoverText: 'Billing address is required!',
                popoverTarget: 'address_1',
                popoverOpen: true
            });
        } else if (e.target.elements['town_city'].value === '') {
            e.target.elements['town_city'].classList.add('is-invalid');
            e.target.elements['town_city'].focus();
            this.setState({
                popoverText: 'Town / City is required!',
                popoverTarget: 'town_city',
                popoverOpen: true
            });
        } else if (e.target.elements['postcode'].value === '') {
            e.target.elements['postcode'].classList.add('is-invalid');
            e.target.elements['postcode'].focus();
            this.setState({
                popoverText: 'Postal code is required!',
                popoverTarget: 'postcode',
                popoverOpen: true
            });
        } else if (isNaN(e.target.elements['postcode'].value) || (e.target.elements['postcode'].value.length < 5)) {
            e.target.elements['postcode'].classList.add('is-invalid');
            e.target.elements['postcode'].focus();
            this.setState({
                popoverText: 'Postal code must be a 5 digit number!',
                popoverTarget: 'postcode',
                popoverOpen: true
            });
        } else if (e.target.elements['state'].value === 'State') {
            e.target.elements['state'].classList.add('is-invalid');
            e.target.elements['state'].focus();
            this.setState({
                popoverText: 'Please select valid state!',
                popoverTarget: 'state',
                popoverOpen: true
            });
        } else if (e.target.elements['mobile'].value === '') {
            e.target.elements['mobile'].classList.add('is-invalid');
            e.target.elements['mobile'].focus();
            this.setState({
                popoverText: 'mobile number is required!',
                popoverTarget: 'mobile',
                popoverOpen: true
            });
        } else if (e.target.elements['mobile'].value.length < 9) {
            e.target.elements['mobile'].classList.add('is-invalid');
            e.target.elements['mobile'].focus();
            this.setState({
                popoverText: 'mobile number must be at least 9 digits in length!',
                popoverTarget: 'mobile',
                popoverOpen: true
            });
        } else if (e.target.elements['email'].value.length < 9) {
            e.target.elements['email'].classList.add('is-invalid');
            e.target.elements['email'].focus();
            this.setState({
                popoverText: 'Email address is required!',
                popoverTarget: 'email',
                popoverOpen: true
            });
        } else if (this.state.shippingAddr) {
            if (e.target.elements['shipping_name'].value === '') {
                e.target.elements['shipping_name'].classList.add('is-invalid');
                e.target.elements['shipping_name'].focus();
                this.setState({
                    popoverText: 'Name is required!',
                    popoverTarget: 'shipping_name',
                    popoverOpen: true
                })
            } else if (e.target.elements['shipping_address_1'].value === '') {
                e.target.elements['shipping_address_1'].classList.add('is-invalid');
                e.target.elements['shipping_address_1'].focus();
                this.setState({
                    popoverText: 'Shipping address is required!',
                    popoverTarget: 'shipping_address_1',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_town_city'].value === '') {
                e.target.elements['shipping_town_city'].classList.add('is-invalid');
                e.target.elements['shipping_town_city'].focus();
                this.setState({
                    popoverText: 'Town / City is required!',
                    popoverTarget: 'shipping_town_city',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_postcode'].value === '') {
                e.target.elements['shipping_postcode'].classList.add('is-invalid');
                e.target.elements['shipping_postcode'].focus();
                this.setState({
                    popoverText: 'Postal code is required!',
                    popoverTarget: 'shipping_postcode',
                    popoverOpen: true
                });
            } else if (isNaN(e.target.elements['shipping_postcode'].value) || (e.target.elements['postcode'].value.length < 5)) {
                e.target.elements['shipping_postcode'].classList.add('is-invalid');
                e.target.elements['shipping_postcode'].focus();
                this.setState({
                    popoverText: 'Postal code must be a 5 digit number!',
                    popoverTarget: 'shipping_postcode',
                    popoverOpen: true
                });
            } else if (e.target.elements['shipping_state'].value === 'State') {
                e.target.elements['shipping_state'].classList.add('is-invalid');
                e.target.elements['shipping_state'].focus();
                this.setState({
                    popoverText: 'Please select valid state!',
                    popoverTarget: 'shipping_state',
                    popoverOpen: true
                });
            }
            // else {
            //     console.log('Validation completed successfully');
            //     // Submit order to backend
            //     let quote = {
            //         customer: {
            //             name: e.target.elements['name'].value.trim().toUpperCase(),
            //             company: e.target.elements['company'].value.trim().toUpperCase(),
            //             address: {
            //                 address_1: e.target.elements['address_1'].value.trim().toUpperCase(),
            //                 address_2: e.target.elements['address_2'].value.trim().toUpperCase(),
            //                 town_city: e.target.elements['town_city'].value.trim().toUpperCase(),
            //                 postcode: e.target.elements['postcode'].value.trim().toUpperCase(),
            //                 state: e.target.elements['state'].value.trim().toUpperCase(),
            //                 country: e.target.elements['country'].value.trim().toUpperCase()
            //             },
            //             mobile: e.target.elements['mobile'].value.trim(),
            //             email: e.target.elements['email'].value.trim().toLowerCase(),
            //         },
            //         recipient: {
            //             name: e.target.elements['shipping_name'].value.trim().toUpperCase(),
            //             address: {
            //                 address_1: e.target.elements['shipping_address_1'].value.trim().toUpperCase(),
            //                 address_2: e.target.elements['shipping_address_2'].value.trim().toUpperCase(),
            //                 town_city: e.target.elements['shipping_town_city'].value.trim().toUpperCase(),
            //                 postcode: e.target.elements['shipping_postcode'].value.trim().toUpperCase(),
            //                 state: e.target.elements['shipping_state'].value.trim().toUpperCase(),
            //                 country: e.target.elements['shipping_country'].value.trim().toUpperCase()
            //             },
            //             phone: e.target.elements['shipping_phone'].value.trim()
            //         },
            //         items: this.props.cart.items,
            //         process_days: this.props.cart.items.reduce((max, item) => Math.max(max, item.duration), 0),
            //         delivery_option: (this.props.cart.delivery_option === 'SELF COLLECT') ? 'SELF COLLECT' : 'COURIER',
            //         subtotal: this.props.cart.total,
            //         shipping_fee: this.props.cart.shipping_fee,
            //         shipping_disc: this.props.cart.shipping_disc,
            //         adjustment_type: this.state.adjustment_type,
            //         adjustment_value: parseFloat(this.state.adjustment_value),
            //         adjustment_amount: parseFloat(this.state.adjustment_amount),
            //         total_amount: parseFloat(totalAmount),
            //         payment_terms: this.state.payment_terms,
            //         notes: e.target.elements['notes'].value.trim(),
            //     }

            //     this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: quote.recipient.address.country, state: quote.recipient.address.state, weight: 0.0 });
            //     if (this.props.user) {
            //         this.completeSubmitQuote(this.props.user, quote);
            //     } else {
            //         this.props.saveOrder(quote);
            //         this.setState({ confirmSubmitQuote: true });
            //     }
            // }
        } else {
            console.log('Validation completed successfully');
            // Submit order to backend
            let editedItem = this.props.cart.items.map((item) => { return item.configurations = this.state.newConfig })
            console.log(editedItem)
            let quote = {
                customer: {
                    name: e.target.elements['name'].value.trim().toUpperCase(),
                    company: e.target.elements['company'].value.trim().toUpperCase(),
                    address: {
                        address_1: e.target.elements['address_1'].value.trim().toUpperCase(),
                        address_2: e.target.elements['address_2'].value.trim().toUpperCase(),
                        town_city: e.target.elements['town_city'].value.trim().toUpperCase(),
                        postcode: e.target.elements['postcode'].value.trim().toUpperCase(),
                        state: e.target.elements['state'].value.trim().toUpperCase(),
                        country: e.target.elements['country'].value.trim().toUpperCase()
                    },
                    mobile: e.target.elements['mobile'].value.trim(),
                    email: e.target.elements['email'].value.trim().toLowerCase(),
                },
                recipient: null,
                delivery_option: (this.props.cart.delivery_option === 'SELF COLLECT') ? 'SELF COLLECT' : 'COURIER',
                items: editedItem ? this.props.cart.items : editedItem,
                process_days: this.props.cart.items.reduce((max, item) => Math.max(max, item.duration), 0),
                subtotal: this.props.cart.total,
                shipping_fee: this.props.cart.shipping_fee,
                shipping_disc: this.props.cart.shipping_disc,
                adjustment_type: this.state.adjustment_type,
                adjustment_value: parseFloat(this.state.adjustment_value),
                adjustment_amount: parseFloat(this.state.adjustment_amount),
                total_amount: parseFloat(totalAmount),
                payment_terms: this.state.payment_terms,
                delivery_schedule: this.state.delivery_schedule,
                notes: e.target.elements['notes'].value.trim(),
            }

            console.log(quote)
            if (this.props.user) {
                this.completeSubmitQuote(this.props.user, quote);
            } else {
                this.props.savequote(quote);
                this.setState({ confirmSubmitQuote: true });
            }
        }
    }

    // handleApplyCode(e) {
    //     console.log(this.props.customer.email);
    //     console.log(this.state.voucher_code);
    //     this.props.verifyVoucher(this.props.customer.email, this.state.voucher_code);
    // }

    handleAdjustmentTypeSelect(type, e) {
        this.setState({ adjustment_type: type });
    }

    handleGenerateStatement(quote) {
        let html = window.Handlebars.templates.quotation(quote);
        // let strWindowFeatures = "menubar=yes,location=no,resizable=no,scrollbars=yes,status=no";
        // window.open(window.location.hostname + ':' + window.location.port + html, "_blank", strWindowFeatures);
        let stmt_wnd = window.open("", "_blank");
        stmt_wnd.document.write(html);
    }

    render() {
        const { cart, checkout, user } = this.props;
        const { shipping_fee, shipping_disc, total } = cart;
        const { delivery_option, customeroption, adjustment_type, adjustment_amount, customer, payment_terms, newConfig } = this.state;
        let totalAmount = (total + shipping_fee - shipping_disc) + parseFloat(adjustment_amount);
        totalAmount = parseFloat(totalAmount);
        console.log(totalAmount)

        // let discountAmount = this.calculateDiscount(checkout.voucher_discount, total);
        // totalAmount -= discountAmount;
        // if (totalAmount < 0) totalAmount = 0;

        return (
            <div>
                <Container fluid={false} className="my-3">
                    <h1 style={{ marginBottom: '1em' }}>Quotation</h1>
                    {!user && <Alert color="danger">Returning customer? Click <a href="#" style={{ color: 'red' }} onClick={this.handleLogin.bind(this)}>HERE</a> to login</Alert>}
                    <Popover placement='bottom' isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                        <PopoverBody>{this.state.popoverText}</PopoverBody>
                    </Popover>
                    <Form innerRef={(ref) => this.formRef = ref} onSubmit={this.validateAndSubmit.bind(this)}>
                        <Prompt
                            when={this.state.isBlocking && (this.props.cart.items.length > 0)}
                            message={'Your form will be clear and edited config data will be reset. Are you sure you want to abort checkout?'
                            }
                        />
                        <Alert color="warning" isOpen={this.state.balance_alert} toggle={this.closeBalanceAlert.bind(this)}>
                            Insufficient account balance. Follow instructions at <a href={window.location.protocol + '//' + window.location.hostname + '/how-to-top-up-account'} target="_blank">{window.location.protocol + '//' + window.location.hostname + '/how-to-top-up-account'}</a> to top-up.
                        </Alert>
                        <CardDeck>
                            <Card>
                                <CardHeader>Billing Details</CardHeader>
                                <CardBody>
                                    <FormGroup row>
                                        {/* <Col>
                                            <Select id="name" name="name" placeholder="NAME / COMPANY / EMAIL" options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
                                        </Col> */}
                                        <Col>
                                            <Label for="name" hidden>Name</Label>
                                            <Input type="text" id="name" name="name" placeholder="Name" value={customer ? customer.name : ''} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="company" hidden>Company</Label>
                                            <Input type="text" id="company" name="company" placeholder="Company" value={customer ? customer.company : ''} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="address_1" hidden>Address Line 1</Label>
                                            <Input type="text" id="address_1" name="address_1" placeholder="Address Line 1" value={customer ? customer.address.address_1 : ''} onChange={this.handleAddressInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="address_2" hidden>Address Line 2</Label>
                                            <Input type="text" id="address_2" name="address_2" placeholder="Address Line 2" value={customer ? customer.address.address_2 : ''} onChange={this.handleAddressInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="town_city" hidden>Town / City</Label>
                                            <Input type="text" id="town_city" name="town_city" placeholder="Town / City" value={customer ? customer.address.town_city : ''} onChange={this.handleAddressInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="postcode" hidden>Postal Code</Label>
                                            <MaskedInput
                                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                className="form-control"
                                                placeholder="Postal Code"
                                                placeholderChar="X"
                                                guide={true}
                                                id="postcode"
                                                name="postcode"
                                                value={customer ? customer.address.postcode : ''}
                                                onChange={this.handleAddressInput.bind(this)}
                                                onBlur={this.resetInputStyle.bind(this)}

                                            />
                                        </Col>
                                        <Col>
                                            <Label for="state" hidden>State</Label>
                                            <Input type="select" className="state_select" id="state" name="state" value={customer ? customer.address.state : ''} placeholder="State" onChange={this.handleAddressInput.bind(this)} onBlur={this.resetInputStyle.bind(this)}  >
                                                <option>State</option>
                                                {(checkout.shipping_rates.length > 0) &&
                                                    checkout.shipping_rates.find((m) => m._id === this.props.checkout.billing_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                                }
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="country" hidden>Country</Label>
                                            <Input type="text" id="country" name="country" placeholder="Country" value={customer ? customer.address.country : ''} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="mobile" hidden>Mobile Number</Label>
                                            <MaskedInput
                                                mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                guide={false}
                                                id="mobile"
                                                name="mobile"
                                                value={customer ? customer.mobile : ''}
                                                onChange={this.handleInput.bind(this)}
                                                onInput={this.showPhoneHelpText.bind(this)}
                                                onBlur={this.resetInputStyle.bind(this)}

                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="email" hidden>Email</Label>
                                            <Input type="email" id="email" name="email" placeholder="EMAIL ADDRESS" value={customer ? customer.email : ''} onChange={this.handleInput.bind(this)} onBlur={this.resetInputStyle.bind(this)} d />
                                        </Col>
                                    </FormGroup>
                                    {(!delivery_option.includes('SELF COLLECT')) &&
                                        <FormGroup row>
                                            <Col>
                                                <FormGroup check>
                                                    <Label className="checkboxClass" check>
                                                        <Input type="checkbox" name="diffShipAddr" onClick={() => this.setState({ shippingAddr: !this.state.shippingAddr })} />{' Ship to different address'}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    }
                                </CardBody>
                                {(!delivery_option.includes('SELF COLLECT')) &&
                                    <Collapse isOpen={this.state.shippingAddr}>
                                        <CardHeader className="inner-card-header">Shipping Details</CardHeader>
                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_name" hidden>First Name</Label>
                                                    <Input type="text" id="shipping_name" name="shipping_name" placeholder="Name" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_address_1" hidden>Address Line 1</Label>
                                                    <Input type="text" id="shipping_address_1" name="shipping_address_1" placeholder="Address Line 1" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_address_2" hidden>Address Line 2</Label>
                                                    <Input type="text" id="shipping_address_2" name="shipping_address_2" placeholder="Address Line 2" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_town_city" hidden>Town / City</Label>
                                                    <Input type="text" id="shipping_town_city" name="shipping_town_city" placeholder="Town / City" onBlur={this.resetInputStyle.bind(this)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_postcode" hidden>Postal Code</Label>
                                                    <MaskedInput
                                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        placeholder="Postal Code"
                                                        placeholderChar="X"
                                                        guide={true}
                                                        id="shipping_postcode"
                                                        name="shipping_postcode"
                                                        onBlur={this.resetInputStyle.bind(this)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label for="shipping_state" hidden>State</Label>
                                                    <Input type="select" className="state_select" id="shipping_state" name="shipping_state" value={this.state.shipping_state} placeholder="State" onChange={this.handleShipAddrChange.bind(this)} onBlur={this.resetInputStyle.bind(this)}>
                                                        <option>State</option>
                                                        {(checkout.shipping_rates.length > 0) &&
                                                            checkout.shipping_rates.find((m) => m._id === this.props.checkout.shipping_country).rates.map((n) => <option key={n.state}>{n.state}</option>)
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_country" hidden>Country</Label>
                                                    <Input type="text" id="shipping_country" name="shipping_country" placeholder="Country" defaultValue={this.props.checkout.shipping_country} d />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col>
                                                    <Label for="shipping_phone" hidden>Mobile Number</Label>
                                                    <MaskedInput
                                                        mask={[/0/, /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        placeholder="Mobile Number"
                                                        guide={false}
                                                        id="shipping_phone"
                                                        name="shipping_phone"
                                                        onInput={this.showPhoneHelpText.bind(this)}
                                                        onBlur={this.closePopover.bind(this)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Collapse>
                                }
                            </Card>
                            <Card>
                                <CardHeader>Quote Details</CardHeader>
                                <CardBody>
                                    <div className="order-details">
                                        {
                                            cart.items.map((item) => {
                                                if (item.title) {
                                                    return (
                                                        <Table className="table table-bordered table-sm table-striped table-wordwrap">
                                                            <tbody>
                                                                <tr><td style={{ width: '30%', textAlign: 'center' }}><b>Title</b></td><td style={{ width: '70%', textAlign: 'center', backgroundColor: 'white' }}>{item.title}</td></tr>
                                                            </tbody>
                                                        </Table>
                                                    )
                                                }
                                            })
                                        }
                                        <Table className="order-table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th className="currency">Total (RM)</th>
                                                </tr>
                                            </thead>

                                            {
                                                cart.items.map((item) => {
                                                    return (
                                                        <tbody>
                                                            <tr key={item.id}>
                                                                <td>{item.product} ({item.configurations.quantity} pcs)</td>
                                                                <td className="currency">{item.price.toFixed(2)}</td>
                                                            </tr>
                                                            <tr> <td> {this.generateItemConfigurationTable(item)} </td></tr>
                                                        </tbody>
                                                    )
                                                })

                                            }


                                            {/* <tr>
                                                    <td>Subtotal</td>
                                                    <td className="currency">{cart.total.toFixed(2)}</td>
                                                </tr> */}
                                            {/* {
                                                    (!checkout.voucher_discount || (checkout.voucher_discount.type === 'error')) ?
                                                        <tr>
                                                            <td colSpan='2'>
                                                                <InputGroup>
                                                                    <Input name="voucher_code" placeholder="VOUCHER CODE" value={this.state.voucher_code} style={{ textTransform: 'unset' }} onChange={this.handleInput.bind(this)} />
                                                                    <InputGroupAddon addonType="append"><Button color="secondary" onClick={this.handleApplyCode.bind(this)}>APPLY</Button></InputGroupAddon>
                                                                </InputGroup>
                                                            </td>
                                                        </tr> :
                                                        <tr>
                                                            <td>Voucher Discount ({(checkout.voucher_discount.type === 'cash') ? 'RM ' + checkout.voucher_discount.value.toFixed(2) : checkout.voucher_discount.value + '%'})</td>
                                                            <td className="currency">{(-discountAmount).toFixed(2)}</td>
                                                        </tr>
                                                } */}
                                            {/* <tr>
                                                    <td>Adjustment</td>
                                                    <td>
                                                        <InputGroup colSpan='2'>
                                                            <Input name="adjustment_value" id="adjustment_value" type="number" placeholder="ADJUSTMENT" value={this.state.adjustment_value} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentInput.bind(this)} />
                                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                                <DropdownToggle caret>
                                                                    {
                                                                        (adjustment_type === '%') ? '% ' : 'RM'
                                                                    }
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, '%')}>Percentage (%)</DropdownItem>
                                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, 'RM')} >Riggit Malaysia (RM)</DropdownItem>
                                                                </DropdownMenu>
                                                            </InputGroupButtonDropdown>
                                                        </InputGroup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> Adjusted Amount</td>
                                                    <td className="currency">{adjustment_amount.toFixed(2)}</td>
                                                </tr> */}
                                            {/* <tr>
                                                    <td>Shipping</td>
                                                    <td className="currency">{shipping_fee.toFixed(2)} </td>
                                                </tr> */}
                                            {/* {
                                                    (shipping_disc > 0) &&
                                                    <tr>
                                                        <td>Discount (Shipping)</td>
                                                        <td className="currency">-{shipping_disc.toFixed(2)} </td>
                                                    </tr>
                                                } */}


                                            <tfoot>
                                                <tr>
                                                    <td>TOTAL</td>
                                                    <td className="currency">RM {totalAmount.toFixed(2)}</td>
                                                </tr>
                                            </tfoot>

                                        </Table>
                                        {
                                            (checkout.voucher_discount && checkout.voucher_discount.type === 'error') &&
                                            <Alert color="danger">{checkout.voucher_discount.value}</Alert>
                                        }
                                        {/* {
                                            (totalAmount <= 0) &&
                                            <Alert color="danger">Adjusted Value Cannot Less Than 0</Alert>
                                        } */}
                                    </div>
                                    <FormGroup>
                                        <Label id="notes_label" for="notes">Notes (optional)</Label>
                                        <Input type="textarea" name="notes" id="notes" placeholder="eg. special delivery instruction" onChange={this.handleInput.bind(this)} />
                                    </FormGroup>
                                    {/* <button type="submit" className="button pay" >PLACE ORDER</button> */}
                                </CardBody>
                                <CardHeader className="inner-card-header">Payment Terms</CardHeader>
                                <CardBody>
                                    <FormGroup row >
                                        <Label for="payment_terms" sm={4} style={{ paddingRight: '0px' }}>Payment Terms :</Label>
                                        <Col sm={5}>
                                            <Input type="select" name="payment_terms" onChange={this.handlePaymentMethod.bind(this)} >
                                                {(checkout.payment_terms.values) && checkout.payment_terms.values.map((term) => {
                                                    return (<option value={term}>{term}</option>)
                                                }
                                                )}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="delivery_schedule" sm={4} style={{ paddingRight: '0px' }}>Delivery Schedule :</Label>
                                        <Col sm={5}>
                                            <Input type="select" name="delivery_schedule" onChange={this.handleDeliverySchedule.bind(this)} >
                                                {(checkout.delivery_schedule.values) && checkout.delivery_schedule.values.map((schedule) => {
                                                    return (<option value={schedule}>{schedule}</option>)
                                                }
                                                )}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <hr></hr>
                                    <Button type="submit" color="success" disabled={(this.props.cart.items.length === 0) || this.state.submitting || !user || (totalAmount <= 0)} 
                                        onClick={()=>{
                                            console.log('false');
                                            this.setState({
                                                print_quote: false
                                            });
                                            console.log(this.state.print_quote);
                                        }}>SUBMIT QUOTE</Button>
                                    <Button type="submit" color="info" disabled={(this.props.cart.items.length === 0) || this.state.submitting || !user || (totalAmount <= 0)}
                                        onClick={()=>{
                                            console.log('true');
                                            this.setState({
                                                print_quote: true
                                            });
                                            console.log(this.state.print_quote);
                                        }}>SUBMIT & PRINT QUOTE</Button>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Form>
                </Container>
            </div >
        )
    }

    generateItemConfigurationTable(item) {
        const { newConfig } = this.state;
        console.log(newConfig)

        return (
            <div>
                <table className="table table-bordered table-sm table-striped table-wordwrap">
                    <tbody>
                        {
                            Object.keys(newConfig).map(key => {
                                let value = newConfig[key];
                                console.log(key + ' : ' + value);

                                if ((key === 'stickers_per_sheet') || (key === 'orientation') || (key === 'brochures_per_sheet')) return;

                                if (key === 'size') {
                                    if (value.type) {
                                        value = `${value.type}`;
                                    } else if (value.width & value.height) {
                                        value = `${value.width}mm X ${value.height}mm`;
                                    }
                                }

                                if (key === 'other1' || key === 'other2' || key === 'other3') {
                                    if (value.othersSelected == true) {
                                        return (
                                            <tr key={key}>
                                                <td style={{ width: '30%' }}>{capitalizeKey(value.name)}</td>
                                                <td style={{ width: '70%' }}> <Input className="form-configurations" type="text" id={key} name={key} placeholder={value.value} value={value.value} onChange={this.handleInputConfigurations.bind(this)} /></td>
                                            </tr>
                                        )
                                    }
                                    else return;
                                }

                                if ((key === 'cover_lamination') || (key === 'inner_lamination') || (key === 'diecut') || (key === 'cover_spotuv') || (key == 'inner_spotuv') || (key === 'perforation') || (key === 'folding') || (key === 'diecut_mould') || (key === 'numbering') || (key === 'threadsewing') || (key === 'wire_o')
                                ) {
                                    let stringVal = value.toString();
                                    if (stringVal) {
                                        if (stringVal.includes('None')) return;
                                        else if (stringVal.includes('No')) return;
                                        else if (stringVal.includes('0')) return;
                                    }
                                }

                                if ((key === 'custom_creasing') || (key === 'creasing') || (key === 'creasing_remains') || (key === 'hot_stamping_colour_1') || (key === 'hot_stamping_colour_2') || (key === 'round_corner_type')) {
                                    if ((value === undefined) || (value === null)) return;
                                }

                                if (!key.includes('path') && !key.includes('weight')) {
                                    return (
                                        <tr key={key}>
                                            <td style={{ width: '30%' }}>{capitalizeKey(key)}</td>
                                            <td style={{ width: '70%' }}> <Input className="form-configurations" type="text" id={key} name={key} placeholder={value} value={value} onChange={this.handleInputConfigurations.bind(this)} /></td>
                                        </tr>
                                    )
                                }
                            })

                        }
                        <tr>
                            <td style={{ width: '30%' }}>Unit Price</td>
                            <td style={{ width: '70%' }}> RM {item.unit_price}  </td>
                        </tr>
                        <tr>
                            <td style={{ width: '30%' }}> Adjustment {(item.adjustment_type === '%') && '(' + item.adjustment_value.toFixed(2) + '%)'}</td>
                            <td style={{ width: '70%' }}>RM {item.adjustment_amount.toFixed(2)}  </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


const mapStateToProps = ({ cart, checkout, main, }) => {
    return { cart, checkout, customer: main.customer, loginModal: main.loginModal, user: main.user };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingInfo: fetchShippingInfo,
        calculateShipping: calculateShipping,
        resetShipping: resetShipping,
        emptyCart: emptyCart,
        registerLoginSuccessHandle: registerLoginSuccessHandle,
        showLoading: showLoading,
        showLoginModal: showLoginModal,
        saveOrder: saveOrder,
        verifyVoucher: verifyVoucher,
        getCustomers: getCustomers,
        selectCustomer: selectCustomer,
        getPaymentTerms: getPaymentTerms,
        getDeliverySchedule: getDeliverySchedule,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(Checkout));
