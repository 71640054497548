import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import {
    Container,
    Row,
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from 'reactstrap';
import { showLoginModal, registerLoginSuccessHandle, logoutStaff, showCustomerSelectionModal, selectCustomer } from '../../../actions';
import './UtechNavbar.css';
import logo_mobile from './utech_logo_mobile.png';
import CustomerSelectionModal from './CustomerSelectionModal';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

class UtechNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            navOpen: false,
            cartItemCount: 0,
            selectedcustomer: null,
        };
    }

    componentWillMount() {
        this.props.selectCustomer();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({ navOpen: false })
        }

        if (prevProps.selectedcustomer !== this.props.selectedcustomer) {
            if (this.props.selectedcustomer) {
                let newCustomer = this.props.selectedcustomer;
                // console.log(newCustomer.name)
                this.setState({ selectedcustomer: (newCustomer.name + " (" + newCustomer.company + ") " + newCustomer.email) });
            }
            else {
                this.setState({ selectedcustomer: null })
            }
        }

    }

    toggle() {
        this.setState({
            navOpen: !this.state.navOpen
        });
    }

    redirectAfterLogin(link) {
        window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + link;
    }

    showLogin(link) {
        if (link) this.props.registerLoginSuccessHandle(this.redirectAfterLogin.bind(this, link));
        this.props.showLoginModal(true);
        const alert = this.props.alert.show('Please login! ', {
            timeout: 3000,
            type: 'error'
        });
        this.props.history.push('/');
    }

    handleLogout = (evt) => {
        evt.preventDefault();
        this.props.firebaseApp.auth().signOut()
            .then(() => {
                this.props.logoutStaff();
                this.props.history.push('/');
            });
    }

    toggleCustomerSelectionModal = (evt) => {
        evt.preventDefault();

        this.props.showCustomerSelectionModal(!this.props.customerselectionModal);
        console.log(this.props)
    }


    render() {
        console.log(this.props)
        const { user, cart, customerselectionModal } = this.props;
        const { selectedcustomer } = this.state;

        return (
            <div className='navbar-bg'>
                <Container>
                    {customerselectionModal && <CustomerSelectionModal isOpen={customerselectionModal} />}
                    <Row>
                        <Navbar dark expand="md">
                            {/* <a href="/"> */}
                            <NavbarBrand style={{ color: 'white', fontSize: '1.5em' }} onClick={() => this.props.history.push('/')}><img src={logo_mobile} title="PERCETAKAN U-TECH SDN BHD" alt="PERCETAKAN U-TECH SDN BHD" style={{ height: '50px', width: 'auto' }} /><span style={{ fontSize: '1em', fontWeight: '600', verticalAlign: '-25%', color: '#555555' }}>SALESFORCE</span></NavbarBrand>
                            {/* </a> */}
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.navOpen} navbar>
                                <Nav className="m-auto" navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>PRODUCTS</DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                (user)
                                                    ?
                                                    <Fragment>
                                                        <Link to="/products/1 Colour Digital Booklet">
                                                            <DropdownItem key="1 Colour Digital Booklet">
                                                                1 Colour Digital Booklet
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/4 Colour Digital Booklet">
                                                            <DropdownItem key="4 Colour Digital Booklet">
                                                                4 Colour Digital Booklet
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/4 Colour And 1 Colour Digital Booklet">
                                                            <DropdownItem key="4 Colour And 1 Colour Digital Booklet ">
                                                                4 Colour And 1 Colour Digital Booklet
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/Digital Booklet Landscape">
                                                            <DropdownItem key="Digital Booklet Landscape">
                                                                Digital Booklet Landscape
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/Digital Business Card">
                                                            <DropdownItem key="Digital Business Card" >
                                                                Digital Business Card
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/Offset Business Card">
                                                            <DropdownItem key="Offset Business Card" >
                                                                Offset Business Card
                                                            </DropdownItem>
                                                        </Link>
                                                        {/* <Link to="/products/Flyer">
                                                            <DropdownItem key="Flyer" >
                                                                Flyer
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/Brochure">
                                                            <DropdownItem key="Brochure">
                                                                Brochure
                                                            </DropdownItem>
                                                        </Link> */}
                                                        <Link to="/products/Digital Brochure">
                                                            <DropdownItem key="Digital Brochure">
                                                                Digital Brochure
                                                            </DropdownItem>
                                                        </Link>
                                                        {/* <Link to="/products/Racing Horse Calendar With Header">
                                                            <DropdownItem key="Racing Horse Calendar With Header">
                                                                Racing Horse Calendar With Header
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/Racing Horse Calendar Without Header">
                                                            <DropdownItem key="Racing Horse Calendar Without Header">
                                                                Racing Horse Calendar Without Header
                                                            </DropdownItem>
                                                        </Link> */}
                                                        <Link to="/products/Sticker Label">
                                                            <DropdownItem key="Sticker Label">
                                                                Sticker Label
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/StickerSheet Label">
                                                            <DropdownItem key="StickerSheet Label">
                                                                Sticker Label (SHEET)
                                                            </DropdownItem>
                                                        </Link>
                                                        <Link to="/products/StickerRoll Label">
                                                            <DropdownItem key="StickerRoll Label">
                                                                Sticker Label (ROLL)
                                                            </DropdownItem>
                                                        </Link>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/1 Colour Digital Booklet')}>1 Colour Digital Booklet</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/4 Colour Digital Booklet')}>4 Colour Digital Booklet</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/4 Colour And 1 Colour Digital Booklet')}>4 Colour And 1 Colour Digital Booklet</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Digital Booklet Landscape')}>Digital Booklet Landscape</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Digital Business Card')}>Digital Business Card</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Offset Business Card')}>Offset Business Card</button>
                                                        {/* <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Flyer')}>Flyer</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Brochure')}>Brochure</button> */}
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Digital Brochure')}>Digital Brochure</button>
                                                        {/* <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Racing Horse Calendar With Header')}>Racing Horse Calendar With Header</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Racing Horse Calendar Without Header')}>Racing Horse Calendar Without Header</button> */}
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/Sticker Label')}>Sticker Label</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/StickerSheet Label')}>Sticker Label (SHEET)</button>
                                                        <button className="dropdown-item" onClick={this.showLogin.bind(this, '/products/StickerRoll Label')}>Sticker Label (ROLL)</button>
                                                    </Fragment>
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>PRODUCT GUIDE</DropdownToggle>
                                        <DropdownMenu>
                                            <Link to="/art-work-specification"><DropdownItem>Artwork Specification</DropdownItem></Link>
                                            <Link to="/product-size-template"><DropdownItem>Product Size Template</DropdownItem></Link>
                                            <Link to="/product-specifications"><DropdownItem>Product Specification</DropdownItem></Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {
                                        (user)
                                            ?
                                            <NavItem>
                                                <Link to="/quotations" className="nav-link">QUOTATIONS</Link>
                                            </NavItem>
                                            :
                                            <NavItem>
                                                <a onClick={this.showLogin.bind(this, '/quotations')} className="nav-link">QUOTATIONS</a>
                                            </NavItem>
                                    }
                                    {/* <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>CUSTOMER</DropdownToggle>
                                        <DropdownMenu>
                                            {selectedcustomer &&
                                                <DropdownItem onClick={this.toggleCustomerSelectionModal.bind(this)}><FontAwesomeIcon style={{ marginRight: '10px' }} icon={faUserEdit} />{selectedcustomer}</DropdownItem>
                                            }
                                            {!selectedcustomer &&
                                                <DropdownItem onClick={this.toggleCustomerSelectionModal.bind(this)}>Select Customer</DropdownItem>
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                    {(user) &&
                                        <Fragment>
                                            {/* <NavItem>
                                                <Link to="/cart" className="nav-link">CART - {cart.items.length} ITEM(S)</Link>
                                            </NavItem> */}
                                            <NavItem onClick={this.handleLogout.bind(this)}>
                                                <a href="#" className="nav-link">LOGOUT</a>
                                            </NavItem>
                                        </Fragment>
                                    }
                                    {(!user) &&
                                        <NavItem>
                                            <Link to="/" className="nav-link">LOGIN</Link>
                                        </NavItem>
                                    }

                                    {/* <NavItem className='navbar-cart'><Link to="/cart" className="nav-link">CART - {cart.items.length} ITEM(S)</Link></NavItem> */}
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({ main, product, cart }) => {
    const { customerselectionModal, selectedcustomer } = main;
    return { user: main.user, firebaseApp: main.firebaseApp, products: product.products, cart, customerselectionModal, selectedcustomer };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        showLoginModal: showLoginModal,
        registerLoginSuccessHandle: registerLoginSuccessHandle,
        logoutStaff: logoutStaff,
        showCustomerSelectionModal: showCustomerSelectionModal,
        selectCustomer: selectCustomer
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(UtechNavbar));
