import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalizeKey } from '../../../config';
import { Container, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { updateCart, emptyCart, calculateShipping, showCustomerSelectionModal } from '../../../actions';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faTrash, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './Cart.css';

class CartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmEmptyCart: false,
            selectedDeliveryOption: 'SELF COLLECT',
            grand_total: props.cart.total
        }
    }

    componentWillMount() {
        let total_weight = 0.0;
        this.props.cart.items.forEach(item => total_weight += item.configurations.weight);
        this.props.calculateShipping({ delivery_option: this.props.cart.delivery_option, country: 'MALAYSIA', weight: total_weight });

        this.setState({
            selectedDeliveryOption: this.props.cart.delivery_option
        });

    }

    componentDidUpdate(previousProps, previousState) {
        if ((previousState.selectedDeliveryOption !== this.state.selectedDeliveryOption) || (previousProps.cart.items !== this.props.cart.items) || (previousProps.cart.voucher_code !== this.props.cart.voucher_code)) {
            let total_weight = 0.0;
            this.props.cart.items.forEach(item => total_weight += item.configurations.weight);
            this.props.calculateShipping({ delivery_option: this.state.selectedDeliveryOption, country: 'MALAYSIA', weight: total_weight });
        } else if (previousProps.cart !== this.props.cart) {
            this.setState({ selectedDeliveryOption: this.props.cart.delivery_option, grand_total: this.props.cart.total + this.props.cart.shipping_fee });
        }

    }

    render() {
        const { cart } = this.props;
        console.log(cart);

        let isEmptyCart = cart.items.length === 0;
        return (
            <div>
                <Modal isOpen={this.state.confirmEmptyCart}>
                    <ModalHeader>Empty Product List</ModalHeader>
                    <ModalBody>Are you sure you want to clear your product list?</ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.confirmEmptyCart.bind(this, false)}>Cancel</Button>
                        <Button color="primary" onClick={this.confirmEmptyCart.bind(this, true)}>OK</Button>
                    </ModalFooter>
                </Modal>
                <Container className="my-3">
                    <h1 style={{ marginBottom: '1em' }}>PRODUCTS</h1>
                    <Row>
                        <Col className='col-sm-12 col-md-9 '>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: '15%' }}>Product</th>
                                        <th style={{ width: '65%' }}>Configuration</th>
                                        <th style={{ width: '15%' }}>Price</th>
                                        <th style={{ width: '5%', color: 'red' }}>{!isEmptyCart && <FontAwesomeIcon icon={faTrash} onClick={this.handleEmptyCart.bind(this)} />}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (isEmptyCart === false) && cart.items.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.product}</td>
                                                    <td>{this.generateItemConfigurationTable(item)}</td>
                                                    <td>{`RM ${item.price.toFixed(2)}`}</td>
                                                    <td style={{ color: 'red' }}><FontAwesomeIcon icon={faTimes} onClick={this.removeItemFromCart.bind(this, item.id)} /></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        (isEmptyCart === true) &&
                                        <tr>
                                            <td colSpan='4'>No item in the cart</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <small>Shipping cost is an estimate based on delivery option, final shipping cost will be calculated at checkout based on shipping address.</small>
                        </Col>
                        <Col className='col-sm-12 col-md-3'>
                            <h3 style={{ marginBottom: '1em' }}>Delivery</h3>
                            <Input type="select" name='shipping' id='shipping' value={this.state.selectedDeliveryOption} onChange={this.deliveryOptionChanged.bind(this)}>
                                <option value='SELF COLLECT'>Self Collect</option>
                                <option value='PULAU PINANG'>Courier - Pulau Pinang</option>
                                <option value='KEDAH & PERLIS'>Courier - Kedah & Perlis</option>
                                <option value='PENINSULAR MALAYSIA'>Courier - Peninsular Malaysia (Other)</option>
                                <option value='SABAH'>Courier - Sabah</option>
                                <option value='SARAWAK'>Courier - Sarawak</option>
                            </Input>
                            <h3 style={{ margin: '1em 0em' }}>Summary</h3>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td style={{ textAlign: 'right' }}>{`RM ${cart.total.toFixed(2)}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Shipping <small>(estimated)</small></td>
                                        <td style={{ textAlign: 'right' }}>{`RM ${cart.shipping_fee.toFixed(2)}`}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bolder' }}>Total</td>
                                        <td style={{ fontWeight: 'bolder', textAlign: 'right' }}>{`RM ${this.state.grand_total.toFixed(2)}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button onClick={() => this.props.history.push('/')} style={{ marginTop: '2em', background: 'grey', color: 'white', width: '100%', fontWeight: 'bold' }}>CONTINUE</Button>{' '}<Button onClick={this.checkout.bind(this)} style={{ marginTop: '2em', background: 'green', color: 'white', width: '100%', fontWeight: 'bold' }} disabled={isEmptyCart}>NEXT</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }


    /**
     * Remove selected item from cart
     *
     * @param {*} e Syntatic event
     * @param {String} cartItemIdToRemove cart item id
     **/
    removeItemFromCart(cartItemIdToRemove) {
        let newCartItems = this.props.cart.items.filter(x => x.id !== cartItemIdToRemove);
        let newCart = { ...this.props.cart, items: newCartItems, total: 0.0 };
        newCart.items.forEach((n) => {
            newCart.total += n.price;
        });
        // newCart.shipping_fee = this.state.shipping;
        // newCart.total_amount = this.state.grand_total;

        this.props.updateCart(newCart);
    }

    /**
     * Display empty cart confirmation dialog
     *
     */
    handleEmptyCart() {
        this.setState({ confirmEmptyCart: true });
    }

    /**
     * Confirmed empty cart
     *
* @param {bool} confirm confirmation status
* @param {e} Syntatic event
    */
    confirmEmptyCart(confirm, e) {
        if (confirm) {
            this.props.emptyCart();
        }
        this.setState({ confirmEmptyCart: !this.state.confirmEmptyCart });
    }

    /**
     * Check out cart to payment
     */
    checkout() {
        if (!this.props.selectedcustomer) {
            this.props.showCustomerSelectionModal(true);
        } else {
            this.props.history.push('/checkout');
        }
    }

    /**
     * Generate item's configuration table to be placed inside the cart table
     *
* @param {Object} item Product item in the cart system
    */
    generateItemConfigurationTable(item) {
        return (
            <div>
                <table className="table table-bordered table-sm table-striped table-wordwrap">
                    <tbody>
                        {
                            Object.keys(item.configurations).map(key => {
                                let value = item.configurations[key];
                                console.log(key + ' : ' + value);

                                if ((key === 'stickers_per_sheet') || (key === 'orientation') || (key === 'brochures_per_sheet')) return;

                                if (key === 'size') {
                                    if (value.type) {
                                        value = `${value.type}`;
                                    } else {
                                        value = `${value.width}mm X ${value.height}mm`;
                                    }
                                }

                                if (key === 'width') {
                                    value = `${value}mm`;
                                }

                                if (key === 'height') {
                                    value = `${value}mm`;
                                }


                                if ((key === 'lamination') || (key === 'diecut') || (key === 'spotuv') || (key === 'perforation') || (key === 'folding') || (key === 'diecut_mould') || (key === 'numbering')) {
                                    let stringVal = value.toString();
                                    if (stringVal) {
                                        if (stringVal.includes('None')) return;
                                        else if (stringVal.includes('No')) return;
                                        else if (stringVal.includes('0')) return;
                                    }
                                }
                                // if (key === 'diecut') { if (value.includes('None')) return; }

                                if (!key.includes('path') && !key.includes('weight')) {
                                    return (
                                        <tr key={key}>
                                            <td style={{ width: '30%' }}>{capitalizeKey(key)}</td>
                                            <td style={{ width: '70%' }}>{value}</td>
                                        </tr>
                                    )
                                }

                            })
                        }
                    </tbody>
                </table>
                <table className="table table-bordered table-sm table-striped table-wordwrap">
                    <tbody>{
                        Object.keys(item).map(key => {
                            console.log(item.original_price)
                            if (key === 'original_price') {
                                return (
                                    <tr key={key}>
                                        <td style={{ width: '30%' }}>Original Price:</td>
                                        <td style={{ width: '70%' }}>RM{item.original_price}</td>
                                    </tr>
                                )
                            }
                            else if (key === 'adjustment_type') {
                                return (

                                    <tr>
                                        <td style={{ width: '30%' }}>Adjustment :</td>
                                        {item.adjustment_type === '%' ?

                                            <td style={{ width: '70%' }}>{item.adjustment_value}{item.adjustment_type}</td>

                                            :

                                            <td style={{ width: '70%' }}>RM{item.adjustment_value}</td>

                                        }
                                    </tr>

                                )
                            }
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }

    /**
     * Handle delivery options changed event
     *
* @param {*} e Synthetic event
        */
    deliveryOptionChanged(e) {
        let inputValue = e.target.value;
        this.setState({ selectedDeliveryOption: inputValue });
    }

    /**
     * Get pricing data based on the cart items & delivery options
     */
    getPricing() {
        let data = { ...this.state }
        // TODO: Send data to server to get the different price
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
* @param {*} e eventData
    */
    UNUSED_handleInputChange(e) {
        let inputValue = null;

        if (e.target.type === 'checkbox') {
            // Use checked value instead of normal value
            inputValue = e.target.checked;
        } else {
            // everything else just let it slide
            inputValue = e.target.value;
        }
    }
}

const mapStateToProps = ({ cart, main }) => {
    return { cart, selectedcustomer: main.selectedcustomer };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateCart: updateCart,
        emptyCart: emptyCart,
        calculateShipping: calculateShipping,
        showCustomerSelectionModal: showCustomerSelectionModal
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CartPage);
