import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import Select from 'react-select';
import _ from 'lodash';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getProductSetup, getProductTimeAndCost, resetProductTimeAndCost, uploadFiles, resetUploads, addToCart, showCustomerSelectionModal, getCustomers, selectCustomer, emptyCart, emptyQuote, matchQuoteConfig } from '../../../actions'
import './ProductPage.css';
import SizeFormGroup from './Components/SizeFormGroup';
import ManualInputFormGroup from './Components/ManualInputFormGroup';
import GenericDropdownsFormGroup from './Components/GenericDropdownsFormGroup';
import TimeAndCostFormGroup from './Components/TimeAndCostFormGroup';
import { productSpecUrl, productPriceUrl } from "./Utility";
import Specification from './Components/Specifications';
import LoadingIndicator from './Components/LoadingIndicator';

// Dimension constants defined in MM
const min_sticker_width = 25;
const max_sticker_width = 297;
const min_sticker_height = 25;
const max_sticker_height = 420;

const paper_width = 330;
const paper_height = 1000;
const margin = 10;
//? const gap_between = 3;
//? const available_width = paper_width - (2 * margin);
const available_width = 320;
//Changed to continuous printing
//? const available_height = paper_height - (2 * margin);

class StickerSheet extends Component {
    constructor(props) {
        super(props);

        this.product = 'Sticker Sheet Label';
        this.state = {
            stickers_per_row: 0,
            num_of_rows: 0,
            paper_length_m: 0,
            num_sheets: 0,
            /**
             * Dynamic options for certain configuration
             */
            dynamic_options: {
            },
            /**
             * Current configuration selected by end-user
             */
            configurations: {
                gap_between: 3,
                column: 'auto',
                paper_length: 0,
                selected_orientation: 'auto',
                width: 0,
                height: 0,
                quantity: 0,
                stickers_per_sheet: 0,
                orientation: '',
                print: null,
                material: null,
                plotting: null,
                lamination: null,
                weight: 0.0,
                other1: { othersSelected: false, name: 'Other1', value: '', price: 0.0 },
                other2: { othersSelected: false, name: 'Other2', value: '', price: 0.0 },
                other3: { othersSelected: false, name: 'Other3', value: '', price: 0.0 },
            },
            /**
             * Time and cost looked up based on configurations
             */
            price_and_duration: {
                original_price: 0.0,
                price: 0.0,
                duration: 0
            },
            isLoadingData: true,
            invalidWidth: false,
            invalidHeight: false,
            adjustment_value: 0.00,
            adjustment_amount: 0.00,
            adjustment_type: "%",
            adj_printing_price: 0.0,
            sys_printing_price: 0.0,
            adjustPrintingSwitch: false,
            customeroption: [],
            selectedCustomer: null,
            unit_price: 0.0,
            popoverOpen: false,
            popoverTarget: 'name',
            popoverText: '',
            customerName: null,
            customer_email_validation: null,
            matchQuoteConfig: false,
        };
        this.customerRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.props.getProductSetup(this.product);
        this.handleOrientationChange = this.handleOrientationChange.bind(this);
    }

    /**
     * Invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        document.title = `U-Tech - ${this.product}`
        this.props.getCustomers();
        // this.props.getProductSetup(this.product);
    }

    /**
     * Invoked immediately after updating occurs.
     * This method is not called for the initial render.
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current props to previous props (e.g. a network request may not be necessary if the props have not changed).
     *
     * @param {*} previousProps Previous properties before this completed rendering cycle
     * @param {*} previousState Previous state before this already completed rendering cycle
     */
    componentDidUpdate(previousProps, previousState) {
        if (this.props.checkout.list && (this.props.checkout.list !== previousProps.checkout.list)) {
            let customeroption = this.props.checkout.list.map((obj, index) => {
                let newObj = {}; newObj.label = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.value = obj.name.toUpperCase() + " (" + obj.email + ") - " + obj.company;
                newObj.email = obj.email; newObj.index = index;
                return newObj;
            });

            console.log(customeroption)
            this.setState({ data: _.chunk(this.props.checkout.list), customeroption: _.sortBy(customeroption, ['label']) });
        }

        if ((previousProps.product.setup !== this.props.product.setup) && this.props.product.setup.options) {
            const { selectedQuoteProduct } = this.props.quote; // Product From Existed Quote
            let selectedProduct = this.props.cart.items.find((el) => el.product === 'Sticker Label');
            console.log(selectedProduct)

            if (selectedQuoteProduct && (selectedQuoteProduct.product === 'Sticker Label')) {
                this.props.emptyCart(); // To Replace Current Cart Item
                let newConfigurations = selectedQuoteProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedQuoteProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedQuoteProduct.customer,
                    title: selectedQuoteProduct.title,
                    adjustment_amount: selectedQuoteProduct.adjustment_amount,
                    adjustment_type: selectedQuoteProduct.adjustment_type,
                    adjustment_value: selectedQuoteProduct.adjustment_value,
                    adjustPrintingSwitch: selectedQuoteProduct.adjustPrintingSwitch,
                    adj_cover_print_price: selectedQuoteProduct.adj_cover_print_price,
                    adj_inner_print_price: selectedQuoteProduct.adj_inner_print_price,
                    adj_printing_price: selectedQuoteProduct.adj_printing_price,
                    customerName: selectedQuoteProduct.customer.name,
                    customer_email_validation: selectedQuoteProduct.customer.email,
                });
                // Fetch Cart Data and Apply It
            } else if (selectedProduct) {
                let newConfigurations = selectedProduct.configurations;
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: selectedProduct.configurations,
                    isLoadingData: false,
                    selectedCustomer: selectedProduct.customer,
                    title: selectedProduct.title,
                    adjustment_amount: selectedProduct.adjustment_amount,
                    adjustment_type: selectedProduct.adjustment_type,
                    adjustment_value: selectedProduct.adjustment_value,
                    adjustPrintingSwitch: selectedProduct.adjustPrintingSwitch,
                    adj_printing_price: selectedProduct.adj_printing_price,
                    customerName: selectedProduct.customer.name,
                    customer_email_validation: selectedProduct.customer.email,
                });
            }
            else {
                let newConfigurations = this.getDefaultConfigurations(this.props.product.setup.options);
                let newDynamicOptions = this.getDynamicOptions(newConfigurations, this.props.product.setup.options);
                newConfigurations = this.getDefaultConfigurations({ ...this.props.product.setup.options, ...newDynamicOptions });
                console.log(newConfigurations);

                this.setState({
                    dynamic_options: { ...this.props.product.setup.options, ...newDynamicOptions },
                    configurations: newConfigurations,
                    isLoadingData: false
                });
            }
        }

        if ((previousState.configurations !== this.state.configurations) || (previousState.adj_printing_price !== this.state.adj_printing_price) || (previousState.adjustPrintingSwitch !== this.state.adjustPrintingSwitch) || (this.state.selectedCustomer !== previousState.selectedCustomer)) {
            let newConfig = this.state.configurations;
            let newPriceAndDuration = this.state.price_and_duration;
            let totalWeight = NaN;

            // Make sure configuration is valid
            if ((newConfig.width.length > 0) && (newConfig.height.length > 0)) {
                // Get the correct options based on the latest configurations
                let newDynamicOptions = this.getDynamicOptions(newConfig, this.props.product.setup.options);
                let sticker_width = parseFloat(newConfig.width);
                let sticker_height = parseFloat(newConfig.height);
                let gap_between = parseFloat(newConfig.gap_between);

                // Price calculation
                if ((this.props.product.setup)
                    && (sticker_width >= min_sticker_width) && (sticker_width <= max_sticker_width)
                    && (sticker_height >= min_sticker_height) && (sticker_height <= max_sticker_height)
                    && (newConfig.quantity > 0)
                    && (newConfig.gap_between >= 0)
                ) {
                    let pricing = this.props.product.setup.variants[0];
                    console.log(pricing);
                    console.log(this.state.configurations);

                    console.log('selected orientation state: ' + this.state.configurations.selected_orientation);
                    console.log('gap between: ' + this.state.configurations.gap_between);

                    // condition for set mode
                    if(this.state.configurations.selected_orientation === 'portrait'){
                        let stickers_per_row = Math.floor((available_width) / (sticker_width + gap_between));
                        let num_of_rows = Math.ceil((newConfig.quantity) / (stickers_per_row));
                        let length_stickers_portrait = ((sticker_height + gap_between) * num_of_rows) - gap_between;
                        newConfig.paper_length = length_stickers_portrait;
                        newConfig.orientation = 'Portrait';
                        this.setState({
                            stickers_per_row: stickers_per_row,
                            num_of_rows: num_of_rows
                        });
                        console.log('Sticker per row = '+ stickers_per_row
                            + '\nNum of rows = ' + num_of_rows
                            + '\nPaper length for stickers in portrait = ' + length_stickers_portrait);
                        /* let stickers_per_row = Math.floor((available_width) / (sticker_width + gap_between));
                        let num_of_rows = Math.floor((available_height) / (sticker_height + gap_between));
                        let num_stickers_portrait = stickers_per_row * num_of_rows;
                        newConfig.stickers_per_sheet = num_stickers_portrait;
                        newConfig.orientation = 'Portrait'; */
                    }else if(this.state.configurations.selected_orientation === 'landscape'){
                        let stickers_per_row = Math.floor((available_width) / (sticker_height + gap_between));
                        let num_of_rows = Math.ceil((newConfig.quantity) / (stickers_per_row));
                        let length_stickers_landscape = ((sticker_width + gap_between) * num_of_rows) - gap_between;
                        newConfig.paper_length = length_stickers_landscape;
                        newConfig.orientation = 'Landscape';
                        this.setState({
                            stickers_per_row: stickers_per_row,
                            num_of_rows: num_of_rows
                        });
                        console.log('Sticker per row = '+ stickers_per_row
                            + '\nNum of rows = ' + num_of_rows
                            + '\nPaper length for stickers in landscape = ' + length_stickers_landscape);
                        /* let stickers_per_row = Math.floor((available_width) / (sticker_height + gap_between));
                        let num_of_rows = Math.floor((available_height) / (sticker_width + gap_between));
                        let num_stickers_landscape = stickers_per_row * num_of_rows;
                        console.log('Num stickers in landscape = ' + num_stickers_landscape);
                        newConfig.stickers_per_sheet = num_stickers_landscape;
                        newConfig.orientation = 'Landscape'; */
                    }else{
                        // Calculating stickers in portrait
                        let stickers_per_row_p = Math.floor((available_width) / (sticker_width + gap_between));
                        let num_of_rows_p = Math.ceil((newConfig.quantity) / (stickers_per_row_p));
                        let length_stickers_portrait = ((sticker_height + gap_between) * num_of_rows_p) - gap_between;
                        console.log('Sticker per row = '+ stickers_per_row_p
                            + '\nNum of rows = ' + num_of_rows_p
                            + '\nPaper length for stickers in portrait = ' + length_stickers_portrait);

                        // Calculating stickers in landscape
                        let stickers_per_row_l = Math.floor((available_width) / (sticker_height + gap_between));
                        let num_of_rows_l = Math.ceil((newConfig.quantity) / (stickers_per_row_l));
                        let length_stickers_landscape = ((sticker_width + gap_between) * num_of_rows_l) - gap_between;
                        console.log('Sticker per row = '+ stickers_per_row_l
                            + '\nNum of rows = ' + num_of_rows_l
                            + '\nPaper length for stickers in landscape = ' + length_stickers_landscape);
                        
                        let sticker_per_row;
                        let num_of_rows;

                        if (length_stickers_portrait < length_stickers_landscape) {
                            sticker_per_row = stickers_per_row_p;
                            num_of_rows = num_of_rows_p;
                            newConfig.paper_length = length_stickers_portrait;
                            newConfig.orientation = 'Portrait';
                        } else {
                            sticker_per_row = stickers_per_row_l;
                            num_of_rows = num_of_rows_l;
                            newConfig.paper_length = length_stickers_landscape;
                            newConfig.orientation = 'Landscape';
                        }
                        this.setState({
                            stickers_per_row: sticker_per_row,
                            num_of_rows: num_of_rows
                        });
                        /* // Calculating total stickers per sheet in portrait
                        let stickers_per_row = Math.floor((available_width) / (sticker_width + gap_between));
                        let num_of_rows = Math.floor((available_height) / (sticker_height + gap_between));
                        let num_stickers_portrait = stickers_per_row * num_of_rows;
                        console.log('Num stickers in portrait = ' + num_stickers_portrait);

                        // Calculating total stickers per sheet in landscape
                        stickers_per_row = Math.floor((available_width) / (sticker_height + gap_between));
                        num_of_rows = Math.floor((available_height) / (sticker_width + gap_between));
                        let num_stickers_landscape = stickers_per_row * num_of_rows;
                        console.log('Num stickers in landscape = ' + num_stickers_landscape);

                        if (num_stickers_landscape > num_stickers_portrait) {
                            newConfig.stickers_per_sheet = num_stickers_landscape;
                            newConfig.orientation = 'Landscape';
                        } else {
                            newConfig.stickers_per_sheet = num_stickers_portrait;
                            newConfig.orientation = 'Portrait';
                        } */
                    }

                    let total_length = newConfig.paper_length + (2 * margin);
                    // Calculate number of sheets to be used
                    let num_sheets = Math.ceil(total_length / paper_height);
                    let lengthConvert = total_length /1000;
                    
                    // Calculate material cost
                    let material_cost = NaN;

                    let material = pricing.material.prices.find((item) => item.paper_type === newConfig.material);
                    console.log(material);
                    if (material) {
                        num_sheets += 15;   // Adding 15M wastage to the number of sheets
                        material_cost = parseFloat(Math.max(num_sheets * material.unit_price, material.min_price).toFixed(2));
                    }

                    this.setState({
                        num_sheets: num_sheets,
                        paper_length_m: lengthConvert
                    });
                    console.log('Orientation: ' + newConfig.orientation 
                        + '\nPaper length: ' + total_length
                        + '\nNum of sheets to be used: ' + num_sheets);
                    console.log(this.state.num_sheets);
                    /* let num_sheets = Math.ceil(newConfig.quantity / newConfig.stickers_per_sheet);
                    this.setState({
                        num_sheets: num_sheets
                    });
                    console.log('Orientation: ' + newConfig.orientation + ' Num of sheets: ' + num_sheets);
                    console.log(this.state.num_sheets);

                    // Calculate material cost
                    let material_cost = NaN;

                    let material = pricing.material.prices.find((item) => item.paper_type === newConfig.material);
                    console.log(material);
                    if (material) {
                        num_sheets += 5;    // Adding 5pcs wastage to the number of sheets
                        material_cost = parseFloat(Math.max(num_sheets * material.unit_price, material.min_price).toFixed(2));
                    } */

                    console.log('Num sheets = ' + num_sheets);
                    console.log('Material cost = ' + material_cost);

                    let unit_printing_price = null;
                    let print_price_entry = null;
                    let print_colours = newConfig.print.toUpperCase().includes('4C') ? 4 : 1;
                    unit_printing_price = pricing.printing.unit_price;

                    console.log(unit_printing_price)

                    // Selecting printing rate
                    // if ((this.state.selectedCustomer) && (this.state.selectedCustomer.meter_click) && (this.state.selectedCustomer.meter_click.enable)) {
                    //     // Customer profile meter click rate
                    //     console.log(this.state.selectedCustomer.meter_click);
                    //     print_price_entry = this.state.selectedCustomer.meter_click.printing.find((el) => ((el.material.toUpperCase() === 'PAPER') && (el.type.includes(print_colours))));
                    //     console.log(print_price_entry)
                    //     unit_printing_price = print_price_entry.unit_price;
                    // }

                    this.setState({ sys_printing_price: unit_printing_price });

                    // When printing price override is enable
                    if (this.state.adjustPrintingSwitch === true) {
                        unit_printing_price = this.state.adj_printing_price;
                    } else {
                        this.setState({ adj_printing_price: unit_printing_price });
                    }

                    // Calculate printing cost
                    let printing_cost = NaN;
                    if (pricing.printing) {
                        printing_cost = parseFloat(Math.max(num_sheets * unit_printing_price, pricing.printing.min_price).toFixed(2));
                    }
                    console.log(num_sheets)
                    console.log('Updated unit printing price = ' + unit_printing_price);
                    console.log('Min printing price = ' + pricing.printing.min_price);
                    console.log('Printing cost = ' + printing_cost);

                    let plotting_cost = NaN;
                    if (this.state.configurations.plotting === "Yes") {
                        // Calculate plotting cost

                        let unit_plotting_price = NaN;
                        pricing.plotting.prices.forEach((item) => {
                            if (num_sheets >= item.quantity_from) unit_plotting_price = item.unit_price;
                        });
                        plotting_cost = parseFloat((num_sheets * unit_plotting_price).toFixed(2));
                        console.log('Plotting cost = ' + plotting_cost);
                    }
                    else {
                        plotting_cost = 0.00;
                        console.log('Plotting cost = ' + plotting_cost);
                    }

                    // Calculate lamination price
                    let lamination_price = NaN;

                    if (newConfig.lamination.toUpperCase().includes('YES')) {
                        lamination_price = ((num_sheets - 35) * 12.5 * 18.5 * pricing.lamination.price_per_sq_inch) + pricing.lamination.one_sided_min_price;
                        if (lamination_price < pricing.lamination.one_sided_min_price) {
                            lamination_price = pricing.lamination.one_sided_min_price;
                        }
                    // } else if (newConfig.lamination.toUpperCase().includes('BOTH')) {
                    //     lamination_price = ((num_sheets - 40) * 12.5 * 18.5 * pricing.lamination.price_per_sq_inch * 2) + pricing.lamination.two_sided_min_price;
                    //     if (lamination_price < pricing.lamination.two_sided_min_price) {
                    //         lamination_price = pricing.lamination.two_sided_min_price;
                    //     }
                    } else {
                        lamination_price = 0.0;
                    }

                    console.log('Lamination price = ' + lamination_price);

                    let total_price = parseFloat((material_cost + printing_cost + plotting_cost + lamination_price).toFixed(2));
                    console.log('Total = ' + total_price);

                    newPriceAndDuration.original_price = total_price + parseFloat(this.state.configurations.other1.price) + parseFloat(this.state.configurations.other2.price) + parseFloat(this.state.configurations.other3.price);
                    newPriceAndDuration.price = total_price + this.state.adjustment_amount;
                    newPriceAndDuration.duration = pricing.misc.duration;

                    // Calculate weight for shipping fee
                    totalWeight = 50 * num_sheets;      // weight 50g per pcs
                    newConfig.weight = totalWeight / 1000;
                    console.log('Total weight (g) = ' + totalWeight);
                }
                if (this.props.quote.selectedQuoteProduct) {
                    let checkMaterial = this.props.product.setup.options.material.values.some((el) => el === newConfig.material);
                    let checkPrint = this.props.product.setup.options.print.values.some((el) => el === newConfig.print);
                    let checkPlotting = this.props.product.setup.options.plotting.values.some((el) => el === newConfig.plotting);
                    if (checkMaterial && checkPrint && checkPlotting) {
                        console.log('All Config Match')
                    } else {
                        // Show Match Error Modal
                        this.setState({ matchQuoteConfig: true })
                    }
                }

                this.setState({
                    dynamic_options: newDynamicOptions,
                    price_and_duration: newPriceAndDuration,
                    configurations: newConfig
                });
            }
        }

        if ((this.state.adjustment_type !== previousState.adjustment_type) || (this.state.adjustment_value !== previousState.adjustment_value) || (this.state.configurations !== previousState.configurations) || (this.state.selectedCustomer !== previousState.selectedCustomer) || (previousState.adj_printing_price !== this.state.adj_printing_price) || (previousState.adjustPrintingSwitch !== this.state.adjustPrintingSwitch)) {
            let final_price = this.state.price_and_duration;
            let quantity = parseInt(this.state.configurations.quantity);

            if ((this.state.adjustment_value.toString().length > 0) && !isNaN(this.state.adjustment_value)) {
                console.log(this.state.adjustment_amount)
                this.state.adjustment_amount = (this.state.adjustment_type === '%') ? (this.state.adjustment_value * final_price.original_price / 100) : parseFloat(this.state.adjustment_value);
                final_price.price = this.state.adjustment_amount + final_price.original_price;
                let unit_price = parseFloat((final_price.price) / quantity).toFixed(4);
                this.setState({ adjustment_amount: this.state.adjustment_amount, price_and_duration: final_price, adjustment_value: parseFloat(this.state.adjustment_value), unit_price });
            }
        }
    }

    /**
     * Get latest dynamic options based on the provided configurations
     *
     * @param {Object} productConfigurations Configurations
     * @param {Object} productOptions Product options. If not supplied, component's props.product.setup.options will be used.
     * @param {Object} productVariants Product variants. If not supplied, component's props.product.setup.variants will be used.
     *
     * @returns {Object} New dynamic options. Return existing state's dynamic options if no static product data is available.
     */
    getDynamicOptions(productConfigurations, productOptions = this.props.product.setup.options, productVariants = this.props.product.setup.variants) {
        // stop if we do not have the data
        if (productOptions === undefined) {
            console.log("Skip update dynamic options due to no data")
            return this.state.dynamic_options;
        }

        let newDynamicOptions = { ...this.state.dynamic_options };

        // Populate material list
        let selectedVariant = productVariants.find((variant) => {
            return ((variant.name === productConfigurations.name));
        });

        console.log(selectedVariant);

        let dynMaterialOptions = (selectedVariant) ? selectedVariant.material.prices.map((item) => item.paper_type) : [];

        newDynamicOptions.material = productOptions.material;
        newDynamicOptions.material.values = dynMaterialOptions;

        return newDynamicOptions;
    }

    /**
     * Handle the input change event.
     * Configuration & options change resulted by the event will be performed here.
     *
     * @param {*} e eventData
     */
    handleInputChange(e) {
        let futureConfigs = { ...this.state.configurations };

        // everything just let it slide
        futureConfigs[e.target.name] = e.target.value;

        console.log(e.target.value);

        if (e.target.name === 'width') {
            if (e.target.value < min_sticker_width) {
                this.setState({ invalidWidth: true, errorText: 'Minimum is 25mm' });
            }
            else if (e.target.value > max_sticker_width) {
                this.setState({ invalidWidth: true, errorText: 'Maximum is 297mm' });
            } else {
                this.setState({ invalidWidth: false });
            }
        }

        if (e.target.name === 'height') {
            if (e.target.value < min_sticker_height) {
                this.setState({ invalidHeight: true, errorText: 'Minimum is 25mm' });
            }
            else if (e.target.value > max_sticker_height) {
                this.setState({ invalidHeight: true, errorText: 'Maximum is 420mm' });
            } else {
                this.setState({ invalidHeight: false });
            }
        }

        let futureDynOptions = this.getDynamicOptions(futureConfigs, this.props.product.setup.options);

        this.setState({
            dynamic_options: futureDynOptions,
            configurations: futureConfigs
        });
    }

    getDefaultConfigurations(productOptions) {
        let newConfigurations = { ...this.state.configurations }
        const stateConfigKeys = Object.keys(this.state.configurations);
        const optionKeys = Object.keys(productOptions);

        stateConfigKeys.forEach(name => {
            if (optionKeys.includes(name)) {
                let defaultValue = null;

                if (['width'].includes(name)) {
                    defaultValue = '';
                }
                else if (['height'].includes(name)) {
                    defaultValue = '';
                }
                else if (['quantity'].includes(name)) {
                    defaultValue = 1;
                }
                else {
                    // Use first value as default
                    defaultValue = productOptions[name].values[0];
                }

                newConfigurations[name] = defaultValue;
            }
        });

        return newConfigurations
    }
    /**
     * Generate DOM elements based on product options.
     *
     * @param {string} configName Name of the configuration
     * @param {object} configSettings Configuration data
     */
    generateProductOptionElement(configName, configSettings) {
        const { options } = this.props.product.setup;
        const { configurations, dynamic_options, invalidWidth, invalidHeight, errorText } = this.state;
        // console.log(configurations);
        // console.log(configSettings);

        switch (configName) {

            case 'width':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label + '(mm)'}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Min Width: 25mm / Max Width 297 mm' }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalidWidth}
                        errorText={errorText}
                    />
                )
            case 'height':
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label + '(mm)'}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Min Height: 25mm / Max Height 420 mm' }}
                        onChange={this.handleInputChange.bind(this)}
                        invalid={invalidHeight}
                        errorText={errorText}
                    />
                )
            case 'material':
                // DYNAMIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={dynamic_options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'print':
            case 'plotting':
            case 'lamination':
                // STATIC dropdown options
                return (
                    <GenericDropdownsFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        onChange={this.handleInputChange.bind(this)}
                        itemsSource={options[configName].values}
                        selectedItem={configurations[configName]}
                    />
                )

            case 'quantity':
                // Manual Input
                return (
                    <ManualInputFormGroup
                        key={configName}
                        name={configName}
                        label={configSettings.label}
                        value={configurations[configName]}
                        inputAttributes={{ type: 'number', min: 1, placeholder: 'Quantity' }}
                        onChange={this.handleInputChange.bind(this)}
                    />
                )
            default:
                break;
        }
    }


    /**
     * Check if the required configurations is supplied.
     *
     * @returns True if all required configurations is supplied. False otherwise.
     */
    checkRequiredConfigurations() {
        let missingConfigurations = [];

        // Add checks for required configuration here
        if (!this.state.configurations['width']) {
            missingConfigurations.push("Width");
        }

        if (!this.state.configurations['height']) {
            missingConfigurations.push("Height");
        }

        console.log(missingConfigurations);
        if (missingConfigurations.length > 0) {
            window.alert(`Please supply the missing information: ${missingConfigurations.join(", ")}.`);
            return false;
        }

        // if ((this.state.price_and_duration.price <= 0) || (this.state.price_and_duration.duration <= 0)) {
        //     window.alert(`Invalid product configurations.`);
        //     return false;
        // }

        // everything OK
        return true;
    }

    addToCart() {

        // if (!this.props.main.selectedcustomer) {
        //     this.props.showCustomerSelectionModal(true);
        //     return;
        // }

        if (!this.state.selectedCustomer) {
            this.customerRef.current.focus();
            this.setState({
                popoverText: 'Customer Name is required!',
                popoverTarget: 'name',
                popoverOpen: true
            });
            return;
        }


        if (this.checkRequiredConfigurations() === false) {
            return;
        }

        let config = { ...this.state.configurations }
        Object.entries(config).forEach(pair => {
            if (pair[1] == null) config[pair[0]] = undefined;
            if (typeof pair[1] === 'boolean') {
                config[pair[0]] = (pair[1]) ? 'Yes' : 'No';
            }
        });

        this.props.addToCart({
            customer: this.state.selectedCustomer,
            title: this.state.title,
            product: this.product,
            configurations: { ...config, quantity: parseInt(config.quantity) },
            unit_price: parseFloat(this.state.unit_price),
            duration: this.state.price_and_duration.duration,
            adj_printing_price: this.state.adj_printing_price,
            adjustPrintingSwitch: this.state.adjustPrintingSwitch,
            original_price: this.state.price_and_duration.original_price,
            adjustment_type: this.state.adjustment_type,
            adjustment_value: parseFloat(this.state.adjustment_value),
            adjustment_amount: this.state.adjustment_amount,
            price: this.state.price_and_duration.price,
        });

        //Clear Quote since we are generating new cart item
        this.props.emptyQuote();

        // this.props.history.push('/cart');
        this.props.history.push('/checkout');
    }

    handleAdjustmentInput(e) {
        this.setState({ adjustment_value: e.target.value });
    }

    handleAdjustmentTypeSelect(type, e) {
        this.setState({ adjustment_type: type });
    }

    handleInputText(e) {
        let value = '';

        if (e.target.name === 'title') {
            value = e.target.value.toUpperCase()
        } else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }
    
    handleVisibleOthers(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.othersSelected = e.target.checked;
        console.log(e.target.checked)

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherName(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.name = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleInputOtherValue(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.value = e.target.value;

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }


    handleInputOther(e) {
        let newOther = this.state.configurations[e.target.name];
        newOther.price = parseFloat(e.target.value);

        this.setState({
            configurations: {
                ...this.state.configurations,
                [e.target.name]: newOther
            }
        });
    }

    handleAdjustmentUnitPrice(e) {
        let final_price = this.state.price_and_duration;
        let quantity = parseInt(this.state.configurations.quantity);

        //When Unit Price Changed, Final Price will also change as the unit price
        final_price.price = (e.target.value * quantity);
        console.log(final_price.price)
        //Find Out New Adjustment Value after unit price changed and update to the adjustment value field
        //Adjusment value will depend the adjusment type to calculate
        this.state.adjustment_amount = (final_price.price - final_price.original_price);
        this.state.adjustment_value = (this.state.adjustment_type === '%') ? parseFloat(this.state.adjustment_amount / final_price.original_price * 100).toFixed(2) : parseFloat(this.state.adjustment_amount).toFixed(2);
        console.log(this.state.adjustment_value)

        this.setState({ unit_price: parseFloat(e.target.value), price_and_duration: final_price, adjustment_amount: this.state.adjustment_amount, adjustment_value: this.state.adjustment_value })
    }

    handleEnableAdjustmentPrinting() {
        this.setState({ adjustPrintingSwitch: !this.state.adjustPrintingSwitch })
    }

    handleAdjustmentPrintingPrice(e) {
        this.setState({ adj_printing_price: e.target.value });
    }


    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    closePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    handleSelectChange(e) {
        console.log(e)
        if ((!e.email) || (e.email === "")) {
            console.log(e.email)
            this.customerRef.current.focus();

            this.setState({
                popoverText: 'Missing Customer Info!',
                popoverTarget: 'name',
                popoverOpen: true,
                popoverPosition: 'bottom',
                customerName: e,
                customer_email_validation: e.email
            });

        } else {
            this.setState({ popoverOpen: false, customerName: e, customer_email_validation: null });
        }

        // Find Customer by index from selected customer selection value
        let selectedCustomer = "";
        selectedCustomer = this.props.checkout.list.find(function (element, index) {
            if ((index === e.index) && (e.index !== "")) {
                return element;
            }
        });
        console.log(selectedCustomer)
        this.setState({ selectedCustomer, customerName: e, customer_email_validation: e.email });
        this.props.selectCustomer(selectedCustomer);
    }

    toggleTooltip() {
        const { configurations, price_and_duration, invalidWidth, invalidHeight, customer_email_validation, customerName, title } = this.state;
        if (!title) {
            this.setState({
                popoverText: 'Please Enter A Title',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customerName) {
            this.setState({
                popoverText: 'Please Select A Customer.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if (!customer_email_validation) {
            this.setState({
                popoverText: 'Please Update Customer Details To Proceed.',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        } else if ((price_and_duration.price <= 0) || (price_and_duration.price === NaN)) {

            this.setState({
                popoverText: 'Your Total Price is Zero or Less Than Zero!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (invalidWidth || invalidHeight) {

            this.setState({
                popoverText: 'Invalid Field!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
        else if (configurations.quantity <= 0) {

            this.setState({
                popoverText: 'Quantity is 0!',
                popoverTarget: 'add-to-cart',
                popoverOpen: true,
                popoverPosition: 'right'
            });
        }
    }

    // add for orientation dropdown
    handleOrientationChange = (event) => {
        console.log('selected_orientation: ' + event.target.value);
        let newConfig = {
            ...this.state.configurations,
            selected_orientation: event.target.value
        }
        this.setState({configurations: newConfig});
    };

    render() {
        const { setup } = this.props.product;
        const { gap_between, selected_orientation, configurations, dynamic_options, price_and_duration, isLoadingData, invalidWidth, invalidHeight, adjustment_type, adjustment_amount, selectedCustomer, customeroption, title, sys_printing_price, adj_printing_price, adjustPrintingSwitch, customer_email_validation, customerName, matchQuoteConfig } = this.state;

        if (isLoadingData === true) {
            return (
                <Container>
                    <LoadingIndicator />
                </Container>
            )
        }

        return (
            <Container>
                <Modal isOpen={matchQuoteConfig} className='scrolling'>
                    <ModalHeader style={{ backgroundColor: '#dd3636', color: 'white', marginRight: '-0.5px' }}>
                        Doesn't Match Default Config Selection
                    </ModalHeader>
                    <ModalBody>
                        <p>Selected Quote Doest Not Match Default Configurations Selection.</p>
                        <p>Please Create A New Quotation.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.props.history.goBack}>Back</Button>
                    </ModalFooter>
                </Modal>
                <Popover placement={this.state.popoverPosition} isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={this.closePopover.bind(this)}>
                    <PopoverBody>{this.state.popoverText}</PopoverBody>
                </Popover>
                {(setup && dynamic_options && configurations) &&
                    <Fragment>
                        <h1 id="product-digital-book" style={{ display: "flex", alignDirection: "column", fontSize: "2.5em" }}>Sticker Label (SHEET)</h1>
                        <Form className="product-info">
                            <FormGroup row>
                                <Label sm={3}> <b>Customer</b></Label>
                                <Col sm={9}>
                                    <Select id="name" name="name" ref={this.customerRef} placeholder={selectedCustomer ? selectedCustomer.name.toUpperCase() + " (" + selectedCustomer.email + ") -" + selectedCustomer.company : "NAME / COMPANY / EMAIL"} options={customeroption} value={customeroption.value} onChange={this.handleSelectChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Title</b></Label>
                                <Col sm={9}>
                                    <Input name='title' id='title' type="text" placeholder="TITLE" value={title} style={{ textTransform: 'uppercase' }} onChange={this.handleInputText.bind(this)} />
                                </Col>
                            </FormGroup>
                            {setup.options &&
                                Object.entries(setup.options).map((entry) => {
                                    let configName = entry[0];
                                    let configSettings = entry[1];
                                    return this.generateProductOptionElement(configName, configSettings);
                                })
                            }
                            <ManualInputFormGroup
                                key="gap_between"
                                name="gap_between"
                                label="Set Gap"
                                value={gap_between}
                                inputAttributes={{ type: 'number', placeholder: 'Default Gap = 3MM' }}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            <FormGroup row>
                                <Label sm={3}>Slitting Row</Label>
                                <Col sm={9}>
                                    <Label sm={9}>0</Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Image Orientation</Label>
                                <Col sm={9}>
                                    <Input type="select" id="orientation" value={selected_orientation} style={{ textTransform: 'uppercase' }} onChange={this.handleOrientationChange}>
                                        <option value="auto">Auto</option>
                                        <option value="portrait">Portrait</option>
                                        <option value="landscape">Landscape</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Sticker Arrangement</b></Label>
                                <Col sm={9}>
                                    <Label sm={9} ><b>{configurations.orientation + ": "+ this.state.stickers_per_row + ' Columns x ' + this.state.num_of_rows + ' Rows'}</b></Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Paper Calculation</b></Label>
                                <Col sm={9}>
                                    <Label sm={9} ><b>{this.state.paper_length_m + ' M + 15 M'}</b></Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}> <b>Number of sheets used</b></Label>
                                <Col sm={9}>
                                    <Label sm={9} ><b>{this.state.num_sheets}</b></Label>
                                </Col>
                            </FormGroup>
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Other</h5>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other1" id="other1" value={configurations.other1.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other1.othersSelected} />
                                    <Input name="other1" id="other1" type="text" placeholder="(Title)" value={configurations.other1.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="text" placeholder="(Value)" value={configurations.other1.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other1' id='other1' type="number" placeholder="(Price)" value={configurations.other1.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other2" id="other2" value={configurations.other2.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other2.othersSelected} />
                                    <Input name="other2" id="other2" type="text" placeholder="(Title)" value={configurations.other2.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="text" placeholder="(Value)" value={configurations.other2.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other2' id='other2' type="number" placeholder="(Price)" value={configurations.other2.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input type="checkbox" name="other3" id="other3" value={configurations.other3.othersSelected} style={{ marginTop: '.7rem' }} onClick={this.handleVisibleOthers.bind(this)} defaultChecked={configurations.other3.othersSelected} />
                                    <Input name="other3" id="other3" type="text" placeholder="(Title)" value={configurations.other3.name} style={{ fontWeight: 'bold', textTransform: 'none' }} onChange={this.handleInputOtherName.bind(this)} />
                                </Col>
                                <Col sm={9}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="text" placeholder="(Value)" value={configurations.other3.value} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOtherValue.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input name='other3' id='other3' type="number" placeholder="(Price)" value={configurations.other3.price} style={{ textAlign: 'right', textTransform: 'none' }} onChange={this.handleInputOther.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <h5 style={{ textDecoration: 'underline', margin: '2em 0 1em 0' }}>Price</h5>
                            <FormGroup row>
                                <Label sm={3}>
                                    <b style={{ display: 'block' }}>Printing Rate<br /><br /></b>
                                </Label>
                                <Col sm={9}>
                                    <FormGroup check>
                                        <Input type="checkbox" name="print_rate_override" id="print_rate_override" onClick={this.handleEnableAdjustmentPrinting.bind(this)} defaultChecked={this.state.adjustPrintingSwitch} />
                                        <Label for="print_rate_override" check><b>Override system printing rate</b></Label>
                                    </FormGroup>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="adj_printing_price">System (RM{this.state.sys_printing_price.toFixed(2)})</Label>
                                                <Input name="adj_printing_price" id="adj_printing_price" type="number" min="0" step="0.01" placeholder="Printing Rate" value={adjustPrintingSwitch ? adj_printing_price : sys_printing_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentPrintingPrice.bind(this)} disabled={!adjustPrintingSwitch} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <Fragment>
                                <TimeAndCostFormGroup
                                    price={price_and_duration.original_price}
                                    processDay={price_and_duration.duration} />
                                <FormGroup row>
                                    <Label sm={3}> <b>Unit Price</b></Label>
                                    <Col sm={9}>
                                        <Input name="unit_price" id="unit_price" type="number" placeholder="ADJUSTMENT" value={this.state.unit_price} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentUnitPrice.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>
                                        <b style={{ display: 'block' }}>Adjustment Value</b>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input name="adjustment_value" id="adjustment_value" type="number" placeholder="ADJUSTMENT" value={this.state.adjustment_value} style={{ textAlign: 'right' }} onChange={this.handleAdjustmentInput.bind(this)} />
                                            <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle caret>
                                                    {
                                                        (adjustment_type === '%') ? '% ' : 'RM'
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, '%')}>Percentage (%)</DropdownItem>
                                                    <DropdownItem onClick={this.handleAdjustmentTypeSelect.bind(this, 'RM')} >Riggit Malaysia (RM)</DropdownItem>
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Adjustment Amount</b></Label>
                                    <Label sm={9} ><b>RM {adjustment_amount.toFixed(2)}</b></Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}> <b>Price</b></Label>
                                    <Label sm={9} ><b>RM {price_and_duration.price.toFixed(2)}</b></Label>
                                </FormGroup>
                                {(title) && (configurations.quantity > 0) && (!invalidWidth) && (!invalidHeight) && (price_and_duration.price > 0 && price_and_duration.price !== NaN) && (customer_email_validation) && (customerName) ?
                                    <Button id="add-to-cart" name="add-to-cart" onClick={this.addToCart.bind(this)} >NEXT</Button>
                                    :
                                    <Button id="add-to-cart" name="add-to-cart" style={{ opacity: '0.5' }} onClick={this.toggleTooltip.bind(this)} onMouseOver={this.toggleTooltip.bind(this)} onMouseOut={this.closePopover.bind(this)}>NEXT</Button>
                                }
                            </Fragment>
                        </Form>
                    </Fragment>
                }
            </Container>
        )
    }
}

const mapStateToProps = ({ product, cart, main, checkout, quote }) => {
    console.log(product);
    return { product, cart, main, checkout, quote };
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProductSetup: getProductSetup,
        getProductTimeAndCost: getProductTimeAndCost,
        resetProductTimeAndCost: resetProductTimeAndCost,
        uploadFiles: uploadFiles,
        resetUploads: resetUploads,
        addToCart: addToCart,
        showCustomerSelectionModal: showCustomerSelectionModal,
        getCustomers: getCustomers,
        selectCustomer: selectCustomer,
        emptyCart: emptyCart,
        emptyQuote: emptyQuote
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withAlert(StickerSheet));
